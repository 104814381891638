// import { TareaModel, TaskDocModel } from '../models/TaskModel';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NoteCollaboratorModel } from '../../collaborators/models/CollaboratorModel';
import {
  getNoteCollaboratorByID,
  updateNoteCollaborator,
} from '../../../service/collaborator/Collaborator';

export const useGetNoteCollaboratorByID = (id: string, reload: number) => {
  const note: NoteCollaboratorModel = {
    id: 0,
    nombre: '',
    descripcion: '',
  };
  const [noteInit, setNoteInit] = useState<NoteCollaboratorModel>(note);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getNoteCollaboratorByID(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setNoteInit(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setNoteInit(note);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload]);

  return { noteInit };
};

export const useEditNoteCollaborator = () => {
  const [loadingEditNote, setLoadingEditNote] = useState<boolean>(false);
  const history = useHistory();
  const editNote = async (note: NoteCollaboratorModel) => {
    setLoadingEditNote(false);
    try {
      await updateNoteCollaborator(note);
      setLoadingEditNote(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingEditNote(false);
    }
  };
  return { editNote, loadingEditNote };
};
