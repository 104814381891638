import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { SimpleAlert } from '../../../../../_metronic/helpers';
import { useUpdateConditions } from '../../hooks/QuotationHook';

type Props = {
  id: number;
  condiciones_comerciales: string;
  moneda: string;
  isVerify: boolean;
  activeConditions: (active: boolean) => void;
};

const Comments: React.FC<Props> = ({
  id,
  condiciones_comerciales,
  moneda,
  isVerify,
  activeConditions,
}) => {
  const [content, setContent] = useState<string>();
  const { updateConditions, loading } = useUpdateConditions();

  const saveContent = async (e: any) => {
    e.preventDefault();

    if (!isVerify) {
      SimpleAlert(
        'Por favor, marca la casilla para verificar las condiciones comerciales',
        3000,
        'error'
      );
      return false;
    }
    if (content) {
      await updateConditions(id, content);
    }
  };

  useEffect(() => {
    let content = `<div>
      <h3><strong>Condiciones comerciales:</strong></h3><br>
      <p>
        La presente cotización ampara únicamente el <strong>[equipo y/o servicio]</strong> que se describe, no incluye ningún otro 
        <strong>[equipo y/o servicio]</strong> que no esté mencionado.
      </p>
      <ul>
      <li>
        En determinado momento que el alcance propuesto cambie, se realizará una cotización por separado y se realizará 
        previa orden de compra por escrito.
      </li>
      <li>Los precios indicados son válidos únicamente para la adquisición del volumen total cotizado.</li>
      <li>Los precios están expresados en <strong>${moneda}</strong> y son <strong>[Lugar/planta]</strong>.</li>
      <li>A los precios cotizados deberá de agregarse el 16% del IVA.</li>
      <li>Tiempo estimado de servicio: <strong>[Duración: cantidad + días, semanas, etc.]</strong>.</li>
      <li><strong>Condiciones de pago:</strong>
      <ul>
      <li>Contra entrega</li>
      </ul>
      </li>
      <li>Vigencia de la cotización: <strong>[Días de vigencia]</strong>.</li>
      <li>
        La recepción por cualquier medio, físico o electrónico, de su orden de compra representa la aceptación legal 
        y expresa la totalidad de las condiciones comerciales indicadas.
      </li>
      </ul><br>
      <h4><strong>Notas importantes:</strong></h4><br>
      <p>Esto es indispensable para dar entrada a su Orden de Compra.</p>
        <p>
          En caso de vernos favorecido con su pedido, solicitamos a ustedes de la manera más atenta indique al momento de 
          enviarnos su Orden de Compra, incluya en la misma nuestro número de cotización: 
          <strong>[Folio y nombre de la cotización]</strong>, y las condiciones comerciales a la que está haciendo referencia.
        </p>
      <ul>
        <li>
          Por nuevas disposiciones del SAT es necesario incluir en su Orden de Compra: Tipo de Gasto y Método de Pago para 
          la emisión de la factura correspondiente.
        </li>
        <li>
          Al momento de realizar su pago le pedimos por favor anote en la referencia bancaria su razón social.
        </li>
        <li>
          Favor de hacernos llegar los datos para la expedición de su recibo de complemento de pago, número de cuenta 
          y banco emisores.
        </li>
      </ul>
      <p>
        En caso de vernos favorecidos por favor mandar su orden de compra a:
      </p><br>
      <p>
        <strong>Automatización y Control Industrial de Monterrey, SA de CV.</strong><br>
        RFC: <strong>ACI9900305ST3</strong><br>
        CALLE TIZOC 160 COL. AZTECA, SAN NICOLAS DE LOS GARZA N.L.<br>
        66480.
      </p>
      <p>
        <strong>Mail para recibir OC:</strong><br>
        <a href="mailto:jorge.gutierrez@acimsa.net">jorge.gutierrez@acimsa.net</a>; 
        <a href="mailto:luis.moreno@acimsa.net">luis.moreno@acimsa.net</a>
      </p>
    </div>
    `;

    if (condiciones_comerciales) {
      setContent(condiciones_comerciales);
    } else {
      setContent(content);
    }
  }, [moneda, condiciones_comerciales]);

  return (
    <>
      <div className="card mb-6 mb-xl-9">
        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Condiciones comerciales</h3>
          </div>
        </div>
        <div className="card-body border-top p-9">
          <div className="row">
            <div className="col h-auto">
              <ReactQuill
                style={{
                  borderRadius: '2px',
                }}
                className="h-600px"
                value={content}
                onChange={setContent}
              />
            </div>
          </div>
          <div className="row">
            <div className="col  mt-20">
              <label className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input mr-10"
                  type="checkbox"
                  checked={isVerify}
                  onChange={(event: any) => {
                    activeConditions(event.target.checked);
                  }}
                />
                Confirmo que he revisado las condiciones comerciales
              </label>
            </div>
            <div className="col">
              <div className="d-flex justify-content-end">
                {/* <button
                  className="btn btn-primary me-3 mt-20"
                  onClick={saveContent}
                >
                  Guardar
                </button> */}

                <button
                  type="button"
                  className="btn btn-primary me-3 mt-20"
                  disabled={loading}
                  onClick={saveContent}
                >
                  {!loading && (
                    <span className="indicator-label">Guardar condiciones</span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comments;
