import axios from "axios";
import { NotaProyectoModel, ProyectoModel } from "../../pages/bitacora/proyectos/models/ProyectoModel";
import { SelectFormat } from "../../../_metronic/helpers/models/GenericModel";

const API_URL = process.env.REACT_APP_API;
const BASE_URL = `${API_URL}proyectos/`;


async function handleRequest(request: Promise<any>) {
  try {
    const response = await request;
    return response.data.doc?.data ?? response.data;
  } catch (error) {
    console.error("Error en la petición:", error);
    throw error; 
  }
}

export async function obtenerProyectos(filters: any) {
  return await handleRequest(axios.post(BASE_URL, filters));
}

export async function obtenerProyectosPorID(id_proyecto: number) {

  try {
    let response = await axios.get(`${BASE_URL}${id_proyecto}`);
    let { id, nombre, descripcion, unidadNegocio, categoria, subcategoria, estatus, tipo, fecha_inicio, fecha_final, secciones, oportunidad_id, activo_oportunidad  } = response.data.doc?.data;
    
    const initModel: ProyectoModel = {
      id,
      nombre,
      descripcion,
      imagen: '',
      unidad_negocio: {
        id: unidadNegocio?.id > 0 ? unidadNegocio?.id : 0,
        name: unidadNegocio?.id > 0 ? unidadNegocio?.name : '',
        code: unidadNegocio?.id > 0 ? unidadNegocio?.code : '',
      },
      categoria: {
        id: categoria?.id > 0 ? categoria?.id : 0,
        codigo: categoria?.id > 0 ? categoria?.codigo : '',
        nombre: categoria?.id > 0 ? categoria?.nombre : '',
        orden: categoria?.id > 0 ? categoria?.orden : '',
      },
      subcategoria: {
        id: subcategoria?.id > 0 ? subcategoria?.id : 0,
        codigo: subcategoria?.id > 0 ? subcategoria?.codigo : '',
        nombre: subcategoria?.id > 0 ? subcategoria?.nombre : '',
        orden: subcategoria?.id > 0 ? subcategoria?.orden : '',
      },
      status:{
        id: estatus?.id > 0 ? estatus?.id : 0,
        name: estatus?.id > 0 ? estatus?.name : 'No iniciado',
        code: estatus?.id > 0 ? estatus?.code : 'NO_INIT', 
      },
      tipo: {
        id: tipo?.id > 0 ? tipo?.id : 0,
        name: tipo?.id > 0 ? tipo?.name : '',
        code: tipo?.id > 0 ? tipo?.code : '',
      },
      oportunidad_id: oportunidad_id,
      fecha_inicio: new Date(fecha_inicio).toISOString().split("T")[0],
      fecha_final: new Date(fecha_final).toISOString().split("T")[0],
      secciones: secciones,
      secciones_eliminadas:[],
      secciones_actuales: secciones,
      activo_oportunidad
    };
    return initModel;
  } catch (error) {
    console.error("Error en la petición:", error);
    throw error; 
  }
}

export async function agregarEditarProyecto(
  { 
    id,
    nombre,
    descripcion,
    unidad_negocio,
    categoria,
    subcategoria,
    tipo,
    fecha_inicio,
    fecha_final,
    secciones,
    secciones_eliminadas,
    secciones_actuales,
    oportunidad_id
  } : ProyectoModel) {

  let data = {
    nombre,
    descripcion,
    tipo_id: tipo?.id === 0 ? null : tipo?.id,
    fecha_inicio,   
    fecha_final,
    unidad_negocio_id: unidad_negocio?.id === 0 ? null : unidad_negocio?.id,
    categoria_id: categoria?.id === 0 ? null : categoria?.id,
    subcategoria_id: subcategoria?.id === 0 ? null : subcategoria?.id,
    secciones: secciones,
    secciones_eliminadas,
    secciones_actuales,
    oportunidad_id
  }
  if(id){
    return await handleRequest(axios.put(`${BASE_URL}${id}`, {
      ...data, id
    }));

  }else{
    return await handleRequest(axios.post(`${BASE_URL}/add`, data));
  }
}
export async function eliminarProyecto(id: number) {
  return await handleRequest(axios.delete(`${BASE_URL}/${id}`));
}


export async function obtenerCamposFiltroProyectos() {
  try {

    const response = await axios.get(`${API_URL}catalogs/campos/TBL_PROY`);
    const data = response.data.doc.data;
    return data;

  } catch (error) {
    console.error("Error al obtener los campos a filtrar:", error);
    throw error;
  }
}


export async function obtenerDatosFiltrosProyectos(code: string) {
  try{

    let response;
    let data;
    let res;
    let selectOptions;

    switch (code) {
        case 'PROY_NOM':
            response = await axios.post(`${BASE_URL}`);
            res = response.data.doc.data.rows;
            selectOptions = res.map((option: any) => ({
              value: option.id,
              label: option.nombre,
            }));
            data = selectOptions;
            break;
        case 'PROY_STS':
            response = await axios.get(`${API_URL}catalogs/STS?sort=name`);
            res = response.data.doc.data.rows;
            let resEstatus = res.filter((item: any) => item.code === 'OPENC');
            selectOptions = resEstatus.map((option: any) => ({
              value: option.id,
              label: option.name,
            }));
            data = selectOptions;
            break;
        case 'PROY_TIPO':
            response = await axios.get(`${API_URL}catalogs/PROYECTO_TIPO?sort=name`);
            res = response.data.doc.data.rows;
            selectOptions = res.map((option: any) => ({
              value: option.id,
              label: option.name
            }));
            data = selectOptions;
            break;
        default:  
            break;
    }
    return data;

  } catch (error) {
    console.error("Error al obtener los campos a filtrar:", error);
    throw error;
  }

}

export async function getDocumentsByProjectId(id: number) {

  try {

    const response = await axios.get(`${BASE_URL}documentos/${id}`);
    const data = response.data.doc.data;
    return data;

  } catch (error) {
    console.error("Error al obtener los documentos:", error);
    throw error;
  }

}
export const uploadFileToQuo = async (file: any, id: string) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(
      `${BASE_URL}documentos/${id}`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Error al subir el documento:", error);
    throw error;
  }
};
export const removeFileOfQuo = async (id: number) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}documentos/${id}`
    );
    return response;
  } catch (error) {
    console.error("Error al eliminar el documento:", error);
    throw error;
  }
};

export async function getNotesByProjectId(id: number) {
  
  try {
    const response = await axios.get(
      `${BASE_URL}notas/proyecto/${id}`
    );
    const data = response.data.doc.data;
    return data;
  } catch (error) {
    console.error("Error al obtener las notas:", error);
    throw error;
  }


}

export async function insertNoteProject(note: any, id: number) {

  try {
    const response = await axios.post(`${BASE_URL}notas/`, {
      nombre: note.nombre,
      descripcion: note.descripcion,
      proyecto_id: Number(id),
    });
    return response.data;
  } catch (error) {
    console.error("Error al agregar la nota:", error);
    throw error;
  }
}

export const getNoteProjectByID = async (id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}notas/${id}`);
    let note = response.data.doc.data;
    note = {
      ...note,
      proyecto_id: note.proyecto_id?.id | 0,
      created_at: note.created_at
        ? new Date(note.created_at).toISOString().split("T")[0]
        : new Date().toISOString().split("T")[0],
      updated_at: note.updated_at
        ? new Date(note.updated_at).toISOString().split("T")[0]
        : new Date().toISOString().split("T")[0],
    };
    return note;
  } catch (error) {
    console.error("Error al obtener la nota:", error);
    throw error;
  }
  
};
export async function updateNoteProject(note: NotaProyectoModel) {
  try {
    const { id, nombre, descripcion } = note;
    const response = await axios.patch(`${BASE_URL}notas/${id}`, {
      nombre: nombre,
      descripcion: descripcion,
    });
    return response;
  } catch (error) {
    console.error("Error al actualizar la nota:", error);
    throw error;
  } 
}

export async function getHistorialProyectos(search: string, id: string) {

  try {
   
    const response = await axios.get(`${BASE_URL}historial/${id}`);
    const data = response.data.doc.data;
    return data;

  } catch (error) {
    console.error("Error al obtener el historial:", error);
    throw error;
  } 
  
}

export async function createHistoryMessageProject(message: string, id: number) {

  try {
   
    const response = await axios.post(
      `${BASE_URL}historial/comment/add`,
      {
        proyectoId: id,
        comentario: message,
      }
    );
    return response;

  } catch (error) {
    console.error("Error al obtener el historial:", error);
    throw error;
  } 
}

export const validarSeccionTareas = async (id: number) => {
  try {
    const response = await axios.get(`${BASE_URL}secciones/${id}/validar`);
    return response;
  } catch (error) {
    console.error('Error al eliminar la sección.', error);
    throw error;
  }
};
export const validarTareas = async (id: number) => {
  try {
    const response = await axios.get(`${BASE_URL}tareas/${id}/validar`);
    return response;
  } catch (error) {
    console.error('Error al eliminar la tarea.', error);
    throw error;
  }
};

export async function getOpportunitysNewProject() {
  try {
      const response = await axios.get(`${API_URL}oportunidades/proyectos`);
      const data = response.data.doc.data;
      let select: SelectFormat[] = [];
      data.forEach((item: any) => {
        const selectCustom = {
          id: Number(item.id),
          value: item.id,
          label: `${item.nombre}`,
          data: item,
        };
        select.push(selectCustom);
      });
      return select;
    } catch (error) {
      console.error("Error al obtener las oportunidades:", error);
      throw error;
    }
}

export async function updateActiveProject(oportunidad_id: number, proyecto_id: number) {
  try {
    const response = await axios.put(`${BASE_URL}oportunidades/activar/`, {
      oportunidad_id: oportunidad_id,
      proyecto_id: proyecto_id,
    });
    return response;
  } catch (error) {
    console.error("Error al activar el proyecto:", error);
    throw error;
  } 
}
