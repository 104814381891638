import axios from "axios";
import { AddressSupplierModel, NotaProveedorModel, SupplierModel } from "../../pages/supplier/models/SupplierModel";

const API_URL = process.env.REACT_APP_API;
const BASE_URL = `${API_URL}proveedores/`;


async function handleRequest(request: Promise<any>) {
  try {
    const response = await request;
    return response.data.doc?.data ?? response.data;
  } catch (error) {
    console.error("Error en la petición:", error);
    throw error; 
  }
}

export async function obtenerProveedores(filters: any) {
  return await handleRequest(axios.post(BASE_URL, filters));
}

export async function obtenerProveedoresPorID(proveedor_id: number) {

  try {
    let response = await axios.get(`${BASE_URL}${proveedor_id}`);
    return response.data.doc.data;
  } catch (error) {
    console.error("Error en la obtener la información del proveedor:", error);
    throw error; 
  }

}

export async function getContactSupplier() {

  try {
    const response = await axios.get(
      `${API_URL}catalogos/contactos`
    );
   return response.data.doc.data;
  } catch (error) {
    console.error("Error en la obtener el contacto:", error);
    throw error; 
  }

}

export async function agregarEditarProveedor(
  { 
    id,
    nombre,
    descripcion,
    no_proveedor,
    telefono_contacto,
    email,
    sitio_web,
    referido,
    industria,
    contacto
  } : SupplierModel) {

  let data = {
    nombre: nombre,
    no_proveedor:no_proveedor,
    industria_id: industria?.id === 0 ? null : industria?.id,
    descripcion: descripcion,
    contacto_id: contacto?.id === 0 ? null : contacto?.id,
    telefono_contacto: telefono_contacto.split(" ").join(""),
    email: email,
    sitio_web: sitio_web,
    referido_id: referido?.id === 0 ? null : referido?.id,
  }
  if(id){
    return await handleRequest(axios.put(`${BASE_URL}${id}`, {
      ...data, id
    }));

  }else{
    return await handleRequest(axios.post(`${BASE_URL}`, data));
  }
}
export async function eliminarProyecto(id: number) {
  return await handleRequest(axios.delete(`${BASE_URL}/${id}`));
}


export async function obtenerCamposFiltroProveedores() {
  try {

    const response = await axios.get(`${API_URL}catalogs/campos/TBL_PROV`);
    const data = response.data.doc.data;
    return data;

  } catch (error) {
    console.error("Error al obtener los campos a filtrar:", error);
    throw error;
  }
}

export async function obtenerDatosFiltrosProveedores(code: string) {
  try{

    let response;
    let data;
    let res;
    let selectOptions;

    switch (code) {
        case 'PROV_NUM_PROV':
            response = await axios.post(`${BASE_URL}`);
            res = response.data.doc.data;
            selectOptions = res.filter((option: any) => option.no_proveedor !== null).map((option: any) => ({
              value: option.id,
              label: option.no_proveedor,
            }));
            data = selectOptions;
            break;
        case 'PROV_NOM':
          response = await axios.post(`${BASE_URL}`);
          res = response.data.doc.data;
            selectOptions = res.map((option: any) => ({
              value: option.id,
              label: option.nombre,
            }));
            data = selectOptions;
            break;
        case 'PROV_IND':
            response = await axios.get(`${API_URL}catalogs/IND?sort=name`);
            res = response.data.doc.data.rows;
            selectOptions = res.map((option: any) => ({
              value: option.id,
              label: option.name
            }));
            data = selectOptions;
            break;
        default:  
            break;
    }
    return data;

  } catch (error) {
    console.error("Error al obtener los campos a filtrar:", error);
    throw error;
  }

}

export const uploadFileToSupplier = async (file: any, proveedor_id: string) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('proveedor_id', proveedor_id);

  const response = await axios.post(
    `${BASE_URL}documentos`,
    formData
  );
  return response;
};
export const removeFileOfSupplier = async (documento_id: number) => {
  const response = await axios.delete(
    `${BASE_URL}documentos/${documento_id}`
  );
  return response;
};

export async function getDocumentsBySupplierId(id: number) {

  try {

    const response = await axios.get(`${BASE_URL}documentos/${id}`);
    const data = response.data.doc.data;
    return data;

  } catch (error) {
    console.error("Error al obtener los documentos:", error);
    throw error;
  }

}


export async function getNotesByProveedorId(id: number) {
  
  try {
    const response = await axios.get(
      `${BASE_URL}notas/proveedor/${id}`
    );
    const data = response.data.doc.data;
    return data;
  } catch (error) {
    console.error("Error al obtener las notas:", error);
    throw error;
  }

}

export async function insertNoteProveedor(note: any, id: number) {
  try {
    const response = await axios.post(`${BASE_URL}notas/`, {
      nombre: note.nombre,
      descripcion: note.descripcion,
      proveedor_id: Number(id),
    });
    return response.data;
  } catch (error) {
    console.error("Error al agregar la nota:", error);
    throw error;
  }
}

export const getNoteProveedorByID = async (id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}notas/${id}`);
    let note = response.data.doc.data;
    note = {
      ...note,
      proveedor_id: note.proveedor?.id | 0,
      created_at: note.created_at
        ? new Date(note.created_at).toISOString().split("T")[0]
        : new Date().toISOString().split("T")[0],
      updated_at: note.updated_at
        ? new Date(note.updated_at).toISOString().split("T")[0]
        : new Date().toISOString().split("T")[0],
    };
    return note;
  } catch (error) {
    console.error("Error al obtener la nota:", error);
    throw error;
  }
  
};

export async function updateNoteProveedor(note: NotaProveedorModel) {
  try {
    const { id, nombre, descripcion } = note;
    const response = await axios.put(`${BASE_URL}notas/${id}`, {
      nombre: nombre,
      descripcion: descripcion,
    });
    return response;
  } catch (error) {
    console.error("Error al actualizar la nota:", error);
    throw error;
  } 
}

export async function getAddressBySupplierId(id: string) {
  try {
    const response = await axios.get(
      `${BASE_URL}direcciones/proveedor/${id}`
    );
    const data = response.data.doc.data;
    return data;
  } catch (error) {
    console.error("ERROR:", error);
  }
}

export async function insertAddressSupplier(
  address: AddressSupplierModel,
  proveedor_id: string
) {
  const response = await axios.post(
    `${BASE_URL}direcciones`,
    {
      ...address,
      proveedor_id: proveedor_id,
    }
  );
  return response;
}

export async function updateAddressSupplier(
  address: AddressSupplierModel,
  proveedor_id: string
) {
  const response = await axios.put(
    `${BASE_URL}direcciones/${address.id}`,
    {
      ...address
    }
  );
  return response;
}

export async function getContactBySupplierId(id: string) {
  const response = await axios.get(`${BASE_URL}contactos/${id}`);
  const data = response.data.doc.data;
  return data;
}

export async function updateModalContactoSupplier(body: any) {
  const { proveedor_id, contacto_id } = body;

  const data = {
    proveedor_id: proveedor_id,
    contacto_id
  };

  const response = await axios.post(
    `${BASE_URL}contactos/`,
    data
  );
  return response;
}


export async function addFirstContactoSupplier(body: any) {
  const {
    nombre,
    apellido_paterno,
    apellido_materno,
    email,
    telefono,
    proveedor_id
  } = body;

  const data = {
    nombre: nombre,
    apellido_paterno: apellido_paterno,
    apellido_materno: apellido_materno,
    email: email,
    telefono: telefono,
    proveedor_id: proveedor_id
  };

  const response = await axios.post(`${API_URL}catalogos/contactos/add/`, data);
  return response;
}