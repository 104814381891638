import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  KTSVG,
  SimpleAlert,
  userHasRole,
} from '../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import CountUp from 'react-countup';
import { Collapse } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import Select from 'react-select';
import {
  useCrearEditarTarea,
  useGetNoteByTaskId,
  useObtenerTareaPorId,
} from '../hooks/TareasHook';
import {
  useSelectCurrencyPackages,
  useSelectGeneric,
} from '../../../catalogos/generico/hooks/genericoHook';
import { colorStatusTask } from '../../../../../_metronic/helpers/CustomElements';
import {
  useGetAudiosByTaskId,
  useGetDocumentsByTaskId,
  useGetMe,
  useGetUsersTasksBitacora,
} from '../../../tasks/hooks/TaskHook';
import { RecursosList } from './sections/RecursosList';
import { AvanceList } from './sections/AvanceList';
import { SelectFormat } from '../../../../../_metronic/helpers/models/GenericModel';
import {
  TareaAvancesModel,
  TareaRecursosModel,
} from '../../../tasks/models/TaskModel';
import { TaskDoc } from '../../../tasks/components/TaskDoc';
import { NoteTaskList } from './sections/NoteTaskList';
import { CreateNoteTaskModal } from '../_modals/CreateNoteModal';
import { DetailNoteTaskModal } from '../_modals/DetailNoteModal';
import { AudioTaskList } from './sections/AudioTaskList';
import { styleSelect } from '../../../../../_metronic/helpers/SelectStyles';

type Props = {
  tareaId: number;
  etapaId: number;
  onRefreshHistory: () => void;
};

const TareaForm: React.FC<Props> = ({ tareaId, etapaId, onRefreshHistory }) => {
  const [selectRecursos, setSelectRecursos] = useState<any>([]);
  const [openSection, setOpenSection] = useState<boolean>(true);
  const [openSectionAvance, setOpenSectionAvance] = useState<boolean>(true);
  let today = new Date().toISOString().split('T')[0];
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [editaTarea, setEditaTarea] = useState<boolean>(false);
  const [esfuerzoTotal, setEsfuerzoTotal] = useState<number>(0);
  const [unidadEsfuerzoCodigo, setUnidadEsfuerzoCodigo] = useState<string>('');
  const { catalog: currencyUnit, loadingRequest: loadingCU } =
    useSelectCurrencyPackages('UMON');
  // const [avanceTotal, setAvanceTotal] = useState<number>(0);
  const userInit = {
    id: 0,
    alias: '',
    first_name: '',
    father_last_name: '',
    mother_last_name: '',
    email: '',
    phone: '',
    profile_photo: '',
    company: {
      id: 0,
      name: '',
      code: '',
    },
    active: false,
    roles: [],
  };
  const [reloadInfo, setReloadInfo] = useState(false);
  const { user } = useGetMe(reloadInfo, userInit);
  const { roles, email } = user;
  const isSupervisor = userHasRole(['SUP', 'ADMIN', 'DIR'], roles);
  const isColaborador = userHasRole(['COLAB'], roles);
  const emailColaborador = email;
  const [avanceDeleted, setAvanceDeleted] = useState<any[]>([]);
  // const isSupervisor = roles?.code === 'SUP' ? true : false;

  const { catalog: priority } = useSelectGeneric('PRIO');
  const { catalog: etapa } = useSelectGeneric('ETAR');
  const { catalog: tipoTareas } = useSelectGeneric('TTASK');
  const { catalog: unidadEsfuerzo } = useSelectGeneric('UN_EFFORT_TASK');
  const { dataSelect: usuarios } = useGetUsersTasksBitacora();
  const { crearEditarTarea } = useCrearEditarTarea();

  const [recargarTarea, setRecargarTarea] = useState(Math.random() * 40);
  const { tarea, cargandoTareas } = useObtenerTareaPorId(
    tareaId,
    etapaId,
    recargarTarea
  );

  // SECCIONES
  const [formIsEdit, setFormIsEdit] = useState<boolean>(false);
  const tabSection = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState<string | 'NOTE' | 'DOC' | 'AUD'>('NOTE');

  // SECCIONES TAREAS
  const [reloadGridNotes, setReloadGridNotes] = useState<number>(
    Math.random() * 40
  );
  const { notes, loadingNote } = useGetNoteByTaskId(
    tareaId.toString(),
    reloadGridNotes
  );
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [showNoteDetailModal, setShowNoteDetailModal] =
    useState<boolean>(false);
  const [activeNewNote, setActiveNewNote] = useState<boolean>(false);

  const openModal = (isOpen: boolean) => {
    setShowNoteModal(isOpen);
  };
  const openDetailModal = (isOpen: boolean) => {
    setShowNoteDetailModal(isOpen);
  };

  // SECCIONES DOCUMENTOS
  const [reloadDocuments, setReloadDocuments] = useState(Math.random() * 40);
  const { dataDocuments, loadingDocuments } = useGetDocumentsByTaskId(
    tareaId.toString(),
    reloadDocuments
  );

  // SECCIONES AUDIOS
  const [reloadAudios, setReloadAudios] = useState(Math.random() * 40);
  const { dataAudios, loadingAudios } = useGetAudiosByTaskId(
    tareaId.toString(),
    reloadAudios
  );

  const tareaValidacion = Yup.object().shape({
    nombre: Yup.string().required('Nombre es requerido.'),
    fecha_inicio: Yup.date()
      .nullable()
      .required('La fecha inicio es requerida'),
    fecha_fin: Yup.date()
      .min(Yup.ref('fecha_inicio'), 'No puede ser menor fecha inicio.')
      .required('La fecha de fin es requerida'),
    etapa_id: Yup.number()
      .min(1, 'Debe seleccionar una etapa.')
      .required('Etapa requerido.'),
    user_asignado_id: Yup.number()
      .min(1, 'Debe seleccionar un vendedor.')
      .required('Vendedor requerido.'),
    prioridad_id: Yup.number()
      .min(1, 'Debe seleccionar una prioridad.')
      .required('Prioridad requerido.'),
    tipo_tarea_id: Yup.number()
      .min(1, 'Debe seleccionar un tipo de tarea.')
      .required('Tipo de tarea requerido.'),
    unidad_esfuerzo_id: Yup.number()
      .min(1, 'Debe seleccionar una unidad.')
      .required('Unidad de esfuerzo requerido.'),
    moneda_id: Yup.number()
      .min(1, 'Moneda es requerido')
      .required('Moneda es requerido'),
  });

  const Formulario = useFormik({
    initialValues: tarea,
    validationSchema: tareaValidacion,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      values.esfuerzo = esfuerzoTotal;
      values.avances_eliminados = avanceDeleted;
      let arrayAvances = values.avances;
      let isEmptyDate = arrayAvances.find((item: any) => item.fecha === '');
      if (isEmptyDate) {
        SimpleAlert(
          'Favor seleccione la fecha en el avance de los recursos.',
          3000,
          'error'
        );
        setLoading(false);

        return false;
      }
      await crearEditarTarea(values);
      setLoading(false);
    },
  });

  const userSelected = (selectedOption: any) => {
    const { value } = selectedOption;
    Formulario.setFieldValue('user_asignado_id', value);
  };

  const crearSelectRecursos = (data: TareaRecursosModel[]) => {
    let select: SelectFormat[] = [];
    data.forEach((item: any) => {
      const selectCustom = {
        id: Number(item.id),
        value: item.id,
        label: `${item.colaborador?.contacto?.nombre} ${item.colaborador?.contacto?.apellido_paterno} ${item.colaborador?.contacto?.apellido_materno}`,
        data: item,
      };
      select.push(selectCustom);
    });
    setSelectRecursos(select);
  };

  const avanceRemove = (avanceRow: number) => {
    setAvanceDeleted([...avanceDeleted, avanceRow]);
  };

  const changeCurrencyUnit = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      Formulario.setFieldValue('moneda_id', value);
    } else {
      Formulario.setFieldValue('moneda_id', 0);
    }
  };

  useEffect(() => {
    if (etapa && !tareaId) {
      Formulario.setFieldValue('etapa_id', etapaId);
    }
  }, [etapaId, etapa, tareaId]);

  useEffect(() => {
    if (tarea && tarea.etapa) {
      const { etapa } = tarea;
      if (etapa.code === 'TCOMPL' || isColaborador) {
        setEditaTarea(true);
      }
    }
    return () => {
      setEditaTarea(false);
    };
  }, [tarea]);

  useEffect(() => {
    if (tipoTareas && tarea?.id === 0) {
      const tipoTarea = tipoTareas.find((item) => item.code === 'ACT');
      if (tipoTarea) {
        Formulario.setFieldValue('tipo_tarea_id', tipoTarea.id);
      }
    }
  }, [tipoTareas, tarea]);

  useEffect(() => {
    if (tarea?.id > 0 && tarea.unidadEsfuerzo) {
      const { code, id } = tarea.unidadEsfuerzo;
      Formulario.setFieldValue('unidad_esfuerzo_id', id);
      setUnidadEsfuerzoCodigo(code || '');
    }
  }, [tarea]);

  // useEffect(() => {
  //   if (tarea?.id > 0 && tarea.avance) {
  //     setAvanceTotal(tarea.avance || 0);
  //   }
  // }, [tarea]);

  return (
    <>
      <form
        id="kt_account_form"
        className="form"
        action="#"
        noValidate
        onSubmit={Formulario.handleSubmit}
      >
        <div className="card mb-6 mb-xl-9">
          <div className="card-body">
            <fieldset disabled={editaTarea}>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 p-0">
                  <div className="w-100">
                    <input
                      className="form-control mb-2 w-100 border-0"
                      style={{
                        width: '100%',
                        padding: '15px',
                        fontSize: '24px',
                        boxSizing: 'border-box',
                        marginBottom: '20px',
                      }}
                      maxLength={40}
                      data-kt-element="input"
                      placeholder="Nombre *"
                      {...Formulario.getFieldProps('nombre')}
                    ></input>
                    {Formulario.touched.nombre && Formulario.errors.nombre && (
                      <div className="fv-plugins-message-container text-danger ms-5 w-100 mb-3">
                        <span role="alert">{Formulario.errors.nombre}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4 p-0">
                  <div className="d-flex justify-content-center mb-10">
                    <div
                      className="w-100 border border-gray-300 rounded d-flex justify-content-center align-items-center"
                      style={{ height: '64px' }}
                    >
                      <div className="text-center m-0">
                        <h6 className="mt-3 fs-7 text-gray-700 m-0"> Avance</h6>
                        <span
                          className="fs-2x fw-bold"
                          style={{ color: '#1B84FF' }}
                        >
                          {Formulario.values.avance}
                        </span>

                        <span className="fs-7 fw-semibold opacity-50">
                          <span data-kt-element="period"> %</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required form-label w-auto">
                        Tipo de tarea
                      </label>
                    </div>
                    <select
                      className="form-select mb-3"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      {...Formulario.getFieldProps('tipo_tarea_id')}
                    >
                      <option value={0}>Seleccione</option>
                      {tipoTareas.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {Formulario.touched.tipo_tarea_id &&
                      Formulario.errors.tipo_tarea_id && (
                        <div className="fv-plugins-message-container text-danger w-100 mb-3">
                          <span role="alert">
                            {Formulario.errors.tipo_tarea_id}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required form-label w-auto">
                        Unidad de esfuerzo
                      </label>
                    </div>
                    <select
                      className="form-select mb-3"
                      disabled={
                        Formulario.values.recursos.length ? true : false
                      }
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      onChange={(e) => {
                        const selectedCode =
                          e.target.options[e.target.selectedIndex].getAttribute(
                            'data-code'
                          );
                        Formulario.setFieldValue(
                          'unidad_esfuerzo_id',
                          e.target.value
                        );
                        setUnidadEsfuerzoCodigo(selectedCode || '');
                      }}
                      value={Formulario.values.unidad_esfuerzo_id}
                    >
                      <option value={0} data-code={''}>
                        Seleccione
                      </option>
                      {unidadEsfuerzo.map((item, index) => (
                        <option
                          key={index}
                          value={item.id}
                          data-code={item.code}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {Formulario.touched.unidad_esfuerzo_id &&
                      Formulario.errors.unidad_esfuerzo_id && (
                        <div className="fv-plugins-message-container text-danger w-100 mb-3">
                          <span role="alert">
                            {Formulario.errors.unidad_esfuerzo_id}
                          </span>
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 d-flex flex-column">
                  <div
                    className="w-100 p-3 rounded"
                    style={{ backgroundColor: '#f6f6f6' }}
                  >
                    <div className="w-100 d-flex justify-content-between">
                      <label className="form-label fs-7">
                        Esfuerzo acomulado
                      </label>
                      <span title="Esfuerzo acomulado">
                        <KTSVG
                          path="/media/icons/duotune/general/gen045.svg"
                          className="svg-icon-4"
                        />
                      </span>
                    </div>

                    <div className="text-end">
                      <span className="fs-6 fw-bold">{esfuerzoTotal}</span>
                      <span className="fs-6 fw-semibold opacity-50 ms-1">
                        {unidadEsfuerzoCodigo}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required form-label w-auto">
                        Etapa
                      </label>
                    </div>
                    <select
                      className="form-select mb-3"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      {...Formulario.getFieldProps('etapa_id')}
                    >
                      <option value={0}>Seleccione</option>
                      {etapa.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {Formulario.touched.etapa_id && Formulario.errors.etapa_id && (
                      <div className="fv-plugins-message-container text-danger w-100 mb-3">
                        <span role="alert">{Formulario.errors.etapa_id}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 d-flex flex-column ">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required form-label w-auto">
                        Fecha inicio
                      </label>
                    </div>
                    <input
                      className={'form-control mb-3'}
                      data-kt-element="input"
                      type="date"
                      min={today}
                      name="fecha_inicio"
                      value={Formulario.values.fecha_inicio}
                      onChange={(e) => {
                        Formulario.setFieldValue(
                          'fecha_inicio',
                          e.target.value
                        );
                      }}
                    />
                    {Formulario.touched.fecha_inicio &&
                      Formulario.errors.fecha_inicio && (
                        <div className="fv-plugins-message-container text-danger w-100 mb-3">
                          <span role="alert">
                            {Formulario.errors.fecha_inicio}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="form-label w-auto">Fecha final</label>
                    </div>
                    <input
                      className={'form-control mb-3'}
                      data-kt-element="input"
                      type="date"
                      min={Formulario.values.fecha_inicio}
                      name="fecha_fin"
                      value={Formulario.values.fecha_fin}
                      onChange={(e) => {
                        Formulario.setFieldValue('fecha_fin', e.target.value);
                      }}
                    />
                    {Formulario.touched.fecha_fin &&
                      Formulario.errors.fecha_fin && (
                        <div className="fv-plugins-message-container text-danger w-100 mb-3">
                          <span role="alert">
                            {Formulario.errors.fecha_fin}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 d-flex flex-column">
                  <label className="form-label w-auto required">
                    Asignado a
                  </label>
                  <Select
                    options={usuarios}
                    value={
                      Formulario.values.user_asignado_id
                        ? usuarios.find(
                            (option) =>
                              option.id === Formulario.values.user_asignado_id
                          )
                        : null
                    }
                    styles={{
                      placeholder: (provided: any, state: any) => ({
                        ...provided,
                        color: '#181c32',
                        textAlign: 'center',
                      }),
                      indicatorSeparator: (provided: any, state: any) => ({
                        ...provided,
                        display: 'none',
                      }),
                      dropdownIndicator: (provided: any, state: any) => ({
                        ...provided,
                        color: '#9096EC',
                        display: 'none',
                      }),
                      control: (provided: any, state: any) => ({
                        ...provided,
                        border: 'none',
                        borderRadius: '6px',
                        marginBottom: '3px',
                        padding: '3px',
                        fontSize: '1rem',
                        boxShadow: 'none',
                        backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                      }),
                      singleValue: (provided: any, state: any) => ({
                        ...provided,
                        color: state.isDisabled ? '#181c32' : 'inherit',
                        fontWeight: 500,
                      }),
                      option: (provided: any, state: any) => ({
                        ...provided,
                        fontSize: '1.0rem',
                        textAlign: 'center',
                      }),
                    }}
                    formatOptionLabel={(option) => (
                      <div
                        className="d-flex"
                        style={{ alignItems: 'center', width: '100%' }}
                      >
                        {option.data && option.data.profile_photo ? (
                          <>
                            <div className="symbol symbol-30px">
                              <img
                                src={`${process.env.REACT_APP_PUBLIC}users/${option.data.profile_photo}`}
                                title={`${option.data.first_name} ${option.data.father_last_name} ${option.data?.mother_last_name}`}
                                crossOrigin="anonymous"
                                className="symbol-label fs-5 "
                                alt="user"
                              />
                            </div>
                            <span className="ms-3 ">
                              {option.data.first_name}{' '}
                              {option.data.father_last_name}{' '}
                              {option.data?.mother_last_name}
                            </span>
                          </>
                        ) : (
                          <>
                            <div className="symbol symbol-30px">
                              <span className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold">
                                {option.data.first_name &&
                                  option?.data.first_name[0]}
                                {option.data.father_last_name &&
                                  option?.data.father_last_name[0]}
                              </span>
                            </div>
                            <span className="ms-3 ">
                              {option.data.first_name}{' '}
                              {option.data.father_last_name}{' '}
                              {option.data?.mother_last_name}
                            </span>
                          </>
                        )}
                      </div>
                    )}
                    placeholder={
                      <span className="symbol symbol-30px cursor-pointer">
                        <div className="symbol-label">
                          <KTSVG
                            path="/media/icons/duotune/communication/com006.svg"
                            className="svg-icon-1"
                          />
                        </div>
                      </span>
                    }
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{'No se encontraron registros'}</span>;
                    }}
                    onChange={(event) => userSelected(event)}
                  />
                  {Formulario.touched.user_asignado_id &&
                    Formulario.errors.user_asignado_id && (
                      <div className="fv-plugins-message-container text-danger w-100 mt-3 mb-3">
                        <span role="alert">
                          {Formulario.errors.user_asignado_id}
                        </span>
                      </div>
                    )}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required form-label w-auto">
                        Prioridad
                      </label>
                    </div>
                    <select
                      className="form-select mb-3"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      {...Formulario.getFieldProps('prioridad_id')}
                    >
                      <option value={0}>Seleccione</option>
                      {priority.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {Formulario.touched.prioridad_id &&
                      Formulario.errors.prioridad_id && (
                        <div className="fv-plugins-message-container text-danger w-100 mb-3">
                          <span role="alert">
                            {Formulario.errors.prioridad_id}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="form-label w-auto">Estatus</label>
                    </div>
                    {Formulario.values.statusTarea ? (
                      <>
                        <div
                          className={`mt-4 ${colorStatusTask(
                            Formulario.values.statusTarea.code || ''
                          )}`}
                        >
                          {tarea?.statusTarea && tarea.statusTarea.name}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`mt-4 badge badge-light-success`}>
                          No iniciada
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required form-label w-auto">
                        Tipo de moneda
                      </label>
                    </div>
                    <Select
                      options={currencyUnit}
                      value={
                        Formulario.values.moneda_id
                          ? currencyUnit.find(
                              (option) =>
                                Number(option.value) ===
                                Formulario.values.moneda_id
                            )
                          : null
                      }
                      styles={styleSelect}
                      placeholder={'Seleccione'}
                      isSearchable={true}
                      noOptionsMessage={() => {
                        return <span>{'No se encontraron registros'}</span>;
                      }}
                      onChange={changeCurrencyUnit}
                      isLoading={loadingCU}
                      backspaceRemovesValue
                    />
                    {Formulario.touched.moneda_id &&
                      Formulario.errors.moneda_id && (
                        <div className="fv-plugins-message-container text-danger w-100 mt-3">
                          <span role="alert">
                            {Formulario.errors.moneda_id}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 ">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="form-label w-auto">Descripción</label>
                    </div>
                    <textarea
                      className="form-control w-100"
                      data-kt-element="input"
                      maxLength={1000}
                      rows={5}
                      style={{ resize: 'none' }}
                      placeholder=""
                      {...Formulario.getFieldProps('descripcion')}
                    ></textarea>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="card mb-6 mb-xl-9">
          <div
            className="card-header cursor-pointer"
            onClick={() => {
              setOpenSection(!openSection);
            }}
            aria-controls="collapseProducts"
            aria-expanded={openSection}
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Recursos asignados</h3>
            </div>
            <div className="d-flex align-items-center">
              <div className="text-center me-5">
                <span className="fs-1 fw-bold" style={{ color: '#1B84FF' }}>
                  <CountUp
                    end={esfuerzoTotal}
                    duration={1}
                    prefix=""
                    decimals={0}
                  />
                </span>

                <span className="fs-7 fw-semibold opacity-50">
                  <span data-kt-element="period"> {unidadEsfuerzoCodigo}</span>
                </span>
              </div>
              <div
                className={`align-self-center`}
                style={{
                  transform: openSection
                    ? 'translateX(10%) rotate(-180deg)'
                    : '',
                  transition: 'transform 0.3s ease',
                }}
              >
                <KTSVG
                  path={'/media/icons/duotune/arrows/arr072.svg'}
                  className={`svg-icon-1`}
                />
              </div>
            </div>
          </div>
          {/* <h6>{exchangeRate.toString()}</h6> */}

          <Collapse in={openSection}>
            <div id="collapseProducts">
              <div className="card-body border-top p-9">
                <RecursosList
                  data={tarea.recursos}
                  unidadEsfuerzoId={Formulario.values.unidad_esfuerzo_id}
                  unidadEsfuerzoCodigo={unidadEsfuerzoCodigo}
                  cambioEsfuerzo={(totalEsfuerzo: number, recursos: any) => {
                    setEsfuerzoTotal(totalEsfuerzo);
                    Formulario.setFieldValue('recursos', recursos);
                    crearSelectRecursos(recursos);
                  }}
                  isSupervisor={isSupervisor}
                  fecha_inicio={Formulario.values.fecha_inicio}
                  fecha_fin={Formulario.values.fecha_fin}
                />
              </div>
            </div>
          </Collapse>
        </div>
        <div className="card mb-6 mb-xl-9">
          <div
            className="card-header cursor-pointer"
            onClick={() => {
              setOpenSectionAvance(!openSectionAvance);
            }}
            aria-controls="collapseAvance"
            aria-expanded={openSectionAvance}
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Avance</h3>
            </div>
            <div className="d-flex align-items-center">
              <div className="text-center me-5">
                <span className="fs-1 fw-bold" style={{ color: '#1B84FF' }}>
                  <CountUp
                    end={Formulario.values.esfuerzo_real_avance}
                    duration={1}
                    prefix=""
                    decimals={0}
                  />
                </span>

                <span className="fs-7 fw-semibold opacity-50">
                  <span data-kt-element="period"> {unidadEsfuerzoCodigo} </span>
                </span>
              </div>
              <div
                className={`align-self-center`}
                style={{
                  transform: openSectionAvance
                    ? 'translateX(10%) rotate(-180deg)'
                    : '',
                  transition: 'transform 0.3s ease',
                }}
              >
                <KTSVG
                  path={'/media/icons/duotune/arrows/arr072.svg'}
                  className={`svg-icon-1`}
                />
              </div>
            </div>
          </div>

          <Collapse in={openSectionAvance}>
            <div id="collapseAvance">
              <div className="card-body border-top p-9">
                <AvanceList
                  recursosAsignados={selectRecursos}
                  data={tarea.avances}
                  isSupervisor={isSupervisor}
                  unidadEsfuerzoId={Formulario.values.unidad_esfuerzo_id}
                  unidadEsfuerzoCodigo={unidadEsfuerzoCodigo}
                  avanceTotal={Formulario.values.avance}
                  fechaInicioAvance={Formulario.values.fecha_inicio_avance}
                  fechaFinAvance={Formulario.values.fecha_fin_avance}
                  cambioEsfuerzo={(avanceTotal: number, avances: any) => {
                    Formulario.setFieldValue(
                      'esfuerzo_real_avance',
                      avanceTotal
                    );
                    Formulario.setFieldValue('avances', avances);
                  }}
                  cambioAvance={(avanceTotal: number) => {
                    Formulario.setFieldValue('avance', avanceTotal);
                  }}
                  avanceRemove={avanceRemove}
                  emailColaborador={emailColaborador}
                  isColaborador={isColaborador}
                />
              </div>
            </div>
          </Collapse>
        </div>
        <div className="card mb-6 mb-xl-9">
          <div className="card-body">
            <div className="d-flex justify-content-end">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>

            {Number(tareaId) > 0 && (
              <>
                <div className="row g-6 g-xl-9" ref={tabSection}>
                  <div className="col-12">
                    <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                      <li className="nav-item cursor-pointer">
                        <a
                          className={`nav-link me-6 ${
                            tab === 'NOTE' ? 'text-active-primary active' : ''
                          } `}
                          onClick={() => {
                            setTab('NOTE');
                          }}
                        >
                          <span className="margin-left-05">Notas</span>
                        </a>
                      </li>
                      <li className="nav-item cursor-pointer">
                        <a
                          className={`nav-link me-6 ${
                            tab === 'DOC' ? 'text-active-primary active' : ''
                          } `}
                          onClick={() => {
                            setTab('DOC');
                          }}
                        >
                          <span className="margin-left-05">Documentos</span>
                        </a>
                      </li>
                      <li className="nav-item cursor-pointer">
                        <a
                          className={`nav-link me-6 ${
                            tab === 'AUD' ? 'text-active-primary active' : ''
                          } `}
                          onClick={() => {
                            setTab('AUD');
                          }}
                        >
                          <span className="margin-left-05">Audios</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12">
                    {tab === 'NOTE' ? (
                      <>
                        <NoteTaskList
                          data={notes}
                          onOpenModal={openModal}
                          active={activeNewNote}
                          onOpenDetailModal={openDetailModal}
                        ></NoteTaskList>
                      </>
                    ) : null}
                    {tab === 'DOC' ? (
                      <>
                        <TaskDoc
                          documents={dataDocuments || []}
                          onRefreshData={() => {
                            setReloadDocuments(Math.random() * 100);
                            onRefreshHistory();
                          }}
                          task={tareaId}
                          isEdit={formIsEdit}
                        ></TaskDoc>
                      </>
                    ) : null}
                    {tab === 'AUD' ? (
                      <>
                        <AudioTaskList
                          data={dataAudios}
                          active={activeNewNote}
                        ></AudioTaskList>
                      </>
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
      <CreateNoteTaskModal
        show={showNoteModal}
        handleClose={(refresh) => {
          setShowNoteModal(!showNoteModal);
          if (refresh) {
            setReloadGridNotes(Math.random() * 40);
            onRefreshHistory();
          }
        }}
        id={Number(tareaId)}
      ></CreateNoteTaskModal>
      <DetailNoteTaskModal
        show={showNoteDetailModal}
        handleClose={(refresh) => {
          setShowNoteDetailModal(!showNoteDetailModal);
        }}
        data={notes}
        id={Number(tareaId)}
      ></DetailNoteTaskModal>
    </>
  );
};

export { TareaForm };
