import React, { useState, useEffect, useRef } from 'react';
import { encryptId, SimpleAlert } from '../../../../../_metronic/helpers';
import { Link } from 'react-router-dom';
import { dateFormatt } from '../../../../../_metronic/helpers/FormatDate';
import { useHistory } from 'react-router-dom';
import { ProyectoModel } from '../../../bitacora/proyectos/models/ProyectoModel';
import { useGetProjectsByIdOportunity } from '../../hooks/OportunidadHook';
import { updateActiveProject } from '../../../../service/proyectos/Proyectos';

type Props = {
  oportunidad_id: number;
};

const ProjectList: React.FC<Props> = ({ oportunidad_id }) => {
  const radios = useRef<any>([]);
  const [reloadProject, setReloadProject] = useState(Math.random() * 40);
  const { projects } = useGetProjectsByIdOportunity(
    Number(oportunidad_id),
    reloadProject
  );
  const [dataMemo, setDataMemo] = useState<any[]>(projects);
  const [loading, setLoading] = useState<boolean>(false);
  const [cargandoEliminacion, setCargandoEliminacion] = useState<number | null>(
    null
  );

  const seleccionarProyecto = (item: any) => {
    const { id } = item;
    if (oportunidad_id > 0 && id > 0) {
      setCargandoEliminacion(id);
      updateActiveProject(oportunidad_id, id)
        .then((res) => {
          const {
            data: { message },
          } = res;
          setCargandoEliminacion(null);
          setReloadProject(Math.random() * 40);
          SimpleAlert(message, 3000, 'success');
        })
        .catch((err) => {
          let resMessageToast: string = '';
          const {
            data: { message, details = null },
          } = err.response;
          resMessageToast = `${message}`;
          setCargandoEliminacion(null);
          SimpleAlert(resMessageToast, 3000, 'error');
        });
    }
  };

  useEffect(() => {
    if (projects.length) {
      setDataMemo(projects);
    }
  }, [projects]);

  useEffect(() => {
    return () => {
      setDataMemo([]);
    };
  }, []);

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-300px">Nombre</th>
              <th className="w-100px">Fecha inicio</th>
              <th className="w-100px">Fecha final</th>
              <th className="w-100px"></th>
            </tr>
          </thead>
          <tbody className="">
            {dataMemo.map((row: ProyectoModel, index) => (
              <tr key={row.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      <Link
                        className="text-primary"
                        to={`/proyectos/proyectos/edit/${encryptId(
                          row.id.toString()
                        )}`}
                      >
                        {row.nombre}
                      </Link>
                    </div>
                  </div>
                </td>

                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.fecha_inicio
                        ? dateFormatt(row.fecha_inicio)
                        : row.fecha_inicio}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.fecha_final
                        ? dateFormatt(row.fecha_final)
                        : row.fecha_final}
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      justifyContent: 'center',
                    }}
                  >
                    <div className="d-flex ">
                      <label className="form-check form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={row.activo_oportunidad}
                          onClick={async () => {
                            seleccionarProyecto(row);
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export { ProjectList };
