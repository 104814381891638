import React from 'react';
import { Link } from 'react-router-dom';
import { encryptId } from '../../../../_metronic/helpers';
import { formattedCurrency } from '../../../../_metronic/helpers/FormatCurrency';
import { TableList } from '../../../../_metronic/helpers/components/TableList';
import { EmptyResult } from '../../../../_metronic/helpers/components/EmptyResult';
import { CollaboratorModel } from '../models/CollaboratorModel';
import { width } from '@mui/system';

type Props = {
  data: any;
  loadingGrid: boolean;
};

const ListGrid: React.FC<Props> = ({ data, loadingGrid }) => {
  const columns: any = [
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) =>
        row.contacto?.nombre &&
        row.contacto?.apellido_paterno &&
        row.contacto?.apellido_materno
          ? `${row.contacto?.nombre} ${row.contacto?.apellido_paterno} ${row.contacto?.apellido_materno}`
          : 'No registrado',
      cell: (row: any) => (
        <Link
          to={`/recursos_humanos/colaboradores/edit/${encryptId(
            row.id.toString()
          )}`}
        >
          {row.contacto?.nombre &&
          row.contacto?.apellido_paterno &&
          row.contacto?.apellido_materno
            ? `${row.contacto?.nombre} ${row.contacto?.apellido_paterno} ${row.contacto?.apellido_materno}`
            : 'No registrado'}
        </Link>
      ),
      sortable: true,
      width: '35%',
    },
    {
      id: 'perfil',
      name: <div className="fw-bolder text-muted me-5">Perfil asociado</div>,
      selector: (row: any) =>
        row.perfil?.name ? row.perfil.name : 'No registrado',
      cell: (row: any) => (
        <div>{row.perfil?.name ? row.perfil.name : 'No registrado'}</div>
      ),
      sortable: true,
      width: '20%',
    },
    {
      id: 'telefono',
      name: <div className="fw-bolder text-muted me-5">Teléfono</div>,
      selector: (row: any) => (row.telefono ? row.telefono : 'No registrado'),
      cell: (row: any) => (
        <div>{row.telefono ? row.telefono : 'No registrado'}</div>
      ),
      sortable: true,
      width: '20%',
    },
    {
      id: 'departamento',
      name: <div className="fw-bolder text-muted me-5">Departamento</div>,
      selector: (row: any) =>
        row.empleos?.length > 0
          ? row.empleos[row.empleos?.length - 1]?.departamento?.name
          : 'No registrado',
      cell: (row: any) => (
        <div>
          {row.empleos?.length > 0
            ? row.empleos[row.empleos?.length - 1]?.departamento?.name
            : 'No registrado'}
        </div>
      ),
      sortable: true,
      width: '20%',
    },
  ];

  return (
    <>
      <div className={`row`}>
        <div className="col-xl-12">
          <div className={`card mb-0 mb-xxl-8 h-100 w-100`}>
            {!loadingGrid ? (
              <TableList data={data} columns={columns}></TableList>
            ) : (
              <EmptyResult title="" loading={loadingGrid} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
