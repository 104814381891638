import React, { useState, useEffect } from 'react';
import { KTSVG, encryptId } from '../../../../../../_metronic/helpers';
import { Link } from 'react-router-dom';
import { ContactoModel } from '../../../../contacto/models/ContactoModel';

type Props = {
  data: ContactoModel[];
  onOpenModal: (isOpen: boolean) => void;
};

const ContactSupplierList: React.FC<Props> = ({ data, onOpenModal }) => {
  const [dataMemo, setDataMemo] = useState<ContactoModel[]>(data);

  useEffect(() => {
    setDataMemo(data);
  }, [data]);

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-150px">Nombre</th>
              <th className="w-150px">Apellido Paterno</th>
              <th className="w-150px">Apellido Materno</th>
              <th className="w-100px">Correo</th>
              <th className="w-100px">Teléfono</th>
            </tr>
          </thead>
          <tbody className="">
            {dataMemo.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      <Link
                        className="text-primary"
                        to={`/clientes/contactos/edit/${encryptId(
                          row.id.toString()
                        )}`}
                      >
                        {row.nombre}
                      </Link>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012">
                      {row.apellido_paterno}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.apellido_materno}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.email}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.telefono}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={5}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={() => {
                      onOpenModal(true);
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar contacto
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { ContactSupplierList };
