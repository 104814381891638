import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Board from 'react-trello';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { encryptId, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { colorStatusTask } from '../../../../_metronic/helpers/CustomElements';
import { actualizarEtapaTarea } from '../../../service/bitacora/Bitacora';
import { updValueCatalog } from '../../../service/catalog/Catalogs';

type Props = {
  etapasConTareas: any;
  onRefreshData: () => void;
};

const ListadoTarjeta: React.FC<Props> = ({
  etapasConTareas,
  onRefreshData,
}) => {
  const history = useHistory();
  const [actualizaTablero, setActualizaTablero] = useState<number>(
    Math.random() * 40
  );
  const databoard = {
    lanes: etapasConTareas,
  };
  const tarjeta = (props: any) => {
    const {
      id,
      nombre,
      user_asignado,
      onClick,
      draggable,
      tipoTarea,
      avance,
      esfuerzo_real_avance,
      unidadEsfuerzo,
      statusTarea,
      oportunidad,
    } = props;

    const { name: nombre_estatus, code: codigo_estatus } = statusTarea;

    return (
      <div
        className="card mb-2 borders-card-board cursor-pointer w-0310"
        onClick={() => {
          if (oportunidad) {
            history.push(
              `/oportunidades_tareas/${
                oportunidad?.id ? encryptId(oportunidad.id.toString()) : ''
              }/edit/${encryptId(id.toString())}`
            );
          } else {
            history.push(`/bitacora/tarea/edit/${encryptId(id.toString())}`);
          }
        }}
        title={nombre}
        style={{ cursor: !draggable ? 'not-allowed' : '' }}
      >
        <div className="card-header d-flex border-0 mt-3 padding-card-board ">
          <div className="wh-0100">
            <div className="sp-bt-c wh-0100">
              <span className="fw-bolder text-gray-700 large-text">
                {nombre}
              </span>
              {user_asignado ? (
                <>
                  <div className="symbol symbol-40px">
                    {user_asignado.profile_photo ? (
                      <img
                        src={`${process.env.REACT_APP_PUBLIC}users/${user_asignado.profile_photo}`}
                        title={`${user_asignado.first_name} ${user_asignado.father_last_name} ${user_asignado.mother_last_name}`}
                        crossOrigin="anonymous"
                        className="symbol-label fs-5 "
                        alt="user"
                      />
                    ) : (
                      <span
                        className="symbol-label fs-5 fw-bold bg-light-primary text-primary fw-bold"
                        title={`${user_asignado.first_name} ${user_asignado.father_last_name} ${user_asignado.mother_last_name}`}
                      >{`${user_asignado.first_name[0]}${user_asignado.father_last_name[0]}`}</span>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <span className="symbol symbol-40px cursor-pointer">
                    <div className="symbol-label">
                      <KTSVG
                        path="/media/icons/duotune/communication/com006.svg"
                        className="svg-icon-1"
                      />
                    </div>
                  </span>
                </>
              )}
            </div>
          </div>
          <div>
            <div>
              <span className="text-gray-700 mt-1 fw-bold fs-7">
                {tipoTarea ? tipoTarea.name : 'N/A'}
              </span>
            </div>
            <div className="text-gray-600 mt-1 fw-semibold fs-7 mb-2">
              {esfuerzo_real_avance || 0}{' '}
              {unidadEsfuerzo?.code?.toLowerCase() || 'hrs'}
            </div>
          </div>
        </div>
        <div className="card-body padding-card-board">
          <div className="sp-bt-c mb-5">
            <div className=" bg-light rounded w-100 height-0010px">
              <div
                className="badge-primary rounded text-center height-0010px fs-010"
                role="progressbar"
                style={{ width: `${avance}%` }}
              >{`${avance}%`}</div>
            </div>
            <div className="margin-left-020">
              <span className={`${colorStatusTask(codigo_estatus)}`}>
                {nombre_estatus}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const encabezado = (props: any) => {
    const { color_etapa, title, index, code, etapa_id } = props;
    return (
      <>
        <div
          className="card borders"
          style={{ borderTop: `solid 5px ${color_etapa}` }}
        >
          <div
            className="sp-bt-c padding-card-board "
            style={{ minHeight: '60px' }}
          >
            <div className="d-flex">
              {code !== 'TPEND' &&
              code !== 'TEPRO' &&
              code !== 'TCOMPL' &&
              code !== 'TCANC' ? (
                <>
                  <input
                    className="large-text fw-bolder text-gray-700"
                    // ref={(el) => (itemsRef.current[index] = el)}
                    // onClick={() => {
                    //   currentItem(index);
                    // }}
                    style={{
                      border: 'none',
                      backgroundColor: '#fff',
                      outline: 'none',
                    }}
                    defaultValue={title}
                    onKeyDown={(event: any) => {
                      if (
                        event.target.value.length >= 20 &&
                        event.key !== 'Backspace' &&
                        event.key !== 'Delete'
                      ) {
                        event.preventDefault();
                      }

                      if (event.key === 'Enter') {
                        actualizarNombreEtapa(
                          event.target.value,
                          code,
                          etapa_id
                        );
                        // itemsRef.current[index].value = event.target.value;
                        // nameUpdateStage(event.target.value, props, index);
                      }
                    }}
                    title={''}
                    onBlur={(event: any) => {
                      actualizarNombreEtapa(event.target.value, code, etapa_id);
                      // nameUpdateStage(event.target.value, props, index);
                      // itemsRef.current[index].className = '';
                    }}
                  />
                </>
              ) : (
                <div className="large-text fw-bolder text-gray-700">
                  {title}
                </div>
              )}
            </div>
            <div className="text-muted cursor-pointer " title="Total de tareas">
              {props.cards.length}
            </div>

            {code !== 'TCOMPL' && code !== 'TCANC' ? (
              <div
                className="btn btn-sm btn-icon  btn-active-light-primary"
                title="Agregar nueva oportunidad"
                onClick={() => {
                  history.push(
                    `/bitacora/tarea/add/${encryptId(etapa_id.toString())}`
                  );
                }}
              >
                <KTSVG
                  path="/media/icons/duotune/abstract/abs011.svg"
                  className="svg-icon-6"
                />
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  };
  const actualizarNombreEtapa = async (
    nombre: string,
    codigo: string,
    etapa_id: number
  ) => {
    let data = {
      id: etapa_id,
      code: codigo,
      name: nombre,
    };
    updValueCatalog('', data)
      .then((res) => {
        // itemsRef.current[currentInput].value = res.data.doc.data[1][0].name;
        // onRefreshData();
      })
      .catch((err) => {
        let resMessageToast: string = '';
        const {
          data: { message, details = null },
        } = err.response;
        resMessageToast = `${message}`;

        toast.error(
          'Hubo un problema al intentar cambiar el nombre de la etapa, intentelo más tarde',
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          }
        );
      });
  };

  const actualizaTarea = async (tareaId: number, codeStage: string) => {
    const values = {
      tareaId: tareaId,
      etapaCode: codeStage,
    };
    actualizarEtapaTarea(values)
      .then((res) => {
        const {
          data: { message },
        } = res;
        onRefreshData();
        toast.success(message, {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })
      .catch((err) => {
        onRefreshData();
        toast.error(
          'Hubo un problema al intentar cambiar de etapa la tarea, intentelo más tarde',
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          }
        );
      });
  };
  const components = {
    Card: tarjeta,
    LaneHeader: encabezado,
  };

  return (
    <>
      {
        <Board
          key={actualizaTablero}
          data={databoard}
          onCardClick={(event: any) => {}}
          handleDragEnd={async (
            cardId: any,
            sourceLaneId: any,
            targetLaneId: any,
            position: any,
            cardDetails: any
          ) => {
            if (targetLaneId !== sourceLaneId) {
              switch (targetLaneId) {
                case 'TCOMPL':
                  Swal.fire({
                    title:
                      '¿Confirmas que deseas marcar esta tarea como completada?',
                    html: `
                    <div className="text-gray-600">
                      La tarea se marcará como completada. Una vez completada, no podrás modificar ningún dato asociado a ella.
                    </div>
                    `,
                    showCancelButton: true,
                    padding: '2em',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Aceptar',
                    reverseButtons: true,
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      cancelButton: 'btn btn-light me-3',
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      actualizaTarea(cardId, targetLaneId);
                    } else {
                      setActualizaTablero(Math.random() * 40);
                    }
                  });
                  break;
                default:
                  actualizaTarea(cardId, targetLaneId);
                  break;
              }
            }
          }}
          editable
          components={components}
        />
      }
    </>
  );
};

export { ListadoTarjeta };
