import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import Swal from 'sweetalert2';
import { styleSelect } from '../../../../../../_metronic/helpers/SelectStyles';
import { KTSVG, SimpleAlert } from '../../../../../../_metronic/helpers';
import { TareaAvancesModel } from '../../../../tasks/models/TaskModel';
import { sumar } from '../../../../../../_metronic/helpers/PriceCalculations';
import { formatDate } from '../../../../../../_metronic/helpers/FormatDate';

type Props = {
  recursosAsignados: any[];
  data: TareaAvancesModel[];
  unidadEsfuerzoId: number;
  unidadEsfuerzoCodigo: string;
  avanceTotal: number;
  fechaInicioAvance: string;
  fechaFinAvance: string;
  isSupervisor: boolean;
  cambioEsfuerzo: (totalEsfuerzo: number, recursos: any) => void;
  cambioAvance: (totalEsfuerzo: number) => void;
  avanceRemove: (avanceRow: any) => void;
  emailColaborador: string;
  isColaborador: boolean;
};

const AvanceList: React.FC<Props> = ({
  recursosAsignados,
  data,
  unidadEsfuerzoId,
  unidadEsfuerzoCodigo,
  avanceTotal,
  fechaInicioAvance,
  fechaFinAvance,
  cambioEsfuerzo,
  cambioAvance,
  isSupervisor,
  avanceRemove,
  emailColaborador,
  isColaborador,
}) => {
  // let today = new Date().toISOString().split('T')[0];
  let today = '';
  const [dataMemo, setDataMemo] = useState<any[]>(data);
  const [recursos, setRecursos] = useState<any[]>([]);
  const [esfuerzoReal, setEsfuerzoReal] = useState<number>(0);
  const [deletedLoading, setDeletedLoading] = useState<number>(0);

  const numeroRandom = (): number => {
    return Math.floor(10000 + Math.random() * 90000);
  };

  const AddRow = () => {
    if (unidadEsfuerzoId <= 0) {
      SimpleAlert('Favor seleccione la unidad de Esfuerzo.', 3000, 'error');
      return false;
    }
    const cleanedData = dataMemo.filter((item) => item.recurso_id !== 0);
    const newRow: TareaAvancesModel = {
      esNuevo: 1,
      referencia: numeroRandom(),
      id: 0,
      recurso_id: 0,
      fecha: today,
      esfuerzo: 0,
      colaborador: {
        id: 0,
        contacto: {
          id: 0,
          nombre: '',
          apellido_paterno: '',
          apellido_materno: '',
        },
        perfil: {
          id: 0,
          name: '',
          code: '',
        },
      },
      etapa: {
        id: 0,
        name: '',
        code: '',
      },
      unidadEsfuerzo: {
        id: unidadEsfuerzoId,
        name: unidadEsfuerzoCodigo,
        code: unidadEsfuerzoCodigo,
      },
      perfil: {
        id: 0,
        name: '',
        code: '',
      },
    };
    setDataMemo([...cleanedData, newRow]);
  };

  const addConcept = (selectedOption: any) => {
    if (selectedOption) {
      const { id, data } = selectedOption;
      const cleanedData = dataMemo.filter((item) => item.recurso_id !== 0);
      const updateRow = {
        id: 0,
        esNuevo: 1,
        referencia: numeroRandom(),
        recurso_id: id,
        fecha: today,
        esfuerzo: 0,
        recurso: data?.colaborador,
        perfil: data.perfil,
        etapa: {
          id: 0,
          name: '',
          code: '',
        },
        unidadEsfuerzo: data.unidadEsfuerzo,
      };
      setDataMemo((prevDataMemo) => {
        const updatedDataMemo = [...cleanedData, updateRow];
        sumAmount(updatedDataMemo);
        return updatedDataMemo;
      });
      // } else {
      //   setDataMemo([...cleanedData]);
      //   SimpleAlert('Ya existe este recurso asignado.', 3000, 'error');
      // }
    }
  };
  const volumeChange = (newVolumen: number, referencia: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.referencia === referencia) {
          return {
            ...item,
            esfuerzo: newVolumen,
          };
        }
        return item;
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };
  const dateChange = (
    newDate: string,
    referencia: number,
    recurso_id: number
  ) => {
    const existing = dataMemo.find(
      (item) => item.recurso.id === recurso_id && item.fecha === newDate
    );
    if (existing) {
      SimpleAlert(
        'Ya existe este recurso asignado en la fecha actual.',
        3000,
        'error'
      );
      return false;
    }
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.referencia === referencia) {
          return {
            ...item,
            fecha: newDate,
          };
        }
        return item;
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  const sumAmount = (updatedDataMemo: any) => {
    const totalEsfuerzo = updatedDataMemo.reduce((total: number, item: any) => {
      return sumar(total, Number(item.esfuerzo));
    }, 0);
    setEsfuerzoReal(totalEsfuerzo);
    cambioEsfuerzo(totalEsfuerzo, updatedDataMemo);
  };

  const deleteRowById = (referencia: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.filter(
        (item) => item.referencia !== referencia
      );
      const avanceDeteled = prevDataMemo.find(
        (item) => item.referencia === referencia && item.esNuevo === 0
      );
      if (avanceDeteled) {
        avanceRemove(avanceDeteled);
      }
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  const deleteAvance = async (referencia: number, nombre: string) => {
    setDeletedLoading(referencia);
    Swal.fire({
      title: '¿Deseas eliminar el avance?',
      html: `
            <div className="text-gray-600">
              Se va a eliminar el avance del recurso: "<b>${nombre}</b>".
            </div>
            `,
      showCancelButton: true,
      padding: '2em',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light me-3',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRowById(Number(referencia));
        setDeletedLoading(0);
      } else {
        setDeletedLoading(0);
      }
    });
  };

  useEffect(() => {
    const cleanedData = data.filter((item) => item.id !== 0);
    sumAmount(cleanedData);
    setDataMemo(cleanedData);
  }, [data]);

  useEffect(() => {
    return () => {
      setDataMemo([]);
    };
  }, []);

  useEffect(() => {
    if (recursosAsignados) {
      console.log('🚀 ~ useEffect ~ emailColaborador:', emailColaborador);
      console.log('🚀 ~ useEffect ~ isColaborador:', isColaborador);
      if (isColaborador && emailColaborador) {
        let newRecursosAsignados = recursosAsignados.find(
          (item) => item.data.colaborador.email === emailColaborador
        );
        setRecursos(newRecursosAsignados ? [newRecursosAsignados] : []);
      } else {
        setRecursos(recursosAsignados);
      }
    }
  }, [recursosAsignados, emailColaborador, isColaborador]);

  return (
    <>
      <div className="row mb-10">
        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">Fecha inicio </label>
            </div>
            <div className="mt-3">
              <div className="w-100 text-gray-700 mt-2">
                {fechaInicioAvance
                  ? formatDate(new Date(fechaInicioAvance))
                  : 'No registrado'}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">Fecha final </label>
            </div>
            <div className="mt-3">
              <div className="w-100 text-gray-700 mt-2">
                {fechaFinAvance
                  ? formatDate(new Date(fechaFinAvance))
                  : 'No registrado'}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">Esfuerzo real</label>
            </div>
            <div className="mt-3">
              <div className="w-100 text-gray-700 mt-2">
                {esfuerzoReal} {unidadEsfuerzoCodigo}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">% de avance</label>
            </div>
            <div className="mt-3">
              <div className="w-100 text-gray-700 mt-2">
                <NumericFormat
                  type="text"
                  autoComplete="off"
                  className="form-control mb-3"
                  value={avanceTotal}
                  decimalScale={0}
                  decimalSeparator="."
                  fixedDecimalScale
                  placeholder={'0 %'}
                  disabled={!isSupervisor}
                  suffix={' %'}
                  isAllowed={(values: any) => {
                    const { floatValue } = values;
                    let allow = floatValue <= 100 || floatValue === undefined;
                    return allow;
                  }}
                  allowNegative={false}
                  thousandSeparator={''}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    if (floatValue) {
                      if (floatValue > 0) {
                        cambioAvance(floatValue);
                      }
                    } else {
                      cambioAvance(floatValue || 0);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-200px">Recurso asignado</th>
              <th className="w-200px">Perfil</th>
              <th className="w-200px">Fecha</th>
              <th className="w-200px">Etapa</th>
              <th className="w-200px">Unidad de esfuerzo</th>
              <th className="w-200px">Esfuerzo</th>
              <th className="w-100px"></th>
            </tr>
          </thead>
          <tbody>
            {dataMemo.length > 0 ? (
              dataMemo.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-700 fw-bold ">
                        {row.recurso_id === 0 ? (
                          <Select
                            key={row.id}
                            options={recursos}
                            styles={styleSelect}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={(e) => {
                              addConcept(e);
                            }}
                            backspaceRemovesValue
                          />
                        ) : (
                          <>
                            {row.recurso &&
                              row.recurso.contacto &&
                              row.recurso.contacto && (
                                <>{`${row.recurso.contacto.nombre} ${row.recurso.contacto.apellido_paterno}`}</>
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-700 fw-bold ">
                        {row.recurso_id === 0 ? (
                          <>Sin perfil</>
                        ) : (
                          <>
                            {row.recurso?.perfil && (
                              <>{`${row.recurso?.perfil?.name}`}</>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        <>
                          {row.recurso_id === 0 ? (
                            <>{row.fecha}</>
                          ) : (
                            <>
                              <input
                                className={'form-control'}
                                data-kt-element="input"
                                type="date"
                                // min={today}
                                disabled={row.id}
                                name="fecha_inicio"
                                value={row.fecha}
                                onChange={(e) => {
                                  dateChange(
                                    e.target.value,
                                    row.referencia,
                                    row.recurso.id
                                  );
                                }}
                              />
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        <>
                          {row.etapa && row.etapa.id > 0 ? (
                            <>{row.etapa.name}</>
                          ) : (
                            <>Pendiente</>
                          )}
                        </>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        <>
                          {row.unidadEsfuerzo && row.unidadEsfuerzo.id > 0 ? (
                            <>{row.unidadEsfuerzo.code}</>
                          ) : (
                            <>Sin unidad</>
                          )}
                        </>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.recurso_id !== 0 ? (
                          <NumericFormat
                            className="form-control w-200px"
                            thousandSeparator={false}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                            allowNegative={false}
                            decimalScale={0}
                            disabled={row.id}
                            value={row.esfuerzo}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (!value) {
                                e.target.value = '0';
                              }
                            }}
                            isAllowed={(values: any) => {
                              const { floatValue } = values;
                              return (
                                floatValue !== undefined &&
                                floatValue <= 24 &&
                                Number.isInteger(floatValue)
                              );
                            }}
                            // isAllowed={(values: any) => {
                            //   const { floatValue } = values;
                            //   const integerPart = Math.floor(floatValue);
                            //   return integerPart.toString().length <= 10;
                            // }}
                            suffix=""
                            allowLeadingZeros={false}
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              if (floatValue) {
                                if (floatValue > 0) {
                                  volumeChange(floatValue, row.referencia);
                                }
                              } else {
                                volumeChange(floatValue || 0, row.referencia);
                              }
                            }}
                          />
                        ) : (
                          <>{'0'}</>
                        )}
                      </div>
                    </div>
                  </td>

                  <td></td>
                  {row.recurso_id !== 0 && (
                    <>
                      {isSupervisor ? (
                        <>
                          <div className="d-flex align-items-center">
                            {deletedLoading === row.referencia ? (
                              <>
                                <div className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary ms-2">
                                  <span
                                    className="indicator-progress"
                                    style={{ display: 'block' }}
                                  >
                                    <span className="spinner-border spinner-border-sm align-middle"></span>
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary ms-2"
                                  onClick={(event) => {
                                    deleteAvance(
                                      Number(row.referencia),
                                      `${row.recurso.contacto.nombre} ${row.recurso.contacto.apellido_paterno}`
                                    );
                                  }}
                                  title="Eliminar avance"
                                >
                                  <KTSVG path="/media/icons/duotune/general/gen027.svg" />
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          {row.recurso.email === emailColaborador && (
                            <>
                              <div className="d-flex align-items-center">
                                {deletedLoading === row.referencia ? (
                                  <>
                                    <div className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary ms-2">
                                      <span
                                        className="indicator-progress"
                                        style={{ display: 'block' }}
                                      >
                                        <span className="spinner-border spinner-border-sm align-middle"></span>
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary ms-2"
                                      onClick={(event) => {
                                        deleteAvance(
                                          Number(row.referencia),
                                          `${row.recurso.contacto.nombre} ${row.recurso.contacto.apellido_paterno}`
                                        );
                                      }}
                                      title="Eliminar avance"
                                    >
                                      <KTSVG path="/media/icons/duotune/general/gen027.svg" />
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className="text-center text-muted">
                  Sin recursos
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={6}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddRow}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Esfuerzo
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { AvanceList };
