import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { styleSelect } from '../../../../../../_metronic/helpers/SelectStyles';
import { KTSVG, SimpleAlert } from '../../../../../../_metronic/helpers';
import { TareaRecursosModel } from '../../../../tasks/models/TaskModel';
import { useSelectPerfiles, useSelectRecursos } from '../../hooks/TareasHook';
import { sumar } from '../../../../../../_metronic/helpers/PriceCalculations';
import { DetailTaskModal } from '../../_modals/DetailTaskModal';

type Props = {
  data: TareaRecursosModel[];
  unidadEsfuerzoId: number;
  unidadEsfuerzoCodigo: string;
  cambioEsfuerzo: (totalEsfuerzo: number, recursos: any) => void;
  isSupervisor: boolean;
  fecha_inicio: string;
  fecha_fin: string;
};

const RecursosList: React.FC<Props> = ({
  data,
  unidadEsfuerzoId,
  unidadEsfuerzoCodigo,
  cambioEsfuerzo,
  isSupervisor,
  fecha_inicio,
  fecha_fin,
}) => {
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [register, setRegister] = useState<any>({});
  const [dataMemo, setDataMemo] = useState<any[]>(data);
  const [codigoPerfil, setCodigoPerfil] = useState<string>('');
  const [nombrePerfil, setNombrePerfil] = useState<string>('');
  const [idPerfil, setIdPerfil] = useState<number>(0);
  const { catalog: perfiles, loadingRequest: loadingPerfiles } =
    useSelectPerfiles();
  const { catalog: recursos, loadingRequest: loadingRecursos } =
    useSelectRecursos(idPerfil);

  const AddRow = () => {
    if (unidadEsfuerzoId <= 0) {
      SimpleAlert('Favor seleccione la unidad de Esfuerzo.', 3000, 'error');
      return false;
    }
    const cleanedData = dataMemo.filter((item) => item.id !== 0);
    const newRow: TareaRecursosModel = {
      id: 0,
      esfuerzo: 0,
      costo: 0,
      perfil: {
        id: 0,
        name: '',
        code: '',
      },
      unidadEsfuerzo: {
        id: unidadEsfuerzoId,
        name: unidadEsfuerzoCodigo,
        code: unidadEsfuerzoCodigo,
      },
      colaborador: {
        id: 0,
        email: '',
        contacto: {
          id: 0,
          nombre: '',
          apellido_paterno: '',
          apellido_materno: '',
        },
        perfil: {
          id: 0,
          name: '',
          code: '',
        },
      },
    };
    setDataMemo([...cleanedData, newRow]);
  };

  const loadConcepts = (selectedOption: any) => {
    if (selectedOption) {
      const { value, label, data } = selectedOption;
      const { code } = data;
      setCodigoPerfil(code);
      setIdPerfil(value);
      setNombrePerfil(label);
      setDataMemo((prevDataMemo) => {
        const updatedDataMemo = prevDataMemo.map((item) => {
          if (item.id === 0) {
            return {
              ...item,
              perfil: {
                id: value,
                name: label,
                code: code,
              },
            };
          }
          return item;
        });
        return updatedDataMemo;
      });
    }
  };
  const addConcept = (selectedOption: any) => {
    if (selectedOption) {
      const { id, data } = selectedOption;
      const { email } = data;
      const {
        id: contacto_id,
        nombre,
        apellido_paterno,
        apellido_materno,
      } = data.contacto;

      const cleanedData = dataMemo.filter((item) => item.id !== 0);
      const existingIndex = dataMemo.findIndex((item) => item.id === id);
      if (existingIndex === -1) {
        const updateRow = {
          id: id,
          esfuerzo: 0,
          costo: 0,
          perfil: {
            id: idPerfil,
            name: nombrePerfil,
            code: codigoPerfil,
          },
          unidadEsfuerzo: {
            id: unidadEsfuerzoId,
            name: unidadEsfuerzoCodigo,
            code: unidadEsfuerzoCodigo,
          },
          colaborador: {
            id: id,
            email: email,
            contacto: {
              id: contacto_id,
              nombre: nombre,
              apellido_paterno: apellido_paterno,
              apellido_materno: apellido_materno,
            },
            perfil: {
              id: idPerfil,
              name: nombrePerfil,
              code: codigoPerfil,
            },
          },
        };
        setDataMemo((prevDataMemo) => {
          const updatedDataMemo = [...cleanedData, updateRow];
          sumAmount(updatedDataMemo);
          return updatedDataMemo;
        });
      } else {
        setDataMemo([...cleanedData]);
        SimpleAlert('Ya existe un recurso en esta tarea.', 3000, 'error');
      }
    }
  };
  const volumeChange = (newVolumen: number, recursoId: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === recursoId) {
          return {
            ...item,
            esfuerzo: newVolumen,
          };
        }
        return item;
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  const costChange = (newCost: number, recursoId: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === recursoId) {
          return {
            ...item,
            costo: newCost,
          };
        }
        return item;
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  const agregarEsfuerzo = (
    e: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    e.preventDefault();
    setRegister(row);
    setShowDetailModal(true);
  };

  const sumAmount = (updatedDataMemo: any) => {
    const totalEsfuerzo = updatedDataMemo.reduce((total: number, item: any) => {
      return sumar(total, Number(item.esfuerzo));
    }, 0);
    cambioEsfuerzo(totalEsfuerzo, updatedDataMemo);
  };

  useEffect(() => {
    const cleanedData = data.filter((item) => item.id !== 0);
    sumAmount(cleanedData);
    setDataMemo(cleanedData);
  }, [data]);

  useEffect(() => {
    return () => {
      setDataMemo([]);
    };
  }, []);

  return (
    <>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-300px">Perfil</th>
              <th className="w-300px">Recurso asignado</th>
              <th className="w-200px">Unidad de esfuerzo</th>
              <th className="w-200px">Esfuerzo</th>
              <th className="w-200px">Costo</th>
              {/* <th className="w-100px"></th> */}
            </tr>
          </thead>
          <tbody>
            {dataMemo.length > 0 ? (
              dataMemo.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-700 fw-bold ">
                        {row.id === 0 ? (
                          <Select
                            key={row.id}
                            options={perfiles}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={styleSelect}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={loadConcepts}
                            backspaceRemovesValue
                          />
                        ) : (
                          <>
                            {row.colaborador && row.colaborador.perfil && (
                              <>{row.colaborador.perfil.name}</>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-700 fw-bold ">
                        {row.id === 0 ? (
                          <Select
                            key={row.id}
                            options={recursos}
                            styles={styleSelect}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={(e) => {
                              addConcept(e);
                            }}
                            isLoading={loadingRecursos}
                            backspaceRemovesValue
                          />
                        ) : (
                          <>
                            {row.colaborador && row.colaborador.contacto && (
                              <>{`${row.colaborador.contacto.nombre} ${row.colaborador.contacto.apellido_paterno}`}</>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        <>
                          {row.unidadEsfuerzo && row.unidadEsfuerzo.id > 0 ? (
                            <>{row.unidadEsfuerzo.code}</>
                          ) : (
                            <>Sin unidad</>
                          )}
                        </>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center">
                      {row.id !== 0 && (
                        <>
                          <div className="d-flex flex-column text-gray-600">
                            <button
                              title="Consultar horas disponibles"
                              className="btn btn-bg-light btn-active-color-primary btn-sm"
                              onClick={(e) => agregarEsfuerzo(e, row)}
                            >
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr075.svg"
                                className="svg-icon-6 ms-2"
                              />
                            </button>
                          </div>
                          <div className="d-flex flex-column fs-012 text-gray-600">
                            <NumericFormat
                              className="form-control w-100px ms-5"
                              thousandSeparator={false}
                              disabled={true}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }
                              }}
                              allowNegative={false}
                              decimalScale={0}
                              value={row.esfuerzo}
                              onBlur={(e) => {
                                const value = e.target.value;
                                if (!value) {
                                  e.target.value = '0';
                                }
                              }}
                              isAllowed={(values: any) => {
                                const { floatValue } = values;
                                const integerPart = Math.floor(floatValue);
                                return integerPart.toString().length <= 10;
                              }}
                              suffix=""
                              allowLeadingZeros={false}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                if (floatValue) {
                                  if (floatValue > 0) {
                                    volumeChange(floatValue, row.id);
                                  }
                                } else {
                                  volumeChange(floatValue || 0, row.id);
                                }
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.id !== 0 ? (
                          <NumericFormat
                            className="form-control w-200px"
                            thousandSeparator={true}
                            disabled={!isSupervisor}
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            allowLeadingZeros={false}
                            isAllowed={(values: any) => {
                              const { floatValue } = values;
                              const integerPart = Math.floor(floatValue);
                              return integerPart.toString().length <= 9;
                            }}
                            value={row.costo}
                            onValueChange={(values: any) => {
                              const { floatValue } = values;
                              costChange(floatValue, row.id);
                            }}
                          />
                        ) : (
                          <>{'0'}</>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center text-muted">
                  Sin recursos
                </td>
              </tr>
            )}

            {isSupervisor && (
              <>
                <tr>
                  <td colSpan={5}>
                    <div className="d-flex align-items-center">
                      <div
                        className="btn btn-light-primary btn-sm mb-10"
                        onClick={AddRow}
                      >
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr075.svg"
                          className="svg-icon-5"
                        />
                        Agregar Recurso
                      </div>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      <DetailTaskModal
        show={showDetailModal}
        handleClose={(refresh: boolean, recurso: any, horas) => {
          if (refresh) {
            volumeChange(horas, recurso.id);
            setRegister({});
            setShowDetailModal(false);
          } else {
            setRegister({});
            setShowDetailModal(false);
          }
        }}
        recurso={register}
        fecha_inicio={fecha_inicio}
        fecha_fin={fecha_fin}
      />
    </>
  );
};

export { RecursosList };
