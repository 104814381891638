import axios from 'axios';
import { AddressContactModel, NoteContactModel } from '../../pages/contacto/models/ContactoModel';
const API_URL = process.env.REACT_APP_API;

const fieldToInt = (field: any) => {
  let parse: any = field;
  if (field) {
    parse = parseInt(field);
  }
  return parse;
};
// export async function getCuentasSb() {
//     const response = await axios.get(`${API_URL}catalogos/cuentas?sort=nombre`);
//     const data = response.data.doc.data.rows;
//     const count = response.data.doc.data.count;
//     return {
//         data: data,
//         count: count
//     };

// }
export async function getSubCuentas(id_cuenta: number) {
  const response = await axios.get(
    `${API_URL}catalogos/subcuentas?cuenta_id=${id_cuenta}`
  );

  const data = response.data.doc.data.rows;
  const count = response.data.doc.data.count;
  return {
    data: data,
    count: count,
  };
}
export async function getContactos(
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let search: string = '';
  let cuenta: string = '';

  if (filters.nombre) {
    search = `&id=${filters.nombre}`;
  }

  if (filters.cuenta_id) {
    cuenta = `&cuenta_id=${filters.cuenta_id}`;
  }
  const response = await axios.get(
    `${API_URL}catalogos/contactos?sort=nombre${search}${cuenta}`
  );
  // }
  // const response = await axios.get(
  //   `${API_URL}catalogos/contactos?limit=${startIndex}&page=${endIndex}${search}${cuenta}`
  // );

  const data = response.data.doc.data.rows;
  const count = response.data.doc.data.count;
  return {
    data: data,
    count: count,
  };
}
export async function addContacto(body: any) {
  const {
    nombre,
    apellido_paterno,
    apellido_materno,
    email,
    telefono_movil,
    telefono_fijo,
    telefono,
    departamento_id,
    cuenta_id,
    sub_cuenta_id,
    puesto,
    extension,
    fecha_nacimiento,
  } = body;

  const data = {
    nombre: nombre,
    apellido_paterno: apellido_paterno,
    apellido_materno: apellido_materno,
    email: email,
    telefono_movil: telefono_movil,
    telefono_fijo: telefono_fijo,
    extension: extension,
    departamento_id:
      departamento_id === '' ? null : fieldToInt(departamento_id),
    cuenta_id: cuenta_id === '' || cuenta_id === 0 ? null : cuenta_id,
    sub_cuenta_id:
      sub_cuenta_id === '' || sub_cuenta_id === 0 ? null : sub_cuenta_id,
    puesto: puesto,
    fecha_nacimiento: fecha_nacimiento === '' ? null : fecha_nacimiento,
    telefono: telefono,
  };

  const response = await axios.post(`${API_URL}catalogos/contactos/add`, data);
  return response;
}

export async function addContactoFromCollaborator(body: any) {
  const {
    nombre,
    apellido_paterno,
    apellido_materno,
    curp,
    cuenta_id,
    sub_cuenta_id
  } = body;

  const data = {
    nombre: nombre,
    apellido_paterno: apellido_paterno,
    apellido_materno: apellido_materno,
    curp,
    cuenta_id,
    sub_cuenta_id
  };

  const response = await axios.post(`${API_URL}catalogos/contactos/colab/add`, data);
  return response;
}

export async function getContactoById(id_row: number) {
  const response = await axios.get(`${API_URL}catalogos/contactos/${id_row}`);
  const {
    id,
    nombre,
    apellido_paterno,
    apellido_materno,
    email,
    telefono,
    telefono_movil,
    telefono_fijo,
    departamento,
    cuenta,
    subCuenta,
    puesto,
    extension,
    fecha_nacimiento,
    lugar_nacimiento,
    genero,
    pais,
    curp
  } = response.data.doc.data;
  return {
    id: id,
    nombre: nombre,
    apellido_paterno: apellido_paterno,
    apellido_materno: apellido_materno,
    email: email,
    telefono: telefono,
    telefono_movil: telefono_movil,
    telefono_fijo: telefono_fijo,
    extension: extension,
    puesto: puesto,
    departamento: {
      id: departamento ? departamento?.id : 0,
      name: departamento ? departamento?.name : '',
      code: departamento ? departamento?.code : '',
    },
    cuenta: {
      id: cuenta ? cuenta.id : 0,
      nombre: cuenta ? cuenta.nombre : '',
    },
    subCuenta: {
      id: subCuenta ? subCuenta?.id : 0,
      nombre: subCuenta ? subCuenta?.nombre : '',
    },
    departamento_id: departamento?.id,
    cuenta_id: cuenta ? cuenta.id : 0,
    sub_cuenta_id: subCuenta ? subCuenta.id : 0,
    fecha_nacimiento: fecha_nacimiento,
    lugar_nacimiento,
    genero,
    pais,
    curp
  };
}
export async function updateContacto(body: any) {
  const {
    id,
    nombre,
    apellido_paterno,
    apellido_materno,
    email,
    telefono,
    telefono_movil,
    telefono_fijo,
    departamento_id,
    cuenta_id,
    sub_cuenta_id,
    puesto,
    extension,
    fecha_nacimiento,
  } = body;

  const data = {
    nombre: nombre,
    apellido_paterno: apellido_paterno,
    apellido_materno: apellido_materno,
    email: email,
    telefono: telefono,
    telefono_movil: telefono_movil,
    telefono_fijo: telefono_fijo,
    extension: extension,
    departamento_id:
      departamento_id === '' ? null : fieldToInt(departamento_id),
    // cuenta_id: cuenta_id,
    // sub_cuenta_id: sub_cuenta_id,
    puesto: puesto,
    fecha_nacimiento: fecha_nacimiento === '' ? null : fecha_nacimiento,
  };

  const response = await axios.put(`${API_URL}catalogos/contactos/${id}`, data);
  return response;
}
export async function addFirstContacto(body: any) {
  const {
    nombre,
    apellido_paterno,
    apellido_materno,
    email,
    telefono,
    cuenta_id,
    sub_cuenta_id,
    contacto_id
  } = body;

  const data = {
    nombre: nombre,
    apellido_paterno: apellido_paterno,
    apellido_materno: apellido_materno,
    email: email,
    telefono: telefono,
    contacto_id,
    cuenta_id: cuenta_id === 0 ? null : cuenta_id,
    sub_cuenta_id: sub_cuenta_id,
  };

  const response = await axios.post(`${API_URL}catalogos/cuentas/contacto/add/${cuenta_id}`, data);
  return response;
}
export async function removeContacto(id: number) {
  const response = await axios.delete(`${API_URL}catalogos/contactos/${id}`);
  return response;
}
export async function getFilterContactos(
  cuenta: number,
  subcuenta: number,
  location: number
) {
  let cuenta_id: string = '';
  let subcuenta_id: string = '';
  let parse: number = parseInt(subcuenta.toString());

  if (location == 1) {
    if (cuenta) {
      cuenta_id = `&cuenta_id=${cuenta}`;
    }
    if (parse) {
      subcuenta_id = `&sub_cuenta_id=${parse}`;
    }
  }

  const response = await axios.get(
    `${API_URL}catalogos/contactos?${cuenta_id}${subcuenta_id}`
  );

  const data = response.data.doc.data.rows;
  const count = response.data.doc.data.count;
  return {
    data: data,
    count: count,
  };
}

export async function getHistory(search: string, id: string) {
  const response = await axios.get(
    `${API_URL}catalogos/contactos/historial/${id}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function createHistoryMessage(message: string, id: string) {
  const response = await axios.post(
    `${API_URL}catalogos/contactos/historial/comentario`,
    {
      contacto_id: id,
      comentario: message,
    }
  );
  return response;
}

export async function updateModalContacto(body: any) {
  const { cuenta_id, contacto_id } = body;

  const data = {
    cuenta_id: cuenta_id,
    contacto_id
  };

  const response = await axios.post(
    `${API_URL}catalogos/cuentas/contacto/add/${cuenta_id}`,
    data
  );
  return response;
}
export async function addIdContactInAcount(body: any) {
  const { id, contactoPrincipal_id } = body;

  const data = {
    cuenta_id: id,
  };

  const response = await axios.put(
    `${API_URL}catalogos/contactos/${contactoPrincipal_id}`,
    data
  );
  return response;
}

export async function addContactoInAcc(body: any) {
  const { nombre, apellido_paterno, apellido_materno, email, telefono } = body;

  const data = {
    nombre: nombre,
    apellido_paterno: apellido_paterno,
    apellido_materno: apellido_materno,
    email: email,
    telefono: telefono,
  };

  const response = await axios.post(`${API_URL}catalogos/contactos/add`, data);
  return response;
}

export async function getContactosFilter(
  cuenta: number,
  subcuenta: number,
  location: number
) {
  let cuenta_id: string = '&cuenta_id=0';
  let subcuenta_id: string = '';
  let parse: number = parseInt(subcuenta.toString());

  if (location == 1) {
    if (cuenta) {
      cuenta_id = `&cuenta_id=${cuenta}`;
    }
    if (parse) {
      subcuenta_id = `&sub_cuenta_id=${parse}`;
    }
  }

  const response = await axios.get(
    `${API_URL}catalogos/contactos?${cuenta_id}${subcuenta_id}&isGrid=false`
  );

  const data = response.data.doc.data.rows;
  const count = response.data.doc.data.count;
  return {
    data: data,
    count: count,
  };
}

export async function getNoteByID (id: string) {
  const response = await axios.get(`${API_URL}catalogos/contactos/notas/${id}`);
  let note = response.data.doc.data;
  note = {
    ...note,
    contacto_id: note.oportunidad?.id | 0,
    created_at: note.created_at
      ? new Date(note.created_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
    updated_at: note.updated_at
      ? new Date(note.updated_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
  };
  return note;
};

export async function getNotesByContactId(id: string) {
  const response = await axios.get(
    `${API_URL}catalogos/contactos/notas/contact/${id}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function addNoteContact(note: NoteContactModel, contactId: number) {
  const response = await axios.post(`${API_URL}catalogos/contactos/notas/`, {
    nombre: note.nombre,
    descripcion: note.descripcion,
    contacto_id: Number(contactId),
  });
  
  return response;
}

export async function updateNoteContact(note: NoteContactModel, contacto_id: string) {
  const { id, nombre, descripcion } = note;
  const response = await axios.put(`${API_URL}catalogos/contactos/notas/${id}`, {
    nombre: nombre,
    descripcion: descripcion,
    contacto_id: Number(contacto_id)
  });
  return response;
}

export async function getAddressByContactId(id: string) {
    const response = await axios.get(
        `${API_URL}catalogos/contactos/direcciones/cto/${id}`
    );
    const data = response.data.doc.data;
    return data;
}

export async function insertAddress(
    address: AddressContactModel,
    contactoId: string
) {
    const response = await axios.post(
        `${API_URL}catalogos/contactos/direcciones/`,
        {
            ...address,
            contacto_id: contactoId,
        }
    );
    return response;
}

export async function updateAddress(
    address: AddressContactModel,
    contactoId: string
) {
    const response = await axios.put(
        `${API_URL}catalogos/contactos/direcciones/${address.id}`,
        {
            ...address,
            contacto_id: contactoId,
        }
    );
    return response;
}

export async function getDocumentsByContactId(contactId: number) {
    const response = await axios.get(
        `${API_URL}catalogos/contactos/documento/${contactId}`
    );
    const data = response.data.doc.data;
    return data;
}

export const uploadFileToContact = async (file: any, contactoId: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('contactoId', contactoId);

    const response = await axios.post(
        `${API_URL}catalogos/contactos/documento`,
        formData
    );
    return response;
};

export const removeFileOfContact = async (documentId: number) => {
    const response = await axios.delete(
        `${API_URL}catalogos/contactos/documento/${documentId}`
    );
    return response;
};