import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AddressSupplierModel, NotaProveedorModel, SupplierDocModel, SupplierModel } from '../models/SupplierModel';
import { agregarEditarProveedor, getAddressBySupplierId, getContactBySupplierId, getContactSupplier, getDocumentsBySupplierId, getNotesByProveedorId, insertAddressSupplier, insertNoteProveedor, obtenerCamposFiltroProveedores, obtenerDatosFiltrosProveedores, obtenerProveedores, obtenerProveedoresPorID, updateAddressSupplier } from '../../../service/supplier/Supplier';
import { ContactoModel } from '../../contacto/models/ContactoModel';
import { SimpleAlert } from '../../../../_metronic/helpers';

export const useObtenerProveedorPorID = (id: number) => {
  const initModel: SupplierModel = {
      id: 0,
      nombre: '',
      no_proveedor: '',
      descripcion: '',
      telefono_contacto: '',
      email: '',
      sitio_web: '',
      referido: {
        id: 0,
      },
      industria: {
        id: 0,
        name: '',
        code: ''
      },
      contacto: {
        id: 0,
        apellido_paterno:  '',
        apellido_materno:  '',
        telefono:  '',
        email:  '',
      }
  };

  const [proveedor, setProveedor] = useState<SupplierModel>(initModel);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      await obtenerProveedoresPorID(id)
        .then((res) => {
          if (res) {
            setProveedor(res);
            setLoadingRequest(false);
          }
        })
        .catch((error) => {
          history.push('/error/500');
        });
    };
    if (id > 0) {
      fetchPost();
    } else {
      setProveedor(initModel);
      setLoadingRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { proveedor, loadingRequest };
};


export const useGetContactSupplier = (
  realoadGrid: number,
) => {
  const [dataContactos, setDataContactos] = useState<any[]>([]); 
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getContactSupplier()
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (res) {
        let contacts = res.rows;
        const selectOptions = contacts.map((option: any) => ({
          value: option.id,
          label: option?.apellido_paterno
            ? `${option.nombre} ${option.apellido_paterno} ${option.apellido_materno}`
            : `${option.nombre}`,
        }));
        setDataContactos(selectOptions);
      }
    };

    fetchPost();
  }, [realoadGrid]);

  return { dataContactos };
};

export const useCrearEditarProveedor = () => {
  const history = useHistory();
  const [successRequest, setSuccessRequest] = useState<boolean>(false);
  const crearEditarProveedor = async (values: SupplierModel) => {
    setSuccessRequest(false);
    try {
      const res = await agregarEditarProveedor(values);
      setSuccessRequest(true);
      SimpleAlert(res.message, 3000, 'success');
      history.goBack();
    } catch (error: any) {
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setSuccessRequest(true);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { crearEditarProveedor, successRequest };
};

export const useObtenerProveedores = (
  realoadGrid: number,
  filters: any
) => {
  const [data, setData] = useState<SupplierModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await obtenerProveedores(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);

  return { data, loadingRequest };
};

export const useObtenerCamposFiltroProveedores = (isAdmin: any) => {
  const [listFields, setListFields] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    let isMounted = true;

    const fetchPost = async () => {
      setLoadingRequest(true);
      try {
        const res = await obtenerCamposFiltroProveedores();
        if (isMounted && res) {
          const selectOptions = res.map((option: any) => ({
            value: option.id,
            label: `${option.name}`,
            code: option.code,
          }));
          setListFields(selectOptions);
        }
      } catch (error) {
        history.push("/error/500");
      } finally {
        if (isMounted) {
          setLoadingRequest(false);
        }
      }
    };

    fetchPost();

    return () => {
      isMounted = false;
    };
  }, [history, isAdmin]);

  return {
    listFields,
    loadingRequest,
  };
};

export const useObtenerDatosFiltrosProveedores = (code: string) => {
  const [dataOptionSelected, setDataOptionSelected] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    let isMounted = true;

    const fetchPost = async () => {
      setLoadingRequest(true);
      try {
        const res = await obtenerDatosFiltrosProveedores(code);
        if (isMounted && res) {
          setDataOptionSelected(res);
        }
      } catch (error) {
        history.push("/error/500");
      } finally {
        if (isMounted) {
          setLoadingRequest(false);
        }
      }
    };

    if (code) {
      fetchPost();
    }

    return () => {
      isMounted = false;
    };
  }, [code, history]);

  return {
    dataOptionSelected,
    loadingRequest,
  };
};


export const useGetDocumentsBySupplierId = (
  proveedor_id: number,
  reload: number
) => {
  const [dataDocuments, setDataDocuments] = useState<SupplierDocModel[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingDocuments(false);
      const res = await getDocumentsBySupplierId(proveedor_id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataDocuments(res);
        setLoadingDocuments(true);
      }
    };
    if (proveedor_id > 0) {
      fetchPost();
    }
  }, [reload]);

  return { dataDocuments, loadingDocuments };
};


export const useGetNoteByProveedorId = (id: number, reaload: number) => {
  const [notes, setNotes] = useState<NotaProveedorModel[]>([]);
  const [loadingNote, setLoadingNote] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingNote(false);
    const fetchPost = async () => {
      try {
        const res = await getNotesByProveedorId(id);
        if (res) {
          setNotes(res);
          setLoadingNote(true);
        }
      } catch (error) {
        history.push("/error/500");
        setLoadingNote(false);
      }
    };
    if (id) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { notes, loadingNote };
};

export const useCreateNoteProveedor = () => {
  const [loadingCreateNote, setLoadingCreateNote] = useState<boolean>(false);
  const history = useHistory();
  const createNote = async (note: NotaProveedorModel, id: number) => {
    setLoadingCreateNote(false);
    try {
      const res = await insertNoteProveedor(note, id);
      SimpleAlert(res.message, 3000, 'success');
      setLoadingCreateNote(true);
    } catch (error: any) {
      setLoadingCreateNote(false);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
      } else {
        history.push('/error/500');
      }
    }
    
  };
  return { createNote, loadingCreateNote };
};


export const useGetAddressBySupplierId = (id: string, reaload: number) => {
  const [address, setAddress] = useState<AddressSupplierModel[]>([]);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingAddress(false);
    const fetchPost = async () => {
      try {
        const res = await getAddressBySupplierId(id);
        if (res) {
          setAddress(res);
          setLoadingAddress(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingAddress(false);
      }
    };
    if (Number(id) > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { address, loadingAddress };
};

export const useCreateAddressSupplier = () => {

    const [loadingInsertAddress, setLoadingInsertAddress] = useState<boolean>(false);
    const history = useHistory();
    const createAddress = async (address: AddressSupplierModel, id: string) => {
        setLoadingInsertAddress(false);
        let res;
      try {
        if(address.id > 0){
          res = await updateAddressSupplier(address, id)

        } else {
          res =await insertAddressSupplier(address, id);
        }
        SimpleAlert(res.data.message, 3000, 'success');
        setLoadingInsertAddress(true);
      } catch (error) {
        history.push('/error/500');
        setLoadingInsertAddress(false);
      }
    };
    return { createAddress, loadingInsertAddress };
};

export const useGetContactsBySupplierId = (id: string, reaload: number) => {
  const [contacts, setContacts] = useState<ContactoModel[]>([]);
  const [loadingContacts, setLoadingContacts] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingContacts(false);
    const fetchPost = async () => {
      try {
        const res = await getContactBySupplierId(id);
        if (res) {
          setContacts(res);
          setLoadingContacts(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingContacts(false);
      }
    };
    if (Number(id) > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { contacts, loadingContacts };
};