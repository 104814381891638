import React, { useState, useEffect } from 'react';
import {
  KTSVG,
  SimpleAlert,
  encryptId,
} from '../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import { Link } from 'react-router-dom';
import {
  dateFormatt,
  dateFormattYYYYMMDD,
} from '../../../../../_metronic/helpers/FormatDate';
import { NoteTaskModel } from '../models/NoteTaskModel';
import { NumericFormat } from 'react-number-format';
import { useObtenerTareasPorIdRecurso } from '../hooks/TareasHook';

type Props = {
  show: boolean;
  handleClose: (refresh: boolean, recurso: any, horas: number) => void;
  recurso: any;
  fecha_inicio: string;
  fecha_fin: string;
};

const DetailTaskModal: React.FC<Props> = ({
  show,
  handleClose,
  recurso,
  fecha_inicio,
  fecha_fin,
}) => {
  const [loading, setLoading] = useState(false);
  const [horas, setHoras] = useState<number>(0);
  const [horasError, setHorasError] = useState<string>('');
  const [horasDiarias, setHorasDiarias] = useState<number>(0);
  const [horasEntreFechas, setHorasEntreFechas] = useState<number>(0);
  const [esfuerzoTotal, setEsfuerzoTotal] = useState<number>(0);
  const [horasDisponibles, setHorasDisponibles] = useState<number>(0);

  const { tareas, cargandoTareas } = useObtenerTareasPorIdRecurso(
    recurso ? recurso.id : 0,
    fecha_inicio,
    fecha_fin
  );

  const calcularDiasTranscurridos = (fecha1: Date, fecha2: Date): number => {
    const diffTime: number = Math.abs(fecha2.getTime() - fecha1.getTime());
    const diffDays: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays === 0 ? 1 : diffDays + 1;
  };

  const cleanForm = (isSuccues: boolean) => {
    if (isSuccues) {
      if (!horas) {
        SimpleAlert(
          'Ingrese la cantidad de horas que desea asignar.',
          3000,
          'error'
        );
        return false;
      }
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        handleClose(isSuccues, recurso, horas);
        setHoras(0);
        setEsfuerzoTotal(0);
        setHorasDiarias(0);
        setHorasEntreFechas(0);
        setHorasDisponibles(0);
        setHorasError('');
      }, 1000);
    } else {
      handleClose(isSuccues, recurso, 0);
      setHoras(0);
      setEsfuerzoTotal(0);
      setHorasDiarias(0);
      setHorasEntreFechas(0);
      setHorasDisponibles(0);
      setHorasError('');
    }
  };

  useEffect(() => {
    let fechaUno = new Date(fecha_inicio);
    let fechaDos = new Date(fecha_fin);
    let diasTranscuridos = calcularDiasTranscurridos(fechaUno, fechaDos);

    if (tareas.length) {
      const horasLaborales = tareas[0].horasLaborales;
      const totalEsfuerzo = tareas.reduce(
        (acc, tarea) => acc + tarea.recursos[0].esfuerzo,
        0
      );
      let horasEntreFechas = diasTranscuridos * horasLaborales;
      let restaHoras = horasEntreFechas - totalEsfuerzo;
      setEsfuerzoTotal(totalEsfuerzo);
      setHorasDiarias(horasLaborales);
      setHorasEntreFechas(horasEntreFechas);
      setHorasDisponibles(restaHoras < 0 ? 0 : restaHoras);
    } else {
      const horasLaborales = 8;
      const totalEsfuerzo = 0;
      let horasEntreFechas = diasTranscuridos * horasLaborales;
      let restaHoras = horasEntreFechas - totalEsfuerzo;
      setEsfuerzoTotal(totalEsfuerzo);
      setHorasDiarias(horasLaborales);
      setHorasEntreFechas(horasEntreFechas);
      setHorasDisponibles(restaHoras < 0 ? 0 : restaHoras);
    }

    return () => {
      setHoras(0);
      setEsfuerzoTotal(0);
      setHorasDiarias(0);
      setHorasEntreFechas(0);
      setHorasDisponibles(0);
      setHorasError('');
    };
  }, [tareas]);

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered min-w-800px"
      show={show}
      onHide={() => {
        cleanForm(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="">Consultar horas disponibles del recurso</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              cleanForm(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body padding-top-00">
          <div className="px-4 py-3 mb-6">
            <div className="d-flex flex-stack">
              <span>
                <div className="w-30 text-gray-500">
                  <div className="w-100 d-flex mt-5">
                    <label className=" form-label w-auto">Recurso:</label>
                  </div>
                  <span>
                    {recurso &&
                      recurso.colaborador &&
                      recurso.colaborador.contacto && (
                        <>
                          {recurso.colaborador.contacto.nombre}{' '}
                          {recurso.colaborador.contacto.apellido_paterno}
                        </>
                      )}
                  </span>
                </div>
              </span>
              <span>
                <div className="w-30 text-gray-500">
                  <div className="w-100 d-flex mt-5">
                    <label className=" form-label w-auto">Fecha inicio:</label>
                  </div>
                  <span>{fecha_inicio}</span>
                </div>
              </span>
              <span>
                <div className="w-30 pe-5 text-gray-500">
                  <div className="w-100 d-flex mt-5">
                    <label className="form-label w-auto">Fecha final:</label>
                  </div>
                  <span>{fecha_fin}</span>
                </div>
              </span>
            </div>
          </div>
          <div
            className="pe-6 me-n6 mt-5 position-relative scroll-y max-h-200px"
            style={{ maxHeight: '450px' }}
          >
            {tareas.length > 0 ? (
              tareas.map((tarea, index) => (
                <div
                  className="border border-dashed border-gray-300 rounded px-7 py-3 mb-3"
                  key={index}
                >
                  <div className="d-flex flex-stack mt-3">
                    <div className="me-3 fs-5 text-gray-500">
                      <div className="w-100 d-flex">
                        <label className=" form-label w-auto">Tarea:</label>
                        <span className="ms-3 fw-bolder">{tarea.nombre}</span>
                      </div>{' '}
                    </div>
                    {/* <div className="me-3 fs-5 fw-bolder">{tarea.nombre}</div> */}
                    <span>
                      <div className="w-100 pe-5 text-gray-500">
                        <div className="w-100 d-flex">
                          <label className="form-label w-auto">
                            Prioridad:
                          </label>
                          <span className="ms-3">{tarea.prioridad.name}</span>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="d-flex flex-stack mt-5">
                    <span>
                      <div className="w-100 pe-5 text-gray-500">
                        <div className="w-100 d-flex">
                          <label className=" form-label w-auto">
                            Esfuerzo:
                          </label>
                        </div>
                        <span>
                          {tarea.recursos && tarea.recursos.length && (
                            <>{tarea.recursos[0].esfuerzo}</>
                          )}
                          {/* {note.created_at
                            ? dateFormatt(note.created_at)
                            : note.created_at} */}
                        </span>
                      </div>
                    </span>
                    <span>
                      <div className="w-100 pe-5 text-gray-500">
                        <div className="w-100 d-flex">
                          <label className=" form-label w-auto">
                            Fecha inicio:
                          </label>
                        </div>
                        <span>{dateFormattYYYYMMDD(tarea.fecha_inicio)}</span>
                      </div>
                    </span>
                    <span>
                      <div className="w-100 pe-5 text-gray-500">
                        <div className="w-100 d-flex">
                          <label className=" form-label w-auto">
                            Fecha final:
                          </label>
                        </div>
                        <span>{dateFormattYYYYMMDD(tarea.fecha_fin)}</span>
                      </div>
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center mt-5 fs-5 text-gray-700 min-h-50px">
                Sin tareas asignadas
              </div>
            )}
          </div>

          <div className="d-flex justify-content-end mt-5">
            <div className="w-300px">
              <div className="d-flex flex-stack mb-3">
                <div className="fw-semibold pe-10 text-gray-600 fs-7">
                  Horas laborales (diarias):
                </div>

                <div className="text-end fw-bold fs-6 text-gray-800">
                  {horasDiarias} h.
                </div>
              </div>
              <div className="d-flex flex-stack mb-3">
                <div className="fw-semibold pe-10 text-gray-600 fs-7">
                  Horas laborales (entre las fechas):
                </div>

                <div className="text-end fw-bold fs-6 text-gray-800">
                  {horasEntreFechas} h.
                </div>
              </div>

              <div className="d-flex flex-stack mb-3">
                <div className="fw-semibold pe-10 text-gray-600 fs-7">
                  Esfuerzo total (actual):
                </div>

                <div className="text-end fw-bold fs-6 text-gray-800">
                  {esfuerzoTotal} h.
                </div>
              </div>

              <div className="d-flex flex-stack mb-3">
                <div className="fw-semibold pe-10 text-gray-600 fs-7">
                  Horas disponibles:
                </div>

                <div className="text-end fw-bold fs-6 text-gray-800">
                  {horasDisponibles} h.
                </div>
              </div>

              <div className="d-flex flex-stack mb-3">
                <div className="fw-semibold pe-10 text-gray-600 fs-7">
                  Esfuerzo asignar:
                </div>

                <div className="text-end fw-bold fs-6 text-gray-800">
                  <NumericFormat
                    className="form-control form-control-sm w-100px"
                    thousandSeparator={false}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    allowNegative={false}
                    decimalScale={0}
                    value={horas}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (!value) {
                        e.target.value = '0';
                      }
                    }}
                    isAllowed={(values: any) => {
                      const { floatValue } = values;
                      const integerPart = Math.floor(floatValue);
                      return integerPart.toString().length <= 10;
                    }}
                    suffix=""
                    allowLeadingZeros={false}
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      if (floatValue) {
                        if (floatValue > 0) {
                          if (floatValue > horasDisponibles) {
                            setHorasError(
                              '**Las horas asignadas exceden las disponibles.'
                            );
                          }
                          setHoras(floatValue);
                        } else {
                          setHorasError('');
                        }
                      } else {
                        setHoras(floatValue || 0);
                        setHorasError('');
                      }
                    }}
                  />
                  {/* <input
                    className={'form-control form-control-sm w-100px'}
                    name="horas"
                    // value={Formulario.values.fecha_inicio}
                    onChange={(e) => {
                      // Formulario.setFieldValue(
                      //   'fecha_inicio',
                      //   e.target.value
                      // );
                    }}
                  ></input> */}
                </div>
              </div>
              <div className="text-end fs-7 text-danger h-20px">
                {horasError !== '' && <>{horasError}</>}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-10">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                cleanForm(false);
              }}
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                cleanForm(true);
              }}
              disabled={loading}
            >
              {!loading && <span className="indicator-label">Asignar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { DetailTaskModal };
