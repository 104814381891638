import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import {
  AddressSupplierModel,
  SupplierModel,
} from '../../models/SupplierModel';
import {
  useCrearEditarProveedor,
  useCreateAddressSupplier,
  useGetAddressBySupplierId,
  useGetContactsBySupplierId,
  useGetContactSupplier,
  useGetDocumentsBySupplierId,
  useGetNoteByProveedorId,
  useObtenerProveedorPorID,
} from '../../hooks/SuppliersHook';
import {
  cleanPhoneNumber,
  maskPhoneNumberNineteen,
} from '../../../../../_metronic/helpers';
import { styleSelect } from '../../../../../_metronic/helpers/SelectStyles';
import { useSelectGeneric } from '../../../catalogos/generico/hooks/genericoHook';
import { DocumentsList } from './sections/DocumentsList';
import { NoteList } from './sections/NoteList';
import { CreateNoteModal } from './_modals/CreateNoteModal';
import { CreateNoteDetailModal } from './_modals/CreateNoteDetailModal';
import { AddressSupplierList } from './sections/AddressList';
import { AddressModal } from '../../../../../_metronic/helpers/components/Address';
import { ContactSupplierList } from './sections/ContactList';
import { AddContactSupplierModal } from './_modals/AddContacto';

type Props = {
  proveedor_id: number;
  onRefreshHistory: () => void;
};

const formValidate = Yup.object().shape({
  nombre: Yup.string()
    .trim()
    .test(
      'not-only-spaces',
      'No puede contener solo espacios.',
      (value) => value?.trim() !== ''
    )
    .required('Nombre es requerido.'),
  email: Yup.string()
    .email('Formato de correo inválido.')
    .min(3, 'Mínimo 3 caracteres.')
    .required('Correo es requerido.'),
  no_proveedor: Yup.string()
    .trim()
    .test(
      'not-only-spaces',
      'No puede contener solo espacios.',
      (value) => value?.trim() !== ''
    )
    .required('No. de proveedor es requerido.'),
});

const SupplierForm: React.FC<Props> = ({ proveedor_id, onRefreshHistory }) => {
  const history = useHistory();

  const sectionTabs = useRef<HTMLDivElement>(null);
  const [tabs, setTabs] = useState<
    string | 'NOTE' | 'CONTACT' | 'ADDRESS' | 'DOC'
  >('NOTE');

  const { proveedor, loadingRequest } = useObtenerProveedorPorID(proveedor_id);
  const [loading, setLoading] = useState<boolean>(false);

  // SELECT DE CONTACTO
  const [reloadContact, setReloadContact] = useState<number>(
    Math.random() * 40
  );
  const { dataContactos } = useGetContactSupplier(reloadContact);

  // SELECT INDUSTRIAS
  const { catalog: industrias } = useSelectGeneric('IND');

  // DOCUMENTS
  const [reloadDocuments, setReloadDocuments] = useState(Math.random() * 40);
  const { dataDocuments, loadingDocuments } = useGetDocumentsBySupplierId(
    proveedor_id,
    reloadDocuments
  );

  // NOTAS
  const [reloadGridNotes, setReloadGridNotes] = useState<number>(
    Math.random() * 40
  );
  const { notes, loadingNote } = useGetNoteByProveedorId(
    proveedor_id,
    reloadGridNotes
  );
  const [activeNewNote, setActiveNewNote] = useState<boolean>(false);

  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [showNoteDetailModal, setShowNoteDetailModal] =
    useState<boolean>(false);

  // SECCION DIRECCIONES
  const [showModalAddress, setShowModalAddress] = useState<boolean>(false);
  const [activeNewAddress, setActiveNewAddress] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [addressInit, setAddessInit] = useState<AddressSupplierModel>({
    id: 0,
    nombre: '',
    pais: '',
    calle: '',
    numero_exterior: '',
    numero_interior: '',
    codigo_postal: '',
    colonia: '',
    ciudad: '',
    estado: '',
  });
  const [reloadGridAddress, setReloadGridAddress] = useState<number>(
    Math.random() * 40
  );
  const { address, loadingAddress } = useGetAddressBySupplierId(
    proveedor_id.toString(),
    reloadGridAddress
  );
  const { createAddress, loadingInsertAddress } = useCreateAddressSupplier();

  // SECCION DE CONTACTOS
  const [showModalContacts, setShowModalContacts] = useState<boolean>(false);

  const [reloadGridContacts, setReloadGridContacts] = useState<number>(
    Math.random() * 40
  );
  const { contacts, loadingContacts } = useGetContactsBySupplierId(
    proveedor_id.toString(),
    reloadGridContacts
  );

  // AGREGAR EDITAR PROVEEDOR
  const { crearEditarProveedor, successRequest } = useCrearEditarProveedor();

  const Formulario = useFormik<SupplierModel>({
    initialValues: proveedor,
    validationSchema: formValidate,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      await crearEditarProveedor(values);
    },
  });

  const handleSelectContact = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      Formulario.setFieldValue('contacto.id', value);
    } else {
      Formulario.setFieldValue('contacto.id', 0);
    }
  };
  const handleSelectRef = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      Formulario.setFieldValue('referido.id', value);
    } else {
      Formulario.setFieldValue('referido.id', 0);
    }
  };
  const showMaskPhoneNumber = (values: any) => {
    let value = values.target.value.toString();
    value = cleanPhoneNumber(value);
    const maskedPhoneNumber = maskPhoneNumberNineteen(value);
    Formulario.setFieldValue('telefono_contacto', maskedPhoneNumber);
  };

  // SECTIONS NOTES
  const openModal = (isOpen: boolean) => {
    setShowNoteModal(isOpen);
  };
  const openDetailModal = (isOpen: boolean) => {
    setShowNoteDetailModal(isOpen);
  };

  // SECCION DIRECCIONES
  const openAddressModal = (isOpen: boolean) => {
    setShowModalAddress(isOpen);
  };
  const openModalToEdit = (values: any) => {
    setAddessInit(values);
    setIsEditable(true);
    setShowModalAddress(true);
  };
  const onSaveAddress = (address: AddressSupplierModel) => {
    createAddress(address, proveedor_id.toString());
  };

  // SECCION DE CONTACTOS

  const openContactsModal = (isOpen: boolean) => {
    setShowModalContacts(isOpen);
  };

  useEffect(() => {
    if (loadingInsertAddress) {
      setReloadGridAddress(Math.random() * 40);
      onRefreshHistory();
      setShowModalAddress(false);
      setIsEditable(false);
    }
  }, [loadingInsertAddress]);

  useEffect(() => {
    if (successRequest) {
      setLoading(false);
    }
  }, [successRequest]);

  return (
    <>
      <form
        id="kt_supplier_form"
        className="form"
        action="#"
        noValidate
        onSubmit={Formulario.handleSubmit}
      >
        <div className="card mb-6 mb-xl-9">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 p-0">
                <div className="w-100">
                  <input
                    className="form-control mb-2 w-100 border-0"
                    style={{
                      width: '100%',
                      padding: '15px',
                      fontSize: '24px',
                      boxSizing: 'border-box',
                      marginBottom: '20px',
                    }}
                    maxLength={40}
                    data-kt-element="input"
                    placeholder="Nombre *"
                    {...Formulario.getFieldProps('nombre')}
                  ></input>
                  {Formulario.touched.nombre && Formulario.errors.nombre && (
                    <div className="fv-plugins-message-container text-danger ms-5 w-100 mb-3">
                      <span role="alert">{Formulario.errors.nombre}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto required">
                      No. de proveedor
                    </label>
                  </div>
                  <input
                    {...Formulario.getFieldProps('no_proveedor')}
                    className="form-control mb-3"
                    maxLength={20}
                    placeholder="Ejemplo: 1290001"
                  />
                  {Formulario.touched.no_proveedor &&
                    Formulario.errors.no_proveedor && (
                      <div className="fv-plugins-message-container text-danger w-100 mb-3">
                        <span role="alert">
                          {Formulario.errors.no_proveedor}
                        </span>
                      </div>
                    )}
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">
                      Contacto principal
                    </label>
                  </div>
                  <Select
                    options={dataContactos}
                    value={
                      Formulario.values.contacto
                        ? dataContactos.find(
                            (option) =>
                              option.value === Formulario.values.contacto.id
                          )
                        : null
                    }
                    styles={styleSelect}
                    placeholder={'Seleccione'}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{'No se encontraron registros'}</span>;
                    }}
                    onChange={handleSelectContact}
                    isClearable
                    backspaceRemovesValue
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">
                      Teléfono de contacto
                    </label>
                  </div>
                  <input
                    className="form-control mb-3"
                    placeholder="Ejemplo: 12 3456 7890 12345 6789"
                    value={maskPhoneNumberNineteen(
                      Formulario.values.telefono_contacto || ''
                    )}
                    prefix=""
                    onChange={showMaskPhoneNumber}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required form-label w-auto">
                      Correo electrónico
                    </label>
                  </div>
                  <input
                    {...Formulario.getFieldProps('email')}
                    className="form-control"
                    placeholder="Correo electrónico"
                    type="email"
                    name="email"
                  />
                  {Formulario.touched.email && Formulario.errors.email && (
                    <div className="fv-plugins-message-container text-danger w-100 mb-3 mt-2">
                      <span role="alert">{Formulario.errors.email}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Sitio Web</label>
                  </div>
                  <input
                    maxLength={250}
                    type="text"
                    {...Formulario.getFieldProps('sitio_web')}
                    className="form-control mb-3"
                    placeholder="Ejemplo: https://www.dominio.com"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Referido por</label>
                  </div>
                  <Select
                    options={dataContactos}
                    value={
                      Formulario.values.referido
                        ? dataContactos.find(
                            (option) =>
                              option.value === Formulario.values.referido.id
                          )
                        : null
                    }
                    styles={styleSelect}
                    placeholder={'Seleccione'}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{'No se encontraron registros'}</span>;
                    }}
                    onChange={handleSelectRef}
                    isClearable
                    backspaceRemovesValue
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 d-flex flex-column ">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className=" form-label w-auto">Industria</label>
                  </div>
                  <select
                    className="form-select mb-3"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    data-allow-clear="true"
                    {...Formulario.getFieldProps('industria.id')}
                  >
                    <option value={0}>Seleccione</option>
                    {industrias.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 ">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Descripción</label>
                  </div>
                  <textarea
                    className="form-control w-100"
                    data-kt-element="input"
                    maxLength={1000}
                    rows={5}
                    style={{ resize: 'none' }}
                    placeholder=""
                    {...Formulario.getFieldProps('descripcion')}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex justify-content-end mt-10">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  disabled={loading}
                >
                  {!loading && <span className="indicator-label">Guardar</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      {proveedor_id ? (
        <div className="row g-6 g-xl-9" ref={sectionTabs}>
          <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
            <div className="card card-flush p-5">
              <div className="row mb-5">
                <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                  <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'NOTE' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('NOTE');
                        }}
                      >
                        <span className="margin-left-05">Notas</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'CONTACT' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('CONTACT');
                        }}
                      >
                        <span className="margin-left-05">Contactos</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'ADDRESS' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('ADDRESS');
                        }}
                      >
                        <span className="margin-left-05">Direcciones</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'DOC' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('DOC');
                        }}
                      >
                        <span className="margin-left-05">Documentos</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                {tabs === 'NOTE' ? (
                  <>
                    <NoteList
                      data={notes}
                      onOpenModal={openModal}
                      active={activeNewNote}
                      onOpenDetailModal={openDetailModal}
                    ></NoteList>
                  </>
                ) : null}
                {tabs === 'CONTACT' ? (
                  <>
                    <ContactSupplierList
                      data={contacts}
                      onOpenModal={openContactsModal}
                    ></ContactSupplierList>
                  </>
                ) : null}
                {tabs === 'ADDRESS' ? (
                  <>
                    <AddressSupplierList
                      data={address || []}
                      onOpenModal={openAddressModal}
                      active={activeNewAddress}
                      onEditAddress={openModalToEdit}
                    ></AddressSupplierList>
                  </>
                ) : null}
                {tabs === 'DOC' ? (
                  <>
                    <DocumentsList
                      documents={dataDocuments}
                      onRefreshData={() => {
                        setReloadDocuments(Math.random() * 100);
                        onRefreshHistory();
                      }}
                      id={proveedor_id}
                      isEdit={false}
                    ></DocumentsList>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <CreateNoteModal
        show={showNoteModal}
        handleClose={(refresh) => {
          setShowNoteModal(!showNoteModal);
          if (refresh) {
            setReloadGridNotes(Math.random() * 40);
            onRefreshHistory();
          }
        }}
        proveedor_id={proveedor_id}
      ></CreateNoteModal>
      <CreateNoteDetailModal
        show={showNoteDetailModal}
        handleClose={(refresh) => {
          setShowNoteDetailModal(!showNoteDetailModal);
          onRefreshHistory();
        }}
        data={notes}
        id={proveedor_id}
      ></CreateNoteDetailModal>
      <AddressModal
        isEditable={isEditable}
        show={showModalAddress}
        handleSubmit={onSaveAddress}
        handleClose={() => {
          setShowModalAddress(false);
          setIsEditable(false);
        }}
        dataEditable={addressInit}
      ></AddressModal>
      <AddContactSupplierModal
        id_row={Number(proveedor_id)}
        title={'Nuevo contacto'}
        show={showModalContacts}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGridContacts(Math.random() * 40);
            onRefreshHistory();
          }
          setShowModalContacts(!showModalContacts);
        }}
      ></AddContactSupplierModal>
    </>
  );
};

export { SupplierForm };
