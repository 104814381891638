import React, { useState, useEffect } from 'react';
import { PatternFormat } from 'react-number-format';
import { KTSVG } from '../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import {
  addContacto,
  addContactoFromCollaborator,
} from '../../../../service/contacto/Contacto';
type Props = {
  show: boolean;
  cuenta: number | null;
  subcuenta: number | null;
  handleClose: (isNew: boolean, newContact: any) => void;
  nameAcc: string | null;
  nameSubAcc: string | null;
};

const ContactSchema = Yup.object().shape({
  nombre: Yup.string().required('Este campo es requerido.'),
  apellido_paterno: Yup.string().required('Este campo es requerido.'),
  apellido_materno: Yup.string().required('Este campo es requerido.'),
  curp: Yup.string()
    .notRequired()
    .test('isEmpty', 'Campo requerido.', function (value: any) {
      return value ? true : false;
    })
    .test(
      'isMinorLength',
      'El CURP debe tener 18 caracteres',
      function (value: any) {
        return value?.length < 18 ? false : true;
      }
    ),
});

const CreateContactModal: React.FC<Props> = ({
  show,
  handleClose,
  cuenta,
  subcuenta,
  nameAcc,
  nameSubAcc,
}) => {
  const [loading, setLoading] = useState(false);

  const formContact = useFormik({
    initialValues: {
      nombre: '',
      apellido_paterno: '',
      apellido_materno: '',
      curp: '',
      email: '',
      telefono: '',
      cuenta_id: cuenta,
      sub_cuenta_id: subcuenta ? subcuenta : null,
    },
    validationSchema: ContactSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        addContactoFromCollaborator(values)
          .then((res) => {
            const {
              data: {
                message,
                doc: {
                  data: { id, nombre },
                },
              },
            } = res;
            toast.success(message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
            setLoading(false);
            formContact.resetForm();
            handleClose(true, {
              value: id,
              label: `${nombre}`,
            });
          })
          .catch((err) => {
            let resMessageToast: string = '';
            const {
              data: { message, details = null },
            } = err.response;
            resMessageToast = `${message}`;
            setLoading(false);
            setSubmitting(false);
            toast.error(resMessageToast, {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          });

        // addContacto(values)
        //     .then((res) => {
        //         const {
        //             data: { message, doc: {
        //                 data: {
        //                     id, nombre, apellido_paterno, email, telefono
        //                 }
        //             } },
        //         } = res;
        //         toast.success(message, {
        //             position: 'top-right',
        //             autoClose: 3000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //             theme: 'light',
        //         });
        //         setLoading(false);
        //         formContact.resetForm();
        //         handleClose(true, {
        //             value: id,
        //             label: `${nombre} ${apellido_paterno} ${email ? `(${email})` : ''}`

        //         });
        //     })
        //     .catch((err) => {
        //         let resMessageToast: string = '';
        //         const {
        //             data: { message, details = null },
        //         } = err.response;
        //         resMessageToast = `${message}`;
        //         setLoading(false);
        //         setSubmitting(false);
        //         toast.error(resMessageToast, {
        //             position: 'top-right',
        //             autoClose: 4000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //             theme: 'light',
        //         });
        //     });
      }, 1000);
    },
  });

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-800px"
      show={show}
      onHide={() => {
        formContact.resetForm();
        handleClose(false, 0);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="text-muted">{'Datos del Contacto'}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formContact.resetForm();
              handleClose(false, 0);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 padding-top-00">
          {nameAcc ? (
            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6 mb-2">
              <KTSVG
                path="/media/icons/duotune/general/gen044.svg"
                className="svg-icon-2tx svg-icon-primary me-4"
              />
              <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                <div className="mb-3 mb-md-0 fw-semibold">
                  <h4 className="fs-6 text-gray-700 pe-7">
                    {`Estas por crear un contacto ligado a la cuenta `}
                    <span className="text-primary">{nameAcc}</span>
                    {nameSubAcc ? (
                      <>
                        {' '}
                        y a la subcuenta{' '}
                        <span className="text-primary">{nameSubAcc}</span>
                      </>
                    ) : null}
                  </h4>
                </div>
              </div>
            </div>
          ) : null}
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formContact.handleSubmit}
          >
            <div className="row wh-0100 mb-3">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
                <input
                  className="form-control mb-2 w-100 border-0 padd-left input-name-form"
                  data-kt-element="input"
                  placeholder="Nombre *"
                  {...formContact.getFieldProps('nombre')}
                ></input>
                {formContact.touched.nombre && formContact.errors.nombre && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">{formContact.errors.nombre}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid mb-3">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Apellido Paterno
                    </label>
                  </div>
                  <input
                    className="form-control"
                    data-kt-element="input"
                    {...formContact.getFieldProps('apellido_paterno')}
                  ></input>
                  {formContact.touched.apellido_paterno &&
                    formContact.errors.apellido_paterno && (
                      <div className="fv-plugins-message-container text-danger w-100">
                        <span role="alert">
                          {formContact.errors.apellido_paterno}
                        </span>
                      </div>
                    )}
                </div>
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      Apellido Materno
                    </label>
                  </div>
                  <input
                    className="form-control"
                    data-kt-element="input"
                    {...formContact.getFieldProps('apellido_materno')}
                  ></input>
                  {formContact.touched.apellido_materno &&
                    formContact.errors.apellido_materno && (
                      <div className="fv-plugins-message-container text-danger w-100">
                        <span role="alert">
                          {formContact.errors.apellido_materno}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      CURP
                    </label>
                  </div>
                  <input
                    {...formContact.getFieldProps('curp')}
                    className="form-control"
                    type="text"
                    name="curp"
                    placeholder="CURP"
                    style={{ textTransform: 'uppercase' }}
                    maxLength={18}
                    onChange={(event) => {
                      const value = event.target.value.replace(/\s/g, '');
                      formContact.setFieldValue('curp', value);
                    }}
                  />
                  {formContact.touched.curp && formContact.errors.curp && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">{formContact.errors.curp}</span>
                    </div>
                  )}
                </div>
                <div className="w-100 pe-5"></div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formContact.resetForm();
                  handleClose(false, 0);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateContactModal };
