import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SimpleAlert } from '../../../../../_metronic/helpers';
import { TareaBitacoraModel } from '../../../tasks/models/TaskModel';
import {
  crearEditarTareaService,
  getNotesByTaskId,
  insertNoteTask,
  obtenerPerfiles,
  obtenerRecursos,
  obtenerTareaPorId,
  obtenerTareasPorIdRecurso,
} from '../../../../service/bitacora/Bitacora';
import { SelectFormat } from '../../../../../_metronic/helpers/models/GenericModel';
import { NoteTaskModel } from '../models/NoteTaskModel';
import { HistoryModel } from '../../../contacto/models/HistoryModel';

export const useObtenerTareaPorId = (
  tareaId: number,
  etapaId: number,
  recargar: number
) => {
  let today = new Date().toISOString().split('T')[0];
  const nuevaTarea: TareaBitacoraModel = {
    id: 0,
    nombre: '',
    descripcion: '',
    avance: 0,
    esfuerzo: 0,
    fecha_inicio: today,
    fecha_fin: today,
    fecha_inicio_avance: '',
    fecha_fin_avance: '',
    esfuerzo_real_avance: 0,
    statusTarea: null,
    tipoTarea: null,
    unidadEsfuerzo: null,
    etapa: {
      id: etapaId,
      name: '',
      code: '',
    },
    moneda: {
      id: 0,
      name: '',
      code: '',
    },
    prioridad: null,
    oportunidad: null,
    user_asignado: null,
    etapa_id: 0,
    prioridad_id: 0,
    status_id: 0,
    user_asignado_id: 0,
    tipo_tarea_id: 0,
    unidad_esfuerzo_id: 0,
    moneda_id: 0,
    recursos: [],
    avances: [],
    avances_eliminados: [],
  };

  const [tarea, setTarea] = useState<TareaBitacoraModel>(nuevaTarea);
  const [cargandoTareas, setCargandoTareas] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    setCargandoTareas(true);
    if (tareaId) {
      const fetchPost = async () => {
        const res = await obtenerTareaPorId(tareaId)
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (res) {
          setTarea(res);
        }
      };
      fetchPost();
    } else {
      setTarea(nuevaTarea);
      setCargandoTareas(false);
    }
  }, [tareaId, recargar]);

  return { tarea, cargandoTareas };
};

export const useCrearEditarTarea = () => {
  const history = useHistory();
  const [successRequest, setSuccessRequest] = useState<boolean>(false);
  const crearEditarTarea = async (values: TareaBitacoraModel) => {
    setSuccessRequest(false);
    try {
      const res = await crearEditarTareaService(values);
      setSuccessRequest(true);
      SimpleAlert(res.message, 3000, 'success');
      history.push(`/bitacora/`);
    } catch (error: any) {
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setSuccessRequest(true);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { crearEditarTarea, successRequest };
};

export const useSelectPerfiles = () => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await obtenerPerfiles()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { catalog, loadingRequest };
};

export const useSelectRecursos = (id: number) => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await obtenerRecursos(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    if (id) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { catalog, loadingRequest };
};

export const useGetNoteByTaskId = (id: string, reaload: number) => {
  const [notes, setNotes] = useState<NoteTaskModel[]>([]);
  const [loadingNote, setLoadingNote] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingNote(false);
    const fetchPost = async () => {
      try {
        const res = await getNotesByTaskId(id);
        if (res) {
          setNotes(res);
          setLoadingNote(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingNote(false);
      }
    };
    if (Number(id) > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { notes, loadingNote };
};

export const useCreateNoteTask = () => {
  const [loadingCreateNote, setLoadingCreateNote] = useState<boolean>(false);
  const history = useHistory();
  const createNote = async (note: NoteTaskModel, id: number) => {
    setLoadingCreateNote(false);
    try {
      await insertNoteTask(note, id);
      setLoadingCreateNote(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingCreateNote(false);
    }
  };
  return { createNote, loadingCreateNote };
};

export const useObtenerTareasPorIdRecurso = (
  recurso_id: number,
  fecha_inicio: string,
  fecha_fin: string
) => {
  const [tareas, setTareas] = useState<any[]>([]);
  const [cargandoTareas, setCargandoTareas] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    setCargandoTareas(true);
    const fetchPost = async () => {
      const res = await obtenerTareasPorIdRecurso(
        recurso_id,
        fecha_inicio,
        fecha_fin
      )
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setTareas(res);
      }
    };

    if (recurso_id && fecha_inicio && fecha_fin) {
      fetchPost();
    }
  }, [recurso_id, fecha_inicio, fecha_fin]);

  return { tareas, cargandoTareas };
};
