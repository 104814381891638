import React, { useState, useEffect } from 'react';
import { previewQuotationFile } from '../../helpers/UploadFile';
import axios from 'axios';
import { downloadQuotationFile } from '../../helpers/UploadFile';
import { useGetOne } from '../../hooks/QuotationHook';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { encryptId } from '../../../../../_metronic/helpers';

type Props = {
  id: number;
};

const View: React.FC<Props> = ({ id }) => {
  const history = useHistory();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { initValues } = useGetOne(Number(id));
  const { nombre, version } = initValues;

  useEffect(() => {
    const fetchPdf = async () => {
      /* try {
        // Reemplaza 'URL_DE_TU_API' con la URL real de tu API
        const response = await axios.get(`${process.env.REACT_APP_API}cotizaciones/pdf/view/${id}`, {
          responseType: 'blob' // Importante para tratar la respuesta como un Blob
        });
        
        // Crear un URL para el Blob recibido
        const url = URL.createObjectURL(response.data);
        setPdfUrl(url);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      } */
      const url = await previewQuotationFile(id);
      const pdf = URL.createObjectURL(url);

      setPdfUrl(pdf);
    };

    fetchPdf();

    // Limpia el objeto URL cuando el componente se desmonte
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [id]);

  return (
    <div>
      {pdfUrl ? (
        <>
          <div className="card mb-6 mb-xl-9">
            <div className="card-body">
              <div className="row">
                <embed
                  src={`${pdfUrl}#toolbar=0`}
                  width="100%"
                  height="600px"
                  title="PDF Viewer"
                />
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="d-flex justify-content-end">
                    <button
                      type="reset"
                      className="btn btn-light me-3"
                      data-kt-users-modal-action="cancel"
                      onClick={() => {
                        history.push(
                          `/cotizaciones/edit/${encryptId(id.toString())}`
                        );
                      }}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      onClick={(event) => {
                        setLoading(true);
                        downloadQuotationFile(id, nombre, event)
                          .then(() => {
                            setLoading(false);
                            toast.success(
                              `Descarga de la cotización ${version} exitosa`,
                              {
                                position: 'top-right',
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: 'light',
                              }
                            );
                            history.push(
                              `/cotizaciones/edit/${encryptId(id.toString())}`
                            );
                          })
                          .catch((err) => {
                            let resMessageToast: string = '';
                            const {
                              data: { message, details = null },
                            } = err.response;
                            resMessageToast = `${message}`;
                            setLoading(false);
                            toast.error(resMessageToast, {
                              position: 'top-right',
                              autoClose: 4000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: 'light',
                            });
                          });
                      }}
                      className="btn btn-primary me-7"
                      data-kt-users-modal-action="submit"
                      disabled={loading}
                    >
                      {!loading && (
                        <span className="indicator-label">Descargar</span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <span className="indicator-progress" style={{ display: 'block' }}>
          Espere por favor...
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      )}
    </div>
  );
};

export { View };
