import React from 'react';
import { Link } from 'react-router-dom';
import { TableList } from '../../../../_metronic/helpers/components/TableList';
import { EmptyResult } from '../../../../_metronic/helpers/components/EmptyResult';
import {
  encryptId,
  maskPhoneNumberNineteen,
} from '../../../../_metronic/helpers';
import { SupplierModel } from '../models/SupplierModel';

type Props = {
  data: Array<SupplierModel>;
  loadingGrid: boolean;
};

const ListGrid: React.FC<Props> = ({ data, loadingGrid }) => {
  const columns: any = [
    {
      id: 'no_proveedor',
      name: <div className="fw-bolder text-muted me-5">No. Proveedor</div>,
      selector: (row: any) => row.no_proveedor || 'No registrado',
      cell: (row: any) => (
        <div className="text-gray-700">
          {row.no_proveedor || 'No registrado'}
        </div>
      ),
      sortable: true,
    },
    {
      id: 'nombre',
      name: (
        <div className="fw-bolder text-muted me-5">Nombre de proveedor</div>
      ),
      selector: (row: any) => row.nombre,
      cell: (row: any) => (
        <Link
          to={`/cotizaciones/proveedores/edit/${encryptId(row.id.toString())}`}
        >
          {row.nombre}
        </Link>
      ),
      sortable: true,
    },
    {
      id: 'industria',
      name: <div className="fw-bolder text-muted me-5">Industria</div>,
      selector: (row: any) =>
        row.industria ? row.industria.name : 'No registrado',
      cell: (row: any) => (
        <div className="text-gray-700">
          {row.industria ? row.industria.name : 'No registrado'}
        </div>
      ),
      sortable: true,
    },
    {
      id: 'telefono_contacto',
      name: (
        <div className="fw-bolder text-muted me-5">Teléfono de contacto</div>
      ),
      selector: (row: any) =>
        row.telefono_contacto
          ? maskPhoneNumberNineteen(row.telefono_contacto)
          : 'No registrado',
      cell: (row: any) => (
        <div className="text-gray-700">
          {row.telefono_contacto
            ? maskPhoneNumberNineteen(row.telefono_contacto)
            : 'No registrado'}
        </div>
      ),
      sortable: true,
    },
    {
      id: 'email',
      name: <div className="fw-bolder text-muted me-5">Correo electrónico</div>,
      selector: (row: any) => (row.email ? row.email : 'No registrado'),
      cell: (row: any) => (
        <div className="text-gray-700">
          {row.email ? row.email : 'No registrado'}
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <>
      <div className={`row gx-5 gx-xl-10`}>
        <div className="col-xl-12">
          <div className={`card mb-0 mb-xxl-8 h-100`}>
            {!loadingGrid ? (
              <TableList data={data} columns={columns}></TableList>
            ) : (
              <EmptyResult title="" loading={loadingGrid} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
