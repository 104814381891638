import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG, userHasRole } from '../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelectOpportunity } from '../hooks/QuotationHook';
import Select from 'react-select';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { styleSelect } from '../../../../_metronic/helpers/SelectStyles';
import { OportunityModel } from '../models/QuotationModel';
import { NumericFormat } from 'react-number-format';
import { useGetAllVendedoresComerciales } from '../../oportunidades/hooks/OportunidadHook';

type Props = {
  cotizacion: any;
  show: boolean;
  precioVenta: number;
  handleClose: (updateData: boolean, values: any) => void;
  sellerData: any;
};

const schemeOpportunity = Yup.object().shape({
  existente: Yup.boolean().required('La propiedad existente es requerida.'),
  id: Yup.number().when('existente', {
    is: true,
    then: Yup.number()
      .min(1, 'Oportunidad es requerido')
      .required('Oportunidad es requerido'),
    otherwise: Yup.number().notRequired(),
  }),
  nombre: Yup.string().when('existente', {
    is: false,
    then: Yup.string().required('Nombre es requerido.'),
    otherwise: Yup.string().notRequired(),
  }),
  fecha_aprobacion_estimada: Yup.date().when('existente', {
    is: false,
    then: Yup.date().required('Fecha estimada es requerido'),
    otherwise: Yup.date().notRequired(),
  }),
  vendedor_comercial_id: Yup.number().when('existente', {
    is: false,
    then: Yup.number()
      .min(1, 'Vendedor es requerido.')
      .required('Vendedor es requerido.'),
    otherwise: Yup.number().notRequired(),
  }),
  cantidad_estimada: Yup.number().when('existente', {
    is: false,
    then: Yup.number()
      .min(1, 'Cantidad estimada debe ser mayor a 0.')
      .required('Cantidad estimada es requerido.'),
    otherwise: Yup.number().notRequired(),
  }),
  probabilidad: Yup.number().when('existente', {
    is: false,
    then: Yup.number()
      .min(1, 'Probabilidad debe ser mayor a 0.')
      .required('Probabilidad es requerido.'),
    otherwise: Yup.number().notRequired(),
  }),
});
let currentDate = new Date();
currentDate.setHours(0, 0, 0, 0);
let minDate = currentDate.toISOString().split('T')[0];

const futureDate = new Date(currentDate);
futureDate.setDate(currentDate.getDate() + 14);
let sumDate = futureDate.toISOString().split('T')[0];

const CreateOpportunityModal: React.FC<Props> = ({
  cotizacion,
  show,
  precioVenta,
  handleClose,
  sellerData,
}) => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles } = user;
  const isValid = userHasRole(['CMA', 'DIR'], roles);

  let opportunity: OportunityModel = {
    id: 0,
    existente: false,
    probabilidad: 60,
    fecha_aprobacion_estimada: sumDate,
    cantidad_estimada: precioVenta,
    vendedor_comercial_id: cotizacion.vendedor_asignado_id,
    nombre: cotizacion.nombre,
  };

  const [selectOpportunity, setSelectOpportunity] = useState<any | null>(null);
  const [selectSeller, setSelectSeller] = useState<any | null>(null);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const { catalog: opportunitys, loadingRequest } =
    useSelectOpportunity(reloadGrid);
  const [loading, setLoading] = useState(false);
  //   const { sellerData } = useGetAllVendedoresComerciales();

  const formOpportunity = useFormik({
    initialValues: opportunity,
    validationSchema: schemeOpportunity,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      cotizacion.relacionar_oportunidad = values;
      handleClose(true, cotizacion);
    },
  });

  const [selectedOption, setSelectedOption] = useState<string>('create');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = event.target.value;
    if (type) {
      //   if (type === 'create') {
      //     setSelectOpportunity(null);
      //   } else {
      //     setSelectSeller(null);
      //   }
      //   formOpportunity.resetForm();
      setSelectedOption(type);
      formOpportunity.setFieldValue(
        'existente',
        type === 'create' ? false : true
      );
    }
  };

  const changeOpportunity = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectOpportunity(selectedOption);
      formOpportunity.setFieldValue('id', value);
    } else {
      setSelectOpportunity(null);
      formOpportunity.setFieldValue('id', 0);
    }
  };

  const cleanForm = () => {
    formOpportunity.resetForm();
    setSelectOpportunity(null);
    setSelectedOption('create');
    handleClose(false, {});
  };

  useEffect(() => {
    const { vendedor_asignado_id } = cotizacion;
    if (vendedor_asignado_id > 0) {
      if (sellerData) {
        const sellerFilter = sellerData.find(
          (seller: any) => seller.value === vendedor_asignado_id
        );
        setSelectSeller(sellerFilter);
      }
    }
  }, [cotizacion]);

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-900px"
      show={show}
      onHide={cleanForm}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="">
            ¿Deseas ligar la cotización a una oportunidad existente o crear una
            nueva oportunidad?
          </h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={cleanForm}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body mx-5 padding-top-00">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formOpportunity.handleSubmit}
          >
            <div className="min-h-500px">
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                  <div className="mb-7 mt-10">
                    <div className="justify-content-center ">
                      <div className="d-flex fv-row">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            id="create"
                            value="create"
                            checked={selectedOption === 'create'}
                            onChange={handleChange}
                          />
                          <label className="form-check-label" htmlFor="create">
                            <div className="fw-bolder text-gray-800">
                              Crear nueva oportunidad
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="my-5"></div>
                      <div className="d-flex fv-row">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            id="relate"
                            value="relate"
                            checked={selectedOption === 'relate'}
                            onChange={handleChange}
                          />
                          <label className="form-check-label" htmlFor="relate">
                            <div className="fw-bolder text-gray-800">
                              Ligar oportunidad existente
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4"></div>
              </div>
              <div
                className="row wh-0100 mb-3"
                style={{
                  display: selectedOption === 'create' ? 'block' : 'none',
                }}
              >
                <div className="separator my-5"></div>
                <div className="row">
                  <div className="col-12">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="form-label w-auto required">
                          Nombre de la oportunidad
                        </label>
                      </div>
                      <input
                        className="form-control"
                        placeholder="Nombre de la oportunidad"
                        {...formOpportunity.getFieldProps('nombre')}
                      ></input>
                      {formOpportunity.touched.nombre &&
                        formOpportunity.errors.nombre && (
                          <div className="fv-plugins-message-container text-danger w-100 mt-2">
                            <span role="alert">
                              {formOpportunity.errors.nombre}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-6">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="form-label w-auto required">
                          Probabilidad de éxito
                        </label>
                      </div>
                      <NumericFormat
                        className="form-control fs-7"
                        thousandSeparator={false}
                        allowNegative={false}
                        value={formOpportunity.values.probabilidad}
                        suffix="%"
                        allowLeadingZeros={false}
                        isAllowed={({ value }) => {
                          if (parseInt(value) < 0 || parseInt(value) > 100) {
                            return false;
                          }
                          const integerValue = parseInt(value);
                          if (integerValue < 10 && value.length > 1) {
                            return false;
                          }
                          if (integerValue < 100 && value.length > 2) {
                            return false;
                          }
                          return true;
                        }}
                        onValueChange={(values) => {
                          const { floatValue } = values;
                          formOpportunity.setFieldValue(
                            'probabilidad',
                            floatValue ? floatValue : 0
                          );
                        }}
                      />
                      {formOpportunity.touched.probabilidad &&
                        formOpportunity.errors.probabilidad && (
                          <div className="fv-plugins-message-container text-danger w-100 mt-2">
                            <span role="alert">
                              {formOpportunity.errors.probabilidad}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="form-label w-auto required">
                          Fecha estimada de cierre
                        </label>
                      </div>
                      <input
                        className="form-control fs-7"
                        data-kt-element="input"
                        type="date"
                        min={minDate}
                        {...formOpportunity.getFieldProps(
                          'fecha_aprobacion_estimada'
                        )}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                      {formOpportunity.touched.fecha_aprobacion_estimada &&
                        formOpportunity.errors.fecha_aprobacion_estimada && (
                          <div className="fv-plugins-message-container text-danger w-100 mt-2">
                            <span role="alert">
                              {formOpportunity.errors.fecha_aprobacion_estimada}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-6">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="form-label w-auto required">
                          Cantidad estimada
                        </label>
                      </div>
                      <NumericFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        value={formOpportunity.values.cantidad_estimada}
                        onValueChange={(values: any) => {
                          const { floatValue } = values;
                          formOpportunity.setFieldValue(
                            'cantidad_estimada',
                            floatValue ? floatValue : 0
                          );
                        }}
                      />

                      {formOpportunity.touched.cantidad_estimada &&
                        formOpportunity.errors.cantidad_estimada && (
                          <div className="fv-plugins-message-container text-danger w-100 mt-2">
                            <span role="alert">
                              {formOpportunity.errors.cantidad_estimada}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="form-label w-auto required">
                          Vendedor comercial
                        </label>
                      </div>
                      <Select
                        options={sellerData}
                        // isDisabled={isValid}
                        styles={{
                          placeholder: (provided: any, state: any) => ({
                            ...provided,
                            color: '#181c32',
                            textAlign: 'center',
                          }),
                          indicatorSeparator: (provided: any, state: any) => ({
                            ...provided,
                            display: 'none',
                          }),
                          dropdownIndicator: (provided: any, state: any) => ({
                            ...provided,
                            color: '#9096EC',
                            display: 'none',
                          }),
                          control: (provided: any, state: any) => ({
                            ...provided,
                            border: 'none',
                            borderRadius: '6px',
                            padding: '3px',
                            fontSize: '1rem',
                            boxShadow: 'none',
                            backgroundColor: state.isDisabled
                              ? '#EFF2F5'
                              : 'white',
                          }),
                          singleValue: (provided: any, state: any) => ({
                            ...provided,
                            color: state.isDisabled ? '#181c32' : 'inherit',
                            fontWeight: 500,
                          }),
                          option: (provided: any, state: any) => ({
                            ...provided,
                            fontSize: '1.0rem',
                            textAlign: 'center',
                          }),
                        }}
                        formatOptionLabel={(option) => (
                          <div
                            className="d-flex"
                            style={{ alignItems: 'center', width: '100%' }}
                          >
                            <div className="symbol symbol-30px">
                              <span
                                className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold"
                                title={option.label}
                              >
                                {option?.nombre[0]}
                                {option?.apellidoP[0]}
                              </span>
                            </div>
                            <span className="ms-3 ">
                              {option.nombre} {option.apellidoP}
                            </span>
                          </div>
                        )}
                        placeholder={
                          <span className="symbol symbol-30px cursor-pointer">
                            <div className="symbol-label">
                              <KTSVG
                                path="/media/icons/duotune/communication/com006.svg"
                                className="svg-icon-1"
                              />
                            </div>
                          </span>
                        }
                        isSearchable={false}
                        noOptionsMessage={() => {
                          return <span>{'No se encontraron registros'}</span>;
                        }}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            const { value } = selectedOption;
                            formOpportunity.setFieldValue(
                              'vendedor_comercial_id',
                              value
                            );
                            setSelectSeller(selectedOption);
                          } else {
                            formOpportunity.setFieldValue(
                              'vendedor_comercial_id',
                              0
                            );
                            setSelectSeller(selectedOption);
                          }
                        }}
                        value={selectSeller}
                        isLoading={loadingRequest}
                        isClearable
                        backspaceRemovesValue
                      />

                      {formOpportunity.touched.vendedor_comercial_id &&
                        formOpportunity.errors.vendedor_comercial_id && (
                          <div className="fv-plugins-message-container text-danger w-100 mt-2">
                            <span role="alert">
                              {formOpportunity.errors.vendedor_comercial_id}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row wh-0100 mb-3"
                style={{
                  display: selectedOption === 'relate' ? 'block' : 'none',
                }}
              >
                <div className="separator my-5"></div>
                <div className="col-12">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required form-label w-auto">
                        Oportunidad
                      </label>
                    </div>
                    <Select
                      value={selectOpportunity}
                      options={opportunitys}
                      styles={styleSelect}
                      placeholder={'Seleccione'}
                      isSearchable={true}
                      noOptionsMessage={() => {
                        return <span>{'No se encontraron registros'}</span>;
                      }}
                      onChange={changeOpportunity}
                      isLoading={loadingRequest}
                      isClearable
                      backspaceRemovesValue
                    />
                    {formOpportunity.touched.id && formOpportunity.errors.id && (
                      <div className="fv-plugins-message-container text-danger w-100 mt-2">
                        <span role="alert">{formOpportunity.errors.id}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={cleanForm}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary min-w-100px"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">
                    {selectedOption === 'create' ? 'Guardar' : 'Ligar'}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateOpportunityModal };
