import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import Select from 'react-select';
import { useFormik } from 'formik';
import { KTSVG, userHasRole } from '../../../../../../_metronic/helpers';
import { styleSelect } from '../../../../../../_metronic/helpers/SelectStyles';
import { ProyectoShortModel } from '../../models/ProyectoModel';
import { useSelectOpportunityProject } from '../../hooks/ProyectosHook';

type Props = {
  show: boolean;
  handleClose: (updateData: boolean, values: any) => void;
};

const schemeOpportunity = Yup.object().shape({
  oportunidad_id: Yup.number()
    .min(1, 'Oportunidad es requerido')
    .required('Oportunidad es requerido'),
});

const CreateProjectModal: React.FC<Props> = ({ show, handleClose }) => {
  let proyecto: ProyectoShortModel = {
    oportunidad_id: 0,
    nombre: '',
    unidad_negocio: {
      id: 0,
      code: '',
      name: '',
    },
  };

  const [selectOpportunity, setSelectOpportunity] = useState<any | null>(null);
  const [reloadOpo, setReloadOpo] = useState(Math.random() * 40);
  const { catalog: opportunitys, loadingRequest } =
    useSelectOpportunityProject(reloadOpo);
  const [loading, setLoading] = useState(false);

  const formProject = useFormik({
    initialValues: proyecto,
    validationSchema: schemeOpportunity,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        handleClose(true, values);
      }, 2000);
    },
  });

  const changeOpportunity = (selectedOption: any) => {
    if (selectedOption) {
      const { value, label, data } = selectedOption;
      const { unidad } = data;
      let unidad_negocio = {
        id: 0,
        code: '',
        name: '',
      };
      if (unidad) {
        unidad_negocio = unidad;
      }
      setSelectOpportunity(selectedOption);
      formProject.setFieldValue('oportunidad_id', value);
      formProject.setFieldValue('nombre', label);
      formProject.setFieldValue('unidad_negocio', unidad_negocio);
    } else {
      setSelectOpportunity(null);
      formProject.setFieldValue('oportunidad_id', 0);
      formProject.setFieldValue('nombre', '');
      formProject.setFieldValue('unidad_negocio', {
        id: 0,
        code: '',
        name: '',
      });
    }
  };

  const cleanForm = () => {
    formProject.resetForm();
    setSelectOpportunity(null);
    handleClose(false, {});
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog-centered mw-600px"
      show={show}
      onHide={cleanForm}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="">Asociar proyecto</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={cleanForm}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body mx-5 padding-top-00">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formProject.handleSubmit}
          >
            <div className="min-h-300px">
              <div className="row pt-10 pb-10">
                <h3>
                  ¿Deseas asociar el proyecto a una oportunidad existente?
                </h3>
              </div>
              <div className="row wh-0100 mb-3">
                <div className="col-12">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required form-label w-auto">
                        Oportunidad
                      </label>
                    </div>
                    <Select
                      value={selectOpportunity}
                      options={opportunitys}
                      styles={styleSelect}
                      placeholder={'Seleccione'}
                      isSearchable={true}
                      noOptionsMessage={() => {
                        return <span>{'No se encontraron registros'}</span>;
                      }}
                      onChange={changeOpportunity}
                      isLoading={loadingRequest}
                      isClearable
                      backspaceRemovesValue
                    />
                    {formProject.touched.oportunidad_id &&
                      formProject.errors.oportunidad_id && (
                        <div className="fv-plugins-message-container text-danger w-100 mt-2">
                          <span role="alert">
                            {formProject.errors.oportunidad_id}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={cleanForm}
              >
                No, prefiero no asociarlo.
              </button>
              <button
                type="submit"
                className="btn btn-primary min-w-100px"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">
                    Sí, asociar el proyecto.
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateProjectModal };
