import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CategoriaProyectos, DocumentoProyectoModel, NotaProyectoModel, ProyectoModel } from '../models/ProyectoModel';
import { agregarEditarProyecto, createHistoryMessageProject, getDocumentsByProjectId, getHistorialProyectos, getNotesByProjectId, getOpportunitysNewProject, insertNoteProject, obtenerCamposFiltroProyectos, obtenerDatosFiltrosProyectos, obtenerProyectos, obtenerProyectosPorID } from '../../../../service/proyectos/Proyectos';
import { addValueCategory, getCategoriesGrid } from '../../../../service/catalog/CategoriaProyectos';
import { addValueSubCategory, getSubCategoriesByIDCategory } from '../../../../service/catalog/SubCategoriaProyectos';
import { SimpleAlert } from '../../../../../_metronic/helpers';
import { getCatalog } from '../../../../service/catalog/Catalogs';
import { HistoryModel } from '../../../../../_metronic/helpers/models/HistoryModel';
import { SelectFormat } from '../../../../../_metronic/helpers/models/GenericModel';

type Option = {
  id: number | 0;
  value: number | 0;
  label: string | null | '';
};

export const useObtenerProyectos = (
  realoadGrid: number,
  filters: any
) => {
  const [data, setData] = useState<ProyectoModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await obtenerProyectos(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);

  return { data, loadingRequest };
};

export const useObtenerProyectosPorID = (id: number) => {
  let today = new Date().toISOString().split('T')[0];
  const initModel: ProyectoModel = {
    id: 0,
    nombre: '',
    descripcion: '',
    imagen: '',
    unidad_negocio: {
      id: 0,
      name: '',
      code: ''
    },
    categoria: {
      id: 0,
      codigo: '',
      nombre: '',
      orden: ''
    },
    subcategoria: {
      id: 0,
      codigo: '',
      nombre: '',
      orden: ''
    },
    status:{
      id: 0,
      name: 'No iniciado',
      code: 'NO_INIT'
    },
    tipo: {
      id: 0,
      name: '',
      code: ''
    },
    oportunidad_id:0,
    fecha_inicio: today,
    fecha_final: today,
    secciones: [],
    secciones_eliminadas: [],
    secciones_actuales: [],
    activo_oportunidad: false
  };

  const [proyecto, setProyecto] = useState<any>(initModel);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      await obtenerProyectosPorID(id)
        .then((res) => {
          if (res) {
            setProyecto(res);
            setLoadingRequest(false);
          }
        })
        .catch((error) => {
          history.push('/error/500');
        });
    };
    if (id > 0) {
      fetchPost();
    } else {
      setProyecto(initModel);
      setLoadingRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { proyecto, loadingRequest };
};

export const useObtenerCategoriasProyectos = (
  reloadCategoryList: number
) => {
  const [loadingCatalog, setLoadingCatalog] = useState<boolean>(true);
  const [categorias, setCategorias] = useState<Option[]>([]);
  const history = useHistory();

  useEffect(() => {
    const fetchGet = async () => {
      const response = await getCategoriesGrid()
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (response) {
        setLoadingCatalog(false);
        const selectOptions = response.rows.map(
          (option: CategoriaProyectos) => ({
            id: Number(option.id),
            value: option.id,
            label: `${option.nombre} `,
          })
        );
        selectOptions.unshift({
          id: 0,
          value: '0',
          label: `Agregar categoría`,
          data: {},
        });
        setCategorias(selectOptions);
      }
    };

    fetchGet();
  }, [reloadCategoryList]);

  return {
    loadingCatalog,
    categorias,
  };
};

export const useObtenerSubcategorias = (
  categoryId: number,
  reloadSubcategoryList: number
) => {
  const [loadingSubCategorias, setLoadingSubCategorias] =
    useState<boolean>(true);
  const [subcategorias, setSubcategorias] = useState<Option[]>([]);
  const history = useHistory();

  useEffect(() => {
    const fetchGet = async () => {
      const response = await getSubCategoriesByIDCategory(categoryId)
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (response) {
        setLoadingSubCategorias(false);
        const selectOptions = response.rows.map(
          (option: CategoriaProyectos) => ({
            value: option.id,
            label: `${option.nombre} `,
          })
        );

        selectOptions.unshift({
          id: 0,
          value: '0',
          label: `Agregar subcategoría`,
          data: {},
        });
        setSubcategorias(selectOptions);
      }
    };

    fetchGet();
  }, [categoryId, reloadSubcategoryList]);

  return {
    loadingSubCategorias,
    subcategorias,
  };
};

export const useCrearCategoriaProyectos = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const create = async (category: any) => {
    setLoading(false);
    setErrorRequest(false);
    try {
      const response = await addValueCategory(category);
      setLoading(true);
      setErrorRequest(false);
      SimpleAlert(response.message, 3000, response.status);
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoading(true);
      } else {
        setErrorRequest(true);
        history.push('/error/500');
      }
    }
  };
  return { create, loading, errorRequest };
};

export const useCrearSubcategoriaProyectos = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const create = async (subcategory: any, categoryId: number) => {
    setLoading(false);
    setErrorRequest(false);
    try {

      let data = { nombre: subcategory.nombre, orden:null, categoria_id: categoryId }
      const response = await addValueSubCategory(data);
      setLoading(true);
      setErrorRequest(false);
      SimpleAlert(response.message, 3000, response.status);
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoading(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { create, loading, errorRequest };
};

export const useCrearEditarProyecto = () => {
  const history = useHistory();
  const [successRequest, setSuccessRequest] = useState<boolean>(false);
  const crearEditarProyecto = async (values: ProyectoModel) => {
    setSuccessRequest(false);
    try {
      const res = await agregarEditarProyecto(values);
      setSuccessRequest(true);
      SimpleAlert(res.message, 3000, 'success');
      history.goBack();
    } catch (error: any) {
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setSuccessRequest(true);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { crearEditarProyecto, successRequest };
};


export const useObtenerCamposFiltroProyectos = (isAdmin: any) => {
  const [listFields, setListFields] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    let isMounted = true;

    const fetchPost = async () => {
      setLoadingRequest(true);
      try {
        const res = await obtenerCamposFiltroProyectos();
        if (isMounted && res) {
          let filteredRes = res.filter((option: any) => option.code !== "PROY_UNEG" &&  option.code !== "PROY_CAT" && option.code !== "PROY_FECHA_INIT" && option.code !== "PROY_FECHA_FIN" && option.code !== "PROY_SUBCAT");
          const selectOptions = filteredRes.map((option: any) => ({
            value: option.id,
            label: `${option.name}`,
            code: option.code,
          }));
          setListFields(selectOptions);
        }
      } catch (error) {
        history.push("/error/500");
      } finally {
        if (isMounted) {
          setLoadingRequest(false);
        }
      }
    };

    fetchPost();

    return () => {
      isMounted = false;
    };
  }, [history, isAdmin]);

  return {
    listFields,
    loadingRequest,
  };
};

export const useObtenerDatosFiltrosProyectos = (code: string) => {
  const [dataOptionSelected, setDataOptionSelected] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    let isMounted = true;

    const fetchPost = async () => {
      setLoadingRequest(true);
      try {
        const res = await obtenerDatosFiltrosProyectos(code);
        if (isMounted && res) {
          setDataOptionSelected(res);
        }
      } catch (error) {
        history.push("/error/500");
      } finally {
        if (isMounted) {
          setLoadingRequest(false);
        }
      }
    };

    if (code) {
      fetchPost();
    }

    return () => {
      isMounted = false;
    };
  }, [code, history]);

  return {
    dataOptionSelected,
    loadingRequest,
  };
};

export const useSelectTipoTareas = (code: string, execute: boolean) => {
  const [optionsSelect, setOptionSelect] = useState<any[]>([]);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        const list = data.rows.filter((item: any) => item.code !== 'TAR'); 
        const selectOptions = list.map((option: any) => ({
          value: option.id,
          label: option.name,
          code: option.code,
        }));
        setOptionSelect((prevState) => [...selectOptions]);
      }
    };

    if (execute) {
      fetchPost();
    }
  }, [execute]);

  return { optionsSelect };
};

export const useGetDocumentsByProjectId = (
  cotizacionId: number,
  reload: number
) => {
  const [dataDocuments, setDataDocuments] = useState<DocumentoProyectoModel[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingDocuments(false);
      const res = await getDocumentsByProjectId(cotizacionId)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataDocuments(res);
        setLoadingDocuments(true);
      }
    };
    if (cotizacionId > 0) {
      fetchPost();
    }
  }, [reload]);

  return { dataDocuments, loadingDocuments };
};

export const useGetNoteByProjectId = (id: number, reaload: number) => {
  const [notes, setNotes] = useState<NotaProyectoModel[]>([]);
  const [loadingNote, setLoadingNote] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingNote(false);
    const fetchPost = async () => {
      try {
        const res = await getNotesByProjectId(id);
        if (res) {
          setNotes(res);
          setLoadingNote(true);
        }
      } catch (error) {
        history.push("/error/500");
        setLoadingNote(false);
      }
    };
    if (id) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { notes, loadingNote };
};

export const useCreateNoteProject = () => {
  const [loadingCreateNote, setLoadingCreateNote] = useState<boolean>(false);
  const history = useHistory();
  const createNote = async (note: NotaProyectoModel, id: number) => {
    setLoadingCreateNote(false);
    try {
      const res = await insertNoteProject(note, id);
      SimpleAlert(res.message, 3000, 'success');
      setLoadingCreateNote(true);
    } catch (error: any) {
      setLoadingCreateNote(false);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
      } else {
        history.push('/error/500');
      }
    }
    
  };
  return { createNote, loadingCreateNote };
};

export const useGetHistory = (reaload: number, search: string, id: string) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistorialProyectos(search, id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};

export const useInsertMessageProject = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, id: number) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessageProject(message, id);
      setLoadingInsert(true);
    } catch (error) {
      history.push("/error/500");
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};


export const useSelectOpportunityProject = (reload: number) => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getOpportunitysNewProject()
        .then()
        .catch((error: any) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return { catalog, loadingRequest };
};
