import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../_metronic/helpers';
import { View } from './components/View';

const ProductsPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const productId = decryptId(id);
  // test

  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Cotizaciones',
      path: '/cotizaciones/cotizaciones',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Productos',
      path: `/cotizaciones/productos`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {productId ? 'Editar producto' : 'Nuevo producto'}
      </PageTitle>

      <View id={productId ? Number(productId) : 0} />
    </>
  );
};

export { ProductsPage };
