import { FormikProps } from 'formik';
import { KTSVG } from '../../../../../_metronic/helpers';
import Select from 'react-select';
import React, { useState, useEffect, useRef } from 'react';
import { formatDate } from '../../../../../_metronic/helpers/FormatDate';
import {
  useGetDepartmentSelect,
  useGetPositionSelect,
  useGetProfileSelect,
} from '../../hooks/CollaboratorHook';
import {
  styleJobSelect,
  styleSelect,
} from '../../../../../_metronic/helpers/SelectStyles';
import { NumericFormat } from 'react-number-format';
import { CreatePositionModal } from '../_modals/CreatePositionModal';
import { toast } from 'react-toastify';
import { CreateProfileModal } from '../_modals/CreateProfileModal';

type Props = {
  data: any[];
  formik: FormikProps<any>;
  collabId: number;
  onUpdateData: (newData: any[]) => void;
};

const JobsList: React.FC<Props> = ({ data, collabId, onUpdateData }) => {
  const radios = useRef<any>([]);
  let camposNulos: string[] = [];

  const [dataMemo, setDataMemo] = useState<any[]>(data);
  const [reloadPosition, setReloadPosition] = useState<number>(
    Math.random() * 40
  );
  const [reloadDepartment, setReloadDepartment] = useState<number>(
    Math.random() * 40
  );
  const [reloadProfile, setReloadProfile] = useState<number>(
    Math.random() * 40
  );
  const [isOpenPositionModal, setIsOpenPositionModal] = useState(false);
  const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);
  const [actualValue, setActualValue] = useState(true);
  const [positionValue, setPositionValue] = useState<any | null>(null);
  const [departmentValue, setDepartmentValue] = useState<any | null>(null);
  const [tarifaValue, setTarifaValue] = useState<any | null>(null);
  const [showJobError, setShowJobError] = useState(false);
  const [fechaInicioValue, setFechaInicioValue] = useState<any | null>(null);
  const [fechaTerminoValue, setFechaTerminoValue] = useState<any | null>(null);
  const [profileValue, setProfileValue] = useState<any | null>(null);
  const { positionDataSelect } = useGetPositionSelect('PUCOL', reloadPosition);
  const { departmentDataSelect } = useGetDepartmentSelect(
    'DEP',
    reloadDepartment
  );
  const { profileDataSelect } = useGetProfileSelect('PASOC', reloadProfile);

  const handleSelectPosition = (selectedOption: any, jobId: any) => {
    if (selectedOption) {
      setPositionValue(selectedOption);
      const { label, value } = selectedOption;
      if (value === 'new') {
        setIsOpenPositionModal(true);
        setPositionValue(null);
        localStorage.setItem('actualRow', jobId);
      } else {
        setDataMemo((prevDataMemo) => {
          const updatedDataMemo = prevDataMemo.map((item) => {
            if (item.id === jobId) {
              return {
                ...item,
                puesto: { id: value, name: label },
              };
            }

            return item;
          });
          return updatedDataMemo;
        });
      }
    }
  };

  const handleSelectDepartment = (selectedOption: any, jobId: any) => {
    if (selectedOption) {
      setDepartmentValue(selectedOption);
      const { value, label } = selectedOption;
      setDataMemo((prevDataMemo) => {
        const updatedDataMemo = prevDataMemo.map((item) => {
          if (item.id === jobId) {
            return {
              ...item,
              departamento: { id: value, name: label },
            };
          }

          return item;
        });
        return updatedDataMemo;
      });
    }
  };

  const isValid = (item: any, camposNulos: string[]) => {
    if (!item.fecha_inicio) {
      camposNulos.push('Fecha inicio');
    }
    if (!item.perfil?.id) {
      camposNulos.push('Perfil');
    }
    if (!item.fecha_termino && !actualValue) {
      camposNulos.push('Fecha término');
    }

    return !camposNulos.length;
  };

  useEffect(() => {
    if (data.length) {
      const cleanedData = data.filter((item) => item.id !== 0);
      const isActual = cleanedData[cleanedData.length - 1];
      setActualValue(isActual?.actual);
      setFechaInicioValue(isActual?.fecha_inicio);
      setFechaTerminoValue(isActual?.fecha_termino);
      setDataMemo(cleanedData);
    }
  }, [data]);

  useEffect(() => {
    if (data && collabId > 0) {
      localStorage.setItem('memo', JSON.stringify(data));
    }
  }, [data]);

  useEffect(() => {
    // Actualiza el estado de Formik cuando dataMemo cambia
    onUpdateData(dataMemo);
  }, [dataMemo]);

  const AddRow = () => {
    let newElement;
    let cleanedData = dataMemo.filter((item) => item.key !== 0);
    const lastAdded = dataMemo.filter((item) => item.key === 0);
    camposNulos = [];

    if (lastAdded.length > 0) {
      const allValid = dataMemo.every((item) => isValid(item, camposNulos));

      if (!allValid) {
        const messageErrorToast =
          'Completa la información de todos los campos para agregar un nuevo registro: ' +
          camposNulos;
        return toast.error(messageErrorToast, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }

      const fechaTerminoValidationList = lastAdded.filter(
        (item) =>
          new Date(item.fecha_termino).getTime() <
          new Date(item.fecha_inicio).getTime()
      );

      if (fechaTerminoValidationList.length > 0) {
        const messageErrorToast =
          'La fecha de término del empleo no puede ser menor que la fecha de inicio.';
        return toast.error(messageErrorToast, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }

      newElement = lastAdded[0];
      newElement.key = dataMemo.length;
      newElement.id = `N/${dataMemo.length}`;
      cleanedData.push(newElement);
    } else {
      if (collabId > 0) {
        const allValid = dataMemo.every((item) => isValid(item, camposNulos));
        if (!allValid) {
          const messageErrorToast =
            'Completa la información de todos los campos para agregar un nuevo registro: ' +
            camposNulos;
          return toast.error(messageErrorToast, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }

        const fechaTerminoValidationList = dataMemo.filter(
          (item) =>
            new Date(item.fecha_termino).getTime() <
            new Date(item.fecha_inicio).getTime()
        );

        if (fechaTerminoValidationList.length > 0) {
          const messageErrorToast =
            'La fecha de término del empleo no puede ser menor que la fecha de inicio.';
          return toast.error(messageErrorToast, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      }
    }

    setPositionValue('');
    setDepartmentValue('');
    setTarifaValue('');
    setProfileValue('');
    setActualValue(true);
    setFechaInicioValue(null);
    setFechaTerminoValue(null);

    const newRow: any = {
      id: 0,
      fecha_inicio: '',
      fecha_termino: null,
      puesto: 0,
      departamento: 0,
      tarifa_unidad_esfuerzo: '',
      actual: true,
      key: 0,
    };

    setDataMemo([...cleanedData, newRow]);
  };

  const handleFechaInicioChange = (fechaInicio: any, jobId: number) => {
    const newValue = fechaInicio
      ? new Date(fechaInicio).toISOString().split('T')[0]
      : null;

    setFechaInicioValue(newValue);

    if (newValue && fechaTerminoValue) {
      const newValueDate = new Date(newValue + 'T00:00:00');
      const fechaTerminoDate = new Date(fechaTerminoValue);

      if (fechaTerminoDate.getTime() < newValueDate.getTime()) {
        const messageErrorToast =
          'La fecha de término del empleo no puede ser menor que la fecha de inicio.';
        return toast.error(messageErrorToast, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }

    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === jobId) {
          return {
            ...item,
            fecha_inicio: newValue,
          };
        }
        return item;
      });
      return updatedDataMemo;
    });
  };

  const handleFechaTerminoChange = (fechaTermino: any, jobId: number) => {
    const newValue =
      fechaTermino && fechaTermino !== ''
        ? new Date(fechaTermino).toISOString().split('T')[0]
        : null;

    const today = new Date();

    const todaySixMonths = new Date(today);

    todaySixMonths.setMonth(today.getMonth() + 7); //Se suman 7 debido a que toma enero como 0

    let dateFechaTermino;

    if (fechaTermino) {
      dateFechaTermino = new Date(fechaTermino + 'T00:00:00');

      if (dateFechaTermino.getTime() > todaySixMonths.getTime()) {
        const messageErrorToast =
          'La fecha termino del empleo no puede ser mayor a 6 meses.';
        setFechaTerminoValue(null);
        setDataMemo((prevDataMemo) => {
          const updatedDataMemo = prevDataMemo.map((item) => {
            if (item.id === jobId) {
              return {
                ...item,
                fecha_termino: null,
              };
            }
            return item;
          });
          return updatedDataMemo;
        });
        return toast.error(messageErrorToast, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }

    setFechaTerminoValue(newValue);
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === jobId) {
          return {
            ...item,
            fecha_termino: fechaTermino,
          };
        }
        return item;
      });
      return updatedDataMemo;
    });
  };

  const handleTarifaChange = (newTarifa: number, jobId: number) => {
    setTarifaValue(newTarifa);
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === jobId) {
          return {
            ...item,
            tarifa_unidad_esfuerzo: newTarifa,
          };
        }
        return item;
      });
      return updatedDataMemo;
    });
  };

  const changeProfile = (selectedOption: any, jobId: any) => {
    if (selectedOption) {
      setProfileValue(selectedOption);
      const { value, label } = selectedOption;
      if (value === 'new') {
        setIsOpenProfileModal(true);
        setProfileValue(null);
        localStorage.setItem('actualRow', jobId);
      } else {
        setDataMemo((prevDataMemo) => {
          const updatedDataMemo = prevDataMemo.map((item) => {
            if (item.id === jobId) {
              return {
                ...item,
                perfil: { id: value, name: label },
              };
            }

            return item;
          });
          return updatedDataMemo;
        });
      }
    }
  };

  const handleChangeActual = (jobId: any) => {
    const newChecked = !actualValue;
    setActualValue(newChecked);

    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === jobId) {
          return {
            ...item,
            actual: !actualValue,
          };
        }
        return {
          ...item,
          actual: false,
        };
      });

      return updatedDataMemo;
    });
  };

  const validationFinishDate = (date: Date) => {
    let nuevaFecha = new Date(date);
    nuevaFecha.setDate(nuevaFecha.getDate() + 1);
    return nuevaFecha.toISOString().split('T')[0];
  };

  return (
    <>
      <div className="table-responsive min-h-400px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="required w-150px">Fecha inicio</th>
              <th className={`${!actualValue ? 'required' : ''} w-200px`}>
                Fecha término
              </th>
              <th className="w-350px required">Perfil</th>
              <th className="w-350px">Puesto</th>
              <th className="w-350px">Departamento</th>
              <th className="w-150px">Tarifa/hh</th>
              <th className="w-150px">Actual</th>
            </tr>
          </thead>
          <tbody className="">
            {dataMemo?.length > 0 ? (
              dataMemo.map((row, index) => (
                <tr key={row.id}>
                  <td>
                    {row.id === 0 ? (
                      <input
                        type="date"
                        className={`form-control`}
                        id="fecha_inicio"
                        name="fecha_inicio"
                        onChange={(event) =>
                          handleFechaInicioChange(event.target.value, row.id)
                        }
                        value={
                          row.fecha_inicio
                            ? new Date(row.fecha_inicio)
                                .toISOString()
                                .split('T')[0]
                            : ''
                        }
                      />
                    ) : (
                      <>
                        {index + 1 === dataMemo.length ? (
                          <input
                            type="date"
                            className={`form-control`}
                            id="fecha_inicio"
                            name="fecha_inicio"
                            onChange={(event) =>
                              handleFechaInicioChange(
                                event.target.value,
                                row.id
                              )
                            }
                            value={
                              fechaInicioValue
                                ? new Date(fechaInicioValue)
                                    .toISOString()
                                    .split('T')[0]
                                : ''
                            }
                          />
                        ) : (
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              {row.fecha_inicio
                                ? formatDate(
                                    new Date(
                                      row.fecha_inicio.split('T')[0] +
                                        'T00:00:00'
                                    )
                                  )
                                : ''}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {row.id === 0 ? (
                      <input
                        type="date"
                        className={`form-control`}
                        id="fecha_termino"
                        name="fecha_termino"
                        disabled={actualValue}
                        autoComplete="one-time-code"
                        min={validationFinishDate(fechaInicioValue)}
                        onChange={(event) =>
                          handleFechaTerminoChange(event.target.value, row.id)
                        }
                        value={
                          row.fecha_termino
                            ? new Date(row.fecha_termino)
                                .toISOString()
                                .split('T')[0]
                            : ''
                        }
                      />
                    ) : (
                      <>
                        {index + 1 === dataMemo.length ? (
                          <input
                            type="date"
                            className={`form-control`}
                            id="fecha_termino"
                            autoComplete="one-time-code"
                            disabled={actualValue}
                            min={validationFinishDate(fechaInicioValue)}
                            name="fecha_termino"
                            onChange={(event) =>
                              handleFechaTerminoChange(
                                event.target.value,
                                row.id
                              )
                            }
                            value={
                              fechaTerminoValue
                                ? new Date(fechaTerminoValue)
                                    .toISOString()
                                    .split('T')[0]
                                : ''
                            }
                          />
                        ) : (
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              {row.fecha_termino
                                ? formatDate(
                                    new Date(
                                      row.fecha_termino.split('T')[0] +
                                        'T00:00:00'
                                    )
                                  )
                                : ''}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    <div className="d-flex align-items-center p-0">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        {row.id === 0 ? (
                          <Select
                            key={row.id}
                            value={profileValue}
                            options={profileDataSelect}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={styleJobSelect}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={(event) => changeProfile(event, row.id)}
                            backspaceRemovesValue
                          />
                        ) : (
                          <>
                            {index + 1 === dataMemo.length ? (
                              <Select
                                key={row.id}
                                value={
                                  row.perfil?.id
                                    ? {
                                        value: row.perfil.id,
                                        label: row.perfil.name,
                                      }
                                    : ''
                                }
                                options={profileDataSelect}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={styleJobSelect}
                                placeholder={'Seleccione'}
                                isSearchable={true}
                                noOptionsMessage={() => {
                                  return (
                                    <span>{'No se encontraron registros'}</span>
                                  );
                                }}
                                onChange={(event) =>
                                  changeProfile(event, row.id)
                                }
                                backspaceRemovesValue
                              />
                            ) : (
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {row.perfil?.name}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        {row.id === 0 ? (
                          <Select
                            key={row.id}
                            value={positionValue}
                            options={positionDataSelect}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={styleJobSelect}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={(event) =>
                              handleSelectPosition(event, row.id)
                            }
                            backspaceRemovesValue
                          />
                        ) : (
                          <>
                            {index + 1 === dataMemo.length ? (
                              <Select
                                key={row.id}
                                value={
                                  row.puesto?.id
                                    ? {
                                        value: row.puesto.id,
                                        label: row.puesto.name,
                                      }
                                    : ''
                                }
                                options={positionDataSelect}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={styleJobSelect}
                                placeholder={'Seleccione'}
                                isSearchable={true}
                                noOptionsMessage={() => {
                                  return (
                                    <span>{'No se encontraron registros'}</span>
                                  );
                                }}
                                onChange={(event) =>
                                  handleSelectPosition(event, row.id)
                                }
                                backspaceRemovesValue
                              />
                            ) : (
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {row.puesto?.name}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        {row.id === 0 ? (
                          <Select
                            key={row.id}
                            value={departmentValue}
                            options={departmentDataSelect}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={styleSelect}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={(event) =>
                              handleSelectDepartment(event, row.id)
                            }
                            backspaceRemovesValue
                          />
                        ) : (
                          <>
                            {index + 1 === dataMemo.length ? (
                              <Select
                                key={row.id}
                                value={
                                  row.departamento?.id
                                    ? {
                                        value: row.departamento.id,
                                        label: row.departamento.name,
                                      }
                                    : ''
                                }
                                options={departmentDataSelect}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={styleSelect}
                                placeholder={'Seleccione'}
                                isSearchable={true}
                                noOptionsMessage={() => {
                                  return (
                                    <span>{'No se encontraron registros'}</span>
                                  );
                                }}
                                onChange={(event) =>
                                  handleSelectDepartment(event, row.id)
                                }
                                backspaceRemovesValue
                              />
                            ) : (
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {row.departamento?.id
                                    ? row.departamento.name
                                    : ''}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.id === 0 ? (
                          <NumericFormat
                            className="form-control w-100px"
                            value={tarifaValue}
                            thousandSeparator=","
                            decimalSeparator="."
                            allowNegative={false}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (!value) {
                                e.target.value = '1';
                              }
                            }}
                            isAllowed={(values: any) => {
                              const { floatValue } = values;
                              const integerPart = Math.floor(floatValue);
                              return integerPart.toString().length <= 7;
                            }}
                            suffix=""
                            allowLeadingZeros={false}
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              if (floatValue) {
                                if (floatValue > 0) {
                                  handleTarifaChange(floatValue, row.id);
                                }
                              } else {
                                handleTarifaChange(0, row.id);
                              }
                            }}
                          />
                        ) : (
                          <>
                            {index + 1 === dataMemo.length ? (
                              <NumericFormat
                                className="form-control w-100px"
                                thousandSeparator=","
                                decimalSeparator="."
                                allowNegative={false}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                value={
                                  tarifaValue
                                    ? tarifaValue
                                    : row.tarifa_unidad_esfuerzo ?? ''
                                }
                                onBlur={(e) => {
                                  const value = e.target.value;
                                  if (!value) {
                                    e.target.value = '';
                                  }
                                }}
                                isAllowed={(values: any) => {
                                  const { floatValue } = values;
                                  const integerPart = Math.floor(floatValue);
                                  return integerPart.toString().length <= 7;
                                }}
                                suffix=""
                                allowLeadingZeros={false}
                                onValueChange={(values) => {
                                  const { floatValue } = values;
                                  if (floatValue) {
                                    if (floatValue > 0) {
                                      handleTarifaChange(floatValue, row.id);
                                    }
                                  } else {
                                    handleTarifaChange(0, row.id);
                                  }
                                }}
                              />
                            ) : (
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {Number(
                                    row.tarifa_unidad_esfuerzo
                                  ).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        {row.id === 0 ? (
                          <input
                            ref={(el: any) => (radios.current[index] = el)}
                            className="form-check-input mx-4 "
                            type="radio"
                            checked={actualValue}
                            onClick={(event: any) => {
                              handleChangeActual(row.id);
                            }}
                          />
                        ) : (
                          <>
                            {index + 1 === dataMemo.length ? (
                              <input
                                ref={(el: any) => (radios.current[index] = el)}
                                className="form-check-input mx-4 "
                                type="radio"
                                checked={row.actual}
                                onClick={(event: any) => {
                                  handleChangeActual(row.id);
                                }}
                              />
                            ) : (
                              <input
                                className="form-check-input mx-4 "
                                type="radio"
                                disabled={true}
                                checked={row.actual}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center text-muted">
                  Sin registros
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={9}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddRow}
                    hidden={dataMemo.length > 0 && actualValue}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Empleo
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <CreatePositionModal
            show={isOpenPositionModal}
            handleClose={(isNew: boolean, newPosition: any) => {
              if (isNew && newPosition) {
                setReloadPosition(Math.random() * 50);
                const actualRow = localStorage.getItem('actualRow');
                handleSelectPosition(newPosition, Number(actualRow));
              }
              setIsOpenPositionModal(!isOpenPositionModal);
            }}
          ></CreatePositionModal>
          <CreateProfileModal
            show={isOpenProfileModal}
            handleClose={(isNew: boolean, newProfile: any) => {
              if (isNew && newProfile) {
                setReloadProfile(Math.random() * 50);
                const actualRow = localStorage.getItem('actualRow');
                changeProfile(newProfile, Number(actualRow));
              }
              setIsOpenProfileModal(!isOpenProfileModal);
            }}
          ></CreateProfileModal>
        </table>
      </div>
    </>
  );
};

export { JobsList };
