import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { View } from './components/View';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../_metronic/helpers';

const NoteProjectPage: FC = () => {
  const { proyecto_id, id } = useParams<{ proyecto_id: string; id: string }>();

  const noteId = decryptId(id);
  const proyectoId = decryptId(proyecto_id);
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Proyectos',
      path: '/proyectos/proyectos/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Editar',
      path: `/proyectos/proyectos/edit/${proyecto_id}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Notas',
      path: `/proyectos/proyectos/edit/${proyecto_id}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {noteId ? 'Editar nota' : 'Nueva nota'}
      </PageTitle>

      <View
        id={noteId ? noteId : '0'}
        proyectoId={proyectoId ? proyectoId : '0'}
      />
    </>
  );
};

export { NoteProjectPage };
