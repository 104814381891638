import axios from "axios";
import {
  CategoryModel,
  UpdServiceModel,
} from "../../pages/cotizacion/models/QuotationModel";
import { formattCoin } from "../../../_metronic/helpers/FormatCurrency";
import { ServiceModel } from "../../pages/services/models/ServiceModel";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { NoteServiceModel } from "../../pages/services/models/NoteServiceModel";
import { SelectFormat } from "../../../_metronic/helpers/models/GenericModel";

const API_URL = process.env.REACT_APP_API;
const API_URL_IMAGE_PUBLIC = `${process.env.REACT_APP_PUBLIC}servicio/`;

export async function getCategories(filters: any = null) {
  let campo_id = null;
  let valor = null;
  if (filters) {
    if (filters.campo_id) {
      campo_id = filters.campo_id;
    }
    if (filters.valor) {
      valor = filters.valor;
    }
  }
  const response = await axios.post(`${API_URL}servicios/group/category`, {
    campo_id,
    valor,
  });
  const data = response.data.doc.data;
  return {
    data: data,
  };
}

export async function insertCategoryService(category: CategoryModel) {
  const response = await axios.post(`${API_URL}categoria/servicios`, {
    nombre: category.nombre,
  });
  return response;
}

export async function createServiceShorcut(
  serviceName: string,
  categoryId: number
) {
  const response = await axios.post(`${API_URL}servicios/shortcut/add`, {
    nombre: serviceName,
    categoria_id: categoryId,
  });
  return response;
}

export async function updService(body: UpdServiceModel) {
  const {
    id,
    nombre = null,
    unidad_medida_venta_id = null,
    unidad_moneda_venta_id = null,
    impuesto_id = null,
    impuesto_siglas = null,
    impuesto_descripcion = null,
    impuesto_porcentaje = null,
    precio_fijo_venta = null,
    inventario = null,
    proveedor_id = null,
    proveedor = null,
    nombre_proveedor = null,
  } = body;

  const data = {
    nombre,
    unidad_medida_venta_id,
    unidad_moneda_venta_id,
    impuesto_id,
    impuesto_siglas,
    impuesto_descripcion,
    impuesto_porcentaje,
    precio_fijo_venta,
    inventario,
    proveedor_id,
    proveedor,
    nombre_proveedor,
  };

  const filteredData = Object.entries(data).reduce((acc: any, [key, value]) => {
    if (value !== null && value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});

  const response = await axios.patch(
    `${API_URL}servicios/shortcut/update/${id}`,
    filteredData
  );
  return response;
}

export async function getFilterSupplier() {
  const response = await axios.post(`${API_URL}proveedores/`);

  const data = response.data.doc.data;
  return {
    data: data,
  };
}

export async function getTaxes() {
  const response = await axios.get(`${API_URL}catalogs/cotizaciones/impuestos`);
  const data = response.data.doc.data;
  // const count = response.data.doc.data.count;
  return data;
}

export const uploadImageService = async (id: number, file: any) => {
  const formData = new FormData();
  formData.append("image", file);

  const response = await axios.post(
    `${API_URL}servicios/shortcut/image/${id}`,
    formData
  );
  return response;
};

export const uploadDocService = async (id: number, file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(
    `${API_URL}servicios/shortcut/documento/${id}`,
    formData
  );
  return response;
};

export const downloadDoc = (fileId: number, fileName: string, event: any) => {
  event.preventDefault();
  event.stopPropagation();

  axios({
    url: `${process.env.REACT_APP_API}servicios/shortcut/download/documento/${fileId}`,
    method: "GET",
    responseType: "blob", // Asegura que la respuesta se maneje como un Blob
  })
    .then((response) => {
      // Asegúrate de obtener el tipo de contenido del archivo desde el encabezado de la respuesta
      const contentType = response.headers["content-type"];
      const urlBlob = window.URL.createObjectURL(
        new Blob([response.data], { type: contentType })
      );
      const link = document.createElement("a");
      link.href = urlBlob;
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Esto es necesario para que el link funcione en ciertos navegadores
      link.click();
      window.URL.revokeObjectURL(urlBlob);
      document.body.removeChild(link); // Limpieza después de la descarga
    })
    .catch(async (error) => {
      // Manejo de errores
    });
};

export const removeDocService = async (docId: number) => {
  const response = await axios.delete(
    `${API_URL}servicios/shortcut/documento/${docId}`
  );
  return response;
};

export async function getAllServices(filters: any) {
  let campo_id = null;
  let valor = null;
  if (filters) {
    if (filters.campo_id) {
      campo_id = filters.campo_id;
    }
    if (filters.valor) {
      valor = filters.valor;
    }
  }
  const response = await axios.post(`${API_URL}servicios/`, {
    campo_id,
    valor,
  });
  const data = response.data.doc.data;
  // const count = response.data.doc.data.count;
  return {
    data: data,
  };
}
export async function obtenerTodosServicios(filters: any) {
  let campo_id = null;
  let valor = null;
  if (filters) {
    if (filters.campo_id) {
      campo_id = filters.campo_id;
    }
    if (filters.valor) {
      valor = filters.valor;
    }
  }
  const response = await axios.post(`${API_URL}servicios/list`, {
    campo_id,
    valor,
  });
  const data = response.data.doc.data;
  // const count = response.data.doc.data.count;
  return {
    data: data,
  };
}

export const removeService = async (serviceId: number) => {
  const response = await axios.delete(`${API_URL}servicios/${serviceId}`);
  return response;
};

// --------------CATEGORIAS--------------------

export async function getCategoriesGrid() {
  const response = await axios.get(`${API_URL}categoria/servicios`);
  const data = response.data.doc.data;
  return data;
}

export async function getDataCategoryById(id_row: number) {
  const response = await axios.get(`${API_URL}categoria/servicios/${id_row}`);
  const data = response.data.doc.data;
  return data;
}

export async function addValueCategory(body: any) {
  const response = await axios.post(`${API_URL}categoria/servicios`, {
    nombre: body.nombre,
    orden: body.orden
  });
  return response;
}

export async function updValueCategory(body: any) {
  const data = {
    nombre: body.nombre,
    orden: body.orden,
  };

  const response = await axios.patch(
    `${API_URL}categoria/servicios/${body.id}`,
    data
  );
  return response;
}

export async function removeRegisterCategory(id: number) {
  const response = await axios.delete(`${API_URL}categoria/servicios/${id}`);

  return response;
}

// ------CATALOGO SUBCATEGORIAS-----------

export async function getSubCategoriesGrid() {
  const response = await axios.get(`${API_URL}subcategoria/servicios`);
  const data = response.data.doc.data;
  return data;
}

export async function getSubCategoriesByCat(categoryId?: number) {
  const response = await axios.get(
    `${API_URL}subcategoria/servicios/categoria/${categoryId}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getDataSubCategoryById(id_row: number) {
  const response = await axios.get(
    `${API_URL}subcategoria/servicios/${id_row}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function addValueSubCategory(body: any) {
  const response = await axios.post(`${API_URL}subcategoria/servicios`, {
    nombre: body.nombre,
    categoria_id: body.categoria_id,
    orden: body.orden,
  });
  return response;
}

export async function updValueSubCategory(body: any) {
  const data = {
    nombre: body.nombre,
    orden: body.orden,
    categoria_id: body.categoria_id,
  };

  const response = await axios.patch(
    `${API_URL}subcategoria/servicios/${body.id}`,
    data
  );
  return response;
}

export async function removeRegisterSubCategory(id: number) {
  const response = await axios.delete(`${API_URL}subcategoria/servicios/${id}`);

  return response;
}

export const removeFileOfService = async (id: number) => {
  const response = await axios.delete(`${API_URL}servicios/documento/${id}`);
  return response;
};

export const uploadFileToService = async (file: any, id: string) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(
    `${API_URL}servicios/documento/${id}`,
    formData
  );
  return response;
};

export const addServiceType = async (category: any) => {
  try {
    const { name } = category;

    const response = await axios.post(`${API_URL}catalogs/add/tserv`, {
      name,
    });
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar el registro:", error);
    throw error;
  }
};

export const addCategoryServices = async (category: any) => {
  try {
    const { nombre } = category;

    const response = await axios.post(`${API_URL}categoria/servicios`, {
      nombre,
    });
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar paquete:", error);
    throw error;
  }
};

export const addSubcategoryService = async (
  subcategory: any,
  categoryId: number
) => {
  try {
    const { nombre } = subcategory;

    const response = await axios.post(`${API_URL}subcategoria/servicios`, {
      nombre,
      categoria_id: categoryId,
    });
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar el registro:", error);
    throw error;
  }
};

export async function copyService(id: number) {
  const response = await axios.post(`${API_URL}servicios/copy/${id}`);
  return response.data;
}

export async function getServiceById(
  serviceId: number
): Promise<ServiceModel | undefined> {
  try {
    const random = Math.random() * 40;
    const response = await axios.get(`${API_URL}servicios/${serviceId}`);
    const data = response.data.doc.data;

    const {
      imagen,
      unidadNegocio,
      subcategoria,
      tipo,
      impuesto,
      perfil_asociado,
      activo_asociado,
      tipoPrecioCompra,
      tipoCompra,
    } = data;

    data.imagen = data.imagen
      ? `${API_URL_IMAGE_PUBLIC}${imagen}?${random}`
      : toAbsoluteUrl("/media/avatars/photo.png");

    data.tipo_id = tipo ? tipo?.id : null;
    data.unidad_negocio_id = unidadNegocio ? unidadNegocio?.id : null;
    data.impuesto_id = impuesto ? impuesto?.id : null;
    data.impuesto_porcentaje = impuesto ? impuesto.porcentaje : null;
    data.sub_categoria_id = subcategoria ? subcategoria?.id : null;
    data.perfil_id = perfil_asociado ? perfil_asociado?.id : null;
    data.activo_id = activo_asociado ? activo_asociado?.id : null;
    data.tipo_precio_compra_id = tipoPrecioCompra ? tipoPrecioCompra?.id : null;
    data.tipo_compra_id = tipoCompra ? tipoCompra : null;

    return data;
  } catch (error) {
    console.error("ERROR:", error);
    return undefined;
  }
}

export const addUpdateService = async (serviceData: any) => {
  try {
    const {
      id,
      nombre,
      descripcion,
      categoria_id,
      unidad_moneda_id,
      unidad_negocio_id,
      tipo_id,
      image,
      impuesto_id,
      sku,
      sub_categoria_id,
      precio_venta,
      tipo_venta_id,
      unidad_medida_venta_id,
      unidad_moneda_venta_id,
      tipo_precio_venta_id,
      precio_fijo_venta,
      aplica_impuesto_precio_venta,
      porcentaje_beneficio_precio_venta,
      precio_objetivo_calculado_venta,
      precio_compra,
      tipo_compra_id,
      unidad_medida_compra_id,
      unidad_moneda_compra_id,
      tipo_precio_compra_id,
      precio_fijo_compra,
      precio_proveedor_compra,
      porcentaje_beneficio_precio_compra,
      porcentaje_conversion_compra,
      tipo_cambio_compra,
      perfil_id,
      activo_id,
      proveedor_id,
      proveedores
    } = serviceData;

    const formData = new FormData();

    formData.append("nombre", nombre);
    formData.append("sku", sku ? sku : "");
    formData.append("descripcion", descripcion ? descripcion : "");
    formData.append("precio_venta", precio_venta);
    formData.append(
      "monto_beneficio_precio_venta",
      precio_objetivo_calculado_venta ? precio_objetivo_calculado_venta : 0
    );
    formData.append(
      "precio_fijo_venta",
      precio_fijo_venta ? precio_fijo_venta : 0
    );
    formData.append(
      "aplica_impuesto_precio_venta",
      aplica_impuesto_precio_venta
    );
    formData.append(
      "porcentaje_beneficio_precio_venta",
      porcentaje_beneficio_precio_venta ? porcentaje_beneficio_precio_venta : 0
    );
    formData.append(
      "precio_objetivo_calculado_venta",
      precio_objetivo_calculado_venta ? precio_objetivo_calculado_venta : 0
    );
    formData.append("precio_compra", precio_compra ? precio_compra : 0);
    formData.append(
      "precio_fijo_compra",
      precio_fijo_compra ? precio_fijo_compra : 0
    );
    formData.append(
      "precio_proveedor_compra",
      precio_proveedor_compra ? precio_proveedor_compra : 0
    );
    formData.append(
      "porcentaje_conversion_compra",
      porcentaje_conversion_compra ? porcentaje_conversion_compra : 0
    );

    formData.append(
      "porcentaje_beneficio_precio_compra",
      porcentaje_beneficio_precio_compra || 0
    );

    if (activo_id > 0) {
      formData.append("activo_id", activo_id);
    }
    if (proveedor_id > 0) {
      formData.append("proveedor_id", proveedor_id);
    }
    if (perfil_id > 0) {
      formData.append("perfil_id", perfil_id);
    }
    if (tipo_cambio_compra > 0) {
      formData.append("tipo_cambio_compra", tipo_cambio_compra);
    }
    if (tipo_venta_id > 0) {
      formData.append("tipo_venta_id", tipo_venta_id);
    }
    if (tipo_precio_compra_id > 0) {
      formData.append("tipo_precio_compra_id", tipo_precio_compra_id);
    }
    if (tipo_compra_id > 0) {
      formData.append("tipo_compra_id", tipo_compra_id);
    }
    if (unidad_medida_venta_id > 0) {
      formData.append("unidad_medida_venta_id", unidad_medida_venta_id);
    }
    if (unidad_moneda_venta_id > 0) {
      formData.append("unidad_moneda_venta_id", unidad_moneda_venta_id);
    }
    if (unidad_medida_compra_id > 0) {
      formData.append("unidad_medida_compra_id", unidad_medida_compra_id);
    }
    if (unidad_moneda_compra_id > 0) {
      formData.append("unidad_moneda_compra_id", unidad_moneda_compra_id);
    }
    if (tipo_precio_venta_id > 0) {
      formData.append("tipo_precio_venta_id", tipo_precio_venta_id);
    }
    if (categoria_id > 0) {
      formData.append("categoria_id", categoria_id);
    }
    if (sub_categoria_id > 0) {
      formData.append("subcategoria_id", sub_categoria_id);
    }
    if (tipo_id > 0) {
      formData.append("tipo_id", tipo_id);
    }
    if (impuesto_id > 0) {
      formData.append("impuesto_id", impuesto_id);
    }
    if (unidad_moneda_id > 0) {
      formData.append("unidad_moneda_id", unidad_moneda_id);
    }
    if (unidad_negocio_id > 0) {
      formData.append("unidad_negocio_id", unidad_negocio_id);
    }
    if (image) {
      formData.append("imagen", image);
    }
    if (proveedores.length) {
      formData.append("proveedores", JSON.stringify(proveedores));
    }
    let response;
    if (id) {
      response = await axios.patch(`${API_URL}servicios/${id}`, formData);
    } else {
      response = await axios.post(`${API_URL}servicios/add`, formData);
    }
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar servicio:", error);
    throw error;
  }
};

export async function getNotesByServiceId(id: number) {
  const response = await axios.get(`${API_URL}servicios/notas/servicio/${id}`);
  const data = response.data.doc.data;
  return data;
}

export async function addSupplierToService(body: any) {
  const {
    nombre_proveedor,
    servicio_id,
    proveedor_id,
    unidad_medida_id,
    unidad_moneda_id,
  } = body;

  const response = await axios.post(`${API_URL}proveedores/servicio/add`, {
    nombre_proveedor,
    servicio_id,
    proveedor_id,
    unidad_medida_id,
    unidad_moneda_id,
  });

  return response;
}

export async function getSupplierByService(serviceId: number) {
  const response = await axios.get(
    `${API_URL}proveedores/services/${serviceId}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function updateSupplierToService(body: any) {
  const {
    nombre_proveedor,
    servicio_id,
    id,
    volumen,
    precio_unitario,
    total,
    unidad_moneda_id,
    unidad_medida_id,
    seleccionado,
  } = body;

  const response = await axios.patch(`${API_URL}proveedores/servicio/update`, {
    volumen,
    nombre_proveedor,
    servicio_id,
    id,
    precio_unitario,
    total,
    unidad_moneda_id,
    unidad_medida_id,
    seleccionado,
  });

  return response;
}

export async function insertNoteService(note: NoteServiceModel, id: number) {
  const response = await axios.post(`${API_URL}servicios/notas`, {
    nombre: note.nombre,
    descripcion: note.descripcion,
    servicio_id: Number(id),
  });
  return response;
}

export async function getHistoryServices(id: string) {
  const response = await axios.get(`${API_URL}servicios/historial/${id}`);

  const data = response.data.doc.data;
  return data;
}

export async function createHistoryMessageService(message: string, id: number) {
  const response = await axios.post(
    `${API_URL}servicios/historial/comment/add`,
    {
      serviceId: id,
      comentario: message,
    }
  );
  return response;
}

export async function getDocumentsByServiceId(id: number) {
  const response = await axios.get(`${API_URL}servicios/documentos/${id}`);
  const data = response.data.doc.data;
  return data;
}

export async function getActiveCatService() {
  const response = await axios.get(`${API_URL}servicios/activoAsociado/`);
  const data = response.data.doc.data;
  let select: SelectFormat[] = [];

  data?.forEach((item: any) => {
    const selectCustom = {
      id: Number(item?.id),
      value: item?.id,
      label: `${item?.nombre}`,
      data: item,
    };

    select.push(selectCustom);
  });
  return select;
}

export async function getDataFilterBySelectFiledServices(code: string) {
  let response;
  let data;

  switch (code) {
    case "SERV_NOM":
      response = await axios.post(`${API_URL}servicios`, {
        campo_id: null,
        valor: null,
      });
      data = response.data.doc.data;
      break;
    case "SERV_UNEG":
      response = await axios.get(`${API_URL}catalogs/UNEG?sort=name`);
      data = response.data.doc.data.rows;
      break;
    case "SERV_TIPO":
      response = await axios.get(`${API_URL}catalogs/TSERV?sort=name`);
      data = response.data.doc.data.rows;
      break;

    default:
      break;
  }
  return data;
}

export async function getCatFieldsServ() {
  const response = await axios.get(`${API_URL}catalogs/campos/TBL_SERV`);

  const data = response.data.doc.data;
  const codesToFilter: string[] = [
    "SERV_NOM",
    "SERV_UNEG",
    "SERV_TIPO",
    "SERV_PV",
  ];
  const dataFilter = data.filter((obj: any) =>
    codesToFilter.includes(obj.code)
  );
  return dataFilter;
}

export const estatusServicio = async (id: number, estatus: number) => {
  try {
    const response = await axios.patch(`${API_URL}/servicios/${id}/estatus`, {
      estatus: estatus,
    });
    return response;
  } catch (error) {
    console.error("Error actualizando el estatus del servicio:", error);
    throw error;
  }
};
export const validarServicio = async (id: number) => {
  try {
    const response = await axios.get(`${API_URL}/servicios/${id}/validar`);
    return response;
  } catch (error) {
    console.error("Error actualizando el estatus del servicio:", error);
    throw error;
  }
};