import { FC } from 'react';
import { PageTitle, PageLink } from '../../../../_metronic/layout/core';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../../_metronic/helpers';
import { View } from './components/View';

const ViewForm: FC = () => {
  const { id } = useParams<{ id: string }>();
  const supplierId: number = Number(decryptId(id));
  let titulo: string = supplierId ? `Editar proveedor` : `Nuevo proveedor`;
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Cotizaciones',
      path: '/cotizaciones/cotizaciones',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Proveedores',
      path: '/cotizaciones/proveedores/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{titulo}</PageTitle>
      <View proveedor_id={supplierId} />
    </>
  );
};

export { ViewForm };
