import axios from "axios";
import { SelectFormat } from "../../../_metronic/helpers/models/GenericModel";
import { NoteQuoModel } from "../../pages/notes_quo/models/NoteModel";

const API_URL = process.env.REACT_APP_API;

export async function getCatalogAccount() {
  const response = await axios.post(`${API_URL}catalogos/cuentas?sort=nombre`);
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  select.unshift({
    id: 0,
    value: "0",
    label: `Agregar cuenta`,
    data: {},
  });

  return select;
}

export const addSubaccountQuo = async (subaccount: any, accountId: number) => {
  try {
    const { nombre } = subaccount;
    const response = await axios.post(`${API_URL}catalogos/subcuentas/add`, {
      nombre,
      cuenta_id: accountId,
    });
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar la subcuenta:", error);
    throw error;
  }
};

export async function getCatalogSubaccount(accountId: number) {
  const response = await axios.get(
    `${API_URL}catalogos/subcuentas/cuenta/${accountId}`
  );
  const data = response.data.doc.data;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  select.unshift({
    id: 0,
    value: "0",
    label: `Agregar subcuenta`,
    data: {},
  });
  return select;
}

export async function getCatalogSurcharge() {
  const response = await axios.get(`${API_URL}cotizacion/recargo`);
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}

export async function getCatalogDiscount() {
  const response = await axios.get(`${API_URL}cotizacion/descuento`);
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}

export const addUpdateQuotation = async (quotation: any) => {
  try {
    let response = {
      data: {
        message: "",
      },
    };
    const {
      id,
      nombre,
      unidad_negocio_id,
      cuenta_id,
      subcuenta_id,
      contacto_id,
      tipo_id,
      unidad_moneda_id,
      vendedor_asignado_id,
      recargo_id,
      descuento_id,
      cotizacion_grupo,
      cotizacion_origen,
      cargo_version,
      secciones,
      costo_indirecto_value,
      utilidad_value,
      oportunidad_id,
      relacionar_oportunidad
    } = quotation;

    if (id) {
      response = await axios.patch(`${API_URL}cotizaciones`, {
        idCotizacion: id,
        nombre: nombre,
        unidad_negocio_id: unidad_negocio_id ? unidad_negocio_id : null,
        cuenta_id: cuenta_id ? cuenta_id : null,
        subcuenta_id: subcuenta_id ? subcuenta_id : null,
        contacto_id: contacto_id ? contacto_id : null,
        tipo_id: tipo_id ? tipo_id : null,
        unidad_moneda_id: unidad_moneda_id ? unidad_moneda_id : null,
        vendedor_asignado_id: vendedor_asignado_id
          ? vendedor_asignado_id
          : null,
        recargo_id: recargo_id ? recargo_id : null,
        descuento_id: descuento_id ? descuento_id : null,
        utilidad_value: utilidad_value,
        costo_indirecto_value: costo_indirecto_value,
        cotizacion_grupo,
        cotizacion_origen,
        cargo_version,
        secciones: secciones,
        oportunidad_id: oportunidad_id,
      });
    } else {
      response = await axios.post(`${API_URL}cotizaciones/add`, {
        nombre: nombre,
        unidad_negocio_id: unidad_negocio_id ? unidad_negocio_id : null,
        cuenta_id: cuenta_id ? cuenta_id : null,
        subcuenta_id: subcuenta_id ? subcuenta_id : null,
        contacto_id: contacto_id ? contacto_id : null,
        tipo_id: tipo_id ? tipo_id : null,
        unidad_moneda_id: unidad_moneda_id ? unidad_moneda_id : null,
        vendedor_asignado_id: vendedor_asignado_id
          ? vendedor_asignado_id
          : null,
        recargo_id: recargo_id ? recargo_id : null,
        descuento_id: descuento_id ? descuento_id : null,
        utilidad_value: utilidad_value,
        costo_indirecto_value: costo_indirecto_value,
        cotizacion_grupo,
        cotizacion_origen,
        cargo_version,
        secciones: secciones,
        oportunidad_id: oportunidad_id,
        relacionar_oportunidad: relacionar_oportunidad
      });
    }

    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar la cotización:", error);
    throw error;
  }
};

export async function getQuotationById(id: number) {
  try {
    const response = await axios.get(`${API_URL}cotizaciones/${id}`);
    let coti = response.data.doc.data;
    coti = {
      ...coti,
      // costo_indirecto_id: 934,
      // costo_indirecto_code: 'TCPOR',
      // costo_indirecto_value: 0.5000,
      // utilidad_id: 935,
      // utilidad_code: 'TCMFIJ',
      // utilidad_value: 5000.0000,
      costo_indirecto_id: coti.costo_indirecto_id ? coti.costo_indirecto_id : 0,
      costo_indirecto_code: coti.costo_indirecto_code
        ? coti.costo_indirecto_code
        : "",
      costo_indirecto_value: coti.costo_indirecto_value
        ? coti.costo_indirecto_value
        : 0,
      utilidad_id: coti.utilidad_id ? coti.utilidad_id : 0,
      utilidad_code: coti.utilidad_code ? coti.utilidad_code : "",
      utilidad_value: coti.utilidad_value ? coti.utilidad_value : 0,
    };
    return coti;
  } catch (error) {
    console.log("ERROR: ", error);
  }
}
export async function getQuotationPDFById(id: number) {
  try {
    const response = await axios.get(
      `${API_URL}cotizaciones/download/PDF/${id}`
    );
    return response.data.doc.data;
  } catch (error) {
    console.log("ERROR: ", error);
  }
}
export async function getNotesByQuoId(id: number) {
  const response = await axios.get(
    `${API_URL}cotizaciones/notas/byCotizacion/${id}`
  );
  const data = response.data.doc.data;
  return data;
}

export const getNoteQuoByID = async (id: string) => {
  const response = await axios.get(`${API_URL}cotizaciones/notas/${id}`);
  let note = response.data.doc.data;
  note = {
    ...note,
    cotizacion_id: note.cotizacion?.id | 0,
    created_at: note.created_at
      ? new Date(note.created_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
    updated_at: note.updated_at
      ? new Date(note.updated_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
  };
  return note;
};
export async function updateNoteQuo(note: NoteQuoModel) {
  const { id, nombre, descripcion } = note;
  const response = await axios.patch(`${API_URL}cotizaciones/notas/${id}`, {
    nombre: nombre,
    descripcion: descripcion,
  });
  return response;
}

export async function insertNoteQuo(note: NoteQuoModel, cotizacion_id: number) {
  const response = await axios.post(`${API_URL}cotizaciones/notas/`, {
    nombre: note.nombre,
    descripcion: note.descripcion,
    cotizacion_id: Number(cotizacion_id),
  });
  return response;
}
export const uploadFileToQuo = async (file: any, id: string) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(
    `${API_URL}cotizaciones/documentos/${id}`,
    formData
  );
  return response;
};
export const removeFileOfQuo = async (id: number) => {
  const response = await axios.delete(
    `${API_URL}cotizaciones/documentos/${id}`
  );
  return response;
};
export async function getDocumentsByQuoId(id: number) {
  const response = await axios.get(`${API_URL}cotizaciones/documentos/${id}`);
  const data = response.data.doc.data;
  return data;
}

export async function getVersionsByQuoId(id: number) {
  const response = await axios.get(`${API_URL}cotizaciones/versiones/${id}`);
  const data = response.data.doc.data;
  return data;
}

export const finishQuotation = async (cotizacion_id: any) => {
  try {
    const response = await axios.patch(
      `${API_URL}cotizaciones/finalizarCotizacion`,
      {
        idCotizacion: cotizacion_id,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al finalizar la cotización:", error);
    throw error;
  }
};

export const eliminarCotizacion = async (id: any) => {
  try {
    const response = await axios.delete(`${API_URL}cotizaciones/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al eliminar la cotización:", error);
    throw error;
  }
};
export const actualizarPrecios = async (id: any) => {
  try {
    const response = await axios.patch(
      `${API_URL}cotizaciones/actualizarPrecios/`,
      {
        idCotizacion: id,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al eliminar la cotización:", error);
    throw error;
  }
};
export async function getHistoryQuo(search: string, id: string) {
  const response = await axios.get(`${API_URL}cotizacion/historial/${id}`);
  const data = response.data.doc.data;
  return data;
}
export async function createHistoryMessageQuo(message: string, id: number) {
  const response = await axios.post(
    `${API_URL}cotizacion/historial/comment/add`,
    {
      cotizacionId: id,
      comentario: message,
    }
  );
  return response;
}

export async function getDataFilterBySelectFiledQuotations(code: string) {
  let response;
  let data;

  switch (code) {
    case "COTI_NOM":
      response = await axios.post(`${API_URL}cotizaciones/`, {
        campo_id: null,
        valor: null,
      });
      data = response.data.doc.data;
      break;
    case "COTI_UNEG":
      response = await axios.get(`${API_URL}catalogs/UNEG?sort=name`);
      data = response.data.doc.data.rows;
      break;
    case "COTI_TIPO":
      response = await axios.get(`${API_URL}catalogs/TCOT?sort=name`);
      data = response.data.doc.data.rows;
      break;

    default:
      break;
  }
  return data;
}

export async function getCatFieldsQuotations() {
  const response = await axios.get(`${API_URL}catalogs/campos/TBL_COTI`);
  const data = response.data.doc.data;
  const codesToFilter: string[] = ["COTI_NOM", "COTI_UNEG", "COTI_TIPO"];
  const dataFilter = data.filter((obj: any) =>
    codesToFilter.includes(obj.code)
  );
  dataFilter.sort((a: any, b: any) => b.id - a.id);
  return dataFilter;
}


export const updateConditionsService = async (id: number, conditions: string) => {
  try {
    let response = await axios.post(`${API_URL}cotizaciones/condiciones/comerciales`, {
      condiciones_comerciales: conditions,
      cotizacion_id:id
    });
    return response;
  } catch (error) {
    console.error("Error al actualizar las condiciones comerciales:", error);
    throw error;
  }
};

export async function getBOMExplosion(cotizacion_id: number) {
  try {
    const response = await axios.get(`${API_URL}cotizaciones/explosion/insumos/${cotizacion_id}`);
    const data = response.data.doc.data;
    return data;
  } catch (error) {
    console.error("Error al obtener la explosión de insumos:", error);
    throw error;
  }
  
}
