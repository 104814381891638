import axios from "axios";
import { AddressCollaboratorModel, CollaboratorModel, NoteCollaboratorModel } from "../../pages/collaborators/models/CollaboratorModel";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API;
const API_URL_IMAGE_PUBLIC = `${process.env.REACT_APP_PUBLIC}colaborador/`;

const codesToFilter: string[] = ["COLA_NOM", "COLA_PERF", "COLA_DEPTO"];

export async function getCatFieldsCollaborators() {
  const response = await axios.get(`${API_URL}catalogs/campos/TBL_COLA`);
  const data = response.data.doc.data;
  const dataFilter = data.filter((obj: any) =>
    codesToFilter.includes(obj.code)
  );
  return dataFilter;
}

export async function getAllCollaborators(filters: any = null, startIndex: number, endIndex: number) {
  let campo_id = null;
  let valor = null;
  if (filters) {
    if (filters.campo_id) {
      campo_id = filters.campo_id;
    }
    if (filters.valor) {
      valor = filters.valor;
    }
  }
  const response = await axios.post(`${API_URL}colaboradores/all?limit=${startIndex}&page=${endIndex}`, {
    campo_id,
    valor,
  });
  const data = response.data.doc.data.rows;
  return {
    data: data,
  };
}

export async function addUpdCollaborator(body: CollaboratorModel) {
  let response;
  const { id, fecha_alta, empleos, contacto, imagen,
    numero_empleado, email, celular, telefono, rfc,
    tipo, perfil, nss, is_supervisor
  } = body;

  const { genero, curp, pais, fecha_nacimiento } = contacto;

  const isNotEmptyFecIng = !!fecha_alta && fecha_alta.trim() !== '';
  const newFechaAlta = isNotEmptyFecIng ? fecha_alta: new Date()
  .toISOString()
  .split('T')[0];

  const formData = new FormData();
  
  formData.append('id', id?.toString());
  formData.append('fecha_alta', newFechaAlta);
  formData.append('imagen', imagen ?? '');
  formData.append('numero_empleado', numero_empleado)
  formData.append('contacto_id', (contacto.id).toString());
  formData.append('tipo_id', (tipo?.id)?.toString() || '')
  formData.append('genero_id', (genero?.id)?.toString() || '')
  formData.append('curp', curp)
  formData.append('fecha_nacimiento', fecha_nacimiento || '');
  formData.append('pais_id', (pais?.id)?.toString() || '');
  formData.append('email', email ?? '');
  formData.append('celular', celular?.trim() ?? '');
  formData.append('telefono', telefono?.trim() ?? '');
  formData.append('rfc', rfc ?? '');
  formData.append('perfil_id', (perfil?.id)?.toString() || '')
  formData.append('nss',nss ?? '');
  formData.append('is_supervisor',  is_supervisor ? 'true' : 'false');
  
  let formatData;
    let empleosFormatedData = [];

    for(let job of empleos){

      formatData = {
        id: job.key > 0 ? 0 : job.id,
        fecha_inicio: job.fecha_inicio,
        fecha_termino: job.fecha_termino === '' ? null : job.fecha_termino,
        puesto: {id: job.puesto?.id},
        departamento: {id: job.departamento?.id},
        tarifa_unidad_esfuerzo: job.tarifa_unidad_esfuerzo === '' ? null : job.tarifa_unidad_esfuerzo,
        perfil: {id: job.perfil?.id},
        actual: job.actual
      }

      empleosFormatedData.push(formatData) 
      
    }

   formData.append('empleos', JSON.stringify(empleosFormatedData))

  if (id) {
    response = await axios.put(`${API_URL}colaboradores/${id}`, formData);
  }else{
  response = await axios.post(`${API_URL}colaboradores/`, formData);
  }
  return response;
}

export async function getCollaboratorById(
  id: number
): Promise<CollaboratorModel | undefined> {
  try {
    const response = await axios.get(`${API_URL}colaboradores/${id}`);
    const data = response.data.doc.data;

    const { imagen, status } = data;
    const random = Math.random() * 40;
    data.url = data.imagen
      ? `${API_URL_IMAGE_PUBLIC}${imagen}?${random}`
      : toAbsoluteUrl("/media/avatars/photo.png");
      data.activo = status && status.code === 'ACTIVE' ? true : false;
    
    return data;
  } catch (error) {
    console.error("ERROR:", error);
    return undefined;
  }
}

export async function getDataFilterBySelectFiledCollaborator(code: string) {
  let response;
  let data;

  switch (code) {
    case codesToFilter[0]:
      response = await axios.post(`${API_URL}colaboradores/all`, {
        campo_id: null,
        valor: null,
      });
      data = response.data.doc.data.rows;
      break;
    case codesToFilter[1]:
      response = await axios.get(`${API_URL}catalogs/PASOC?sort=name`);
      data = response.data.doc.data.rows;
      break;
    case codesToFilter[2]:
      response = await axios.get(`${API_URL}catalogs/DEP?sort=name`);
      data = response.data.doc.data.rows;
      break;

    default:
      break;
  }
  return data;
}

export async function getHistory(search: string, id: string) {
  const response = await axios.get(
    `${API_URL}colaboradores/historial/${id}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function createHistoryMessage(message: string, id: string) {
  const response = await axios.post(
    `${API_URL}colaboradores/historial/comment/add`,
    {
      colaborador_id: id,
      comentario: message,
    }
  );
  return response;
}

export async function getNotesByCollaboratorId(id: string) {
  try {
    const response = await axios.get(
      `${API_URL}colaboradores/notas/colaborador/${id}`
    );
    const data = response.data.doc.data;
    return data;
  } catch (error) {
    console.error("ERROR:", error);
  }
}
export async function insertNoteCollaborator(note: NoteCollaboratorModel, id: number) {
  try {
    const response = await axios.post(`${API_URL}colaboradores/notas/`, {
      nombre: note.nombre,
      descripcion: note.descripcion,
      colaborador_id: Number(id),
    });
    return response;
  } catch (error) {
    console.error("ERROR:", error);
  }
}
export const getNoteCollaboratorByID = async (id: string) => {
  try {
    const response = await axios.get(`${API_URL}colaboradores/notas/${id}`);
  let note = response.data.doc.data;
  note = {
    ...note,
    created_at: note.created_at
      ? new Date(note.created_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
    updated_at: note.updated_at
      ? new Date(note.updated_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
  };
  return note;
  } catch (error) {
    console.error("ERROR:", error);
  }

};

export async function updateNoteCollaborator(note: NoteCollaboratorModel) {
  try {
    const { id, nombre, descripcion } = note;
    const response = await axios.put(`${API_URL}colaboradores/notas/${id}`, {
      nombre: nombre,
      descripcion: descripcion,
    });
    return response;
  } catch (error) {
    console.error("ERROR:", error);
  }
}

export async function getDocumentsByColaboratorID(id: number) {
  try {
    const response = await axios.get(`${API_URL}colaboradores/documentos/${id}`);
    const data = response.data.doc.data;
    return data;
  } catch (error) {
    console.error("ERROR:", error);
  }
}

export const uploadFileToCollaborator = async (file: any, id: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("colaborador_id", id);

  const response = await axios.post(
    `${API_URL}colaboradores/documentos/`,
    formData
  );
  return response;
};
export const removeFileOfCollaborator = async (id: number) => {
  const response = await axios.delete(
    `${API_URL}colaboradores/documentos/${id}`
  );
  return response;
};

export async function getAddressByCollaboratorId(id: string) {
  try {
    const response = await axios.get(
      `${API_URL}colaboradores/direcciones/all/${id}`
    );
    const data = response.data.doc.data;
    return data;
  } catch (error) {
    console.error("ERROR:", error);
  }
}

export async function insertAddressCollaborator(
  address: AddressCollaboratorModel,
  colaborador_id: string
) {
  const response = await axios.post(
    `${API_URL}colaboradores/direcciones`,
    {
      ...address,
      colaborador_id: colaborador_id,
    }
  );
  return response;
}

export async function updateAddressCollaborator(
  address: AddressCollaboratorModel,
  colaborador_id: string
) {
  const response = await axios.put(
    `${API_URL}/colaboradores/direcciones/${address.id}`,
    {
      ...address,
      colaborador_id: colaborador_id,
    }
  );
  return response;
}


