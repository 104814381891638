import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { DropdownFilter } from '../../../../../_metronic/partials/content/dropdown/DropdownFilter';
import { CreateModal } from '../_modals/CreateModal';
import { ListGrid } from './ListGrid';
import { ListGridDisabled } from './ListGridDisabled';
import { RemoveModal } from '../_modals/RemoveModal';
import { ActiveModal } from '../_modals/ActiveModal';
import { GenericoModel } from '../models/GenericoModel';
import { useGetAll, useGetAllDisabled } from '../hooks/genericoHook';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { RootState } from '../../../../../setup';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import {
  removeRegisterCatalog,
  validarEtapaTarea,
  validarTipoTarea,
  validarUnidadEsfuerzo,
} from '../../../../service/catalog/Catalogs';

type Props = {
  code_catalogo: string;
};

const View: React.FC<Props> = ({ code_catalogo }) => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [codeCatalog, setCodeCatalog] = useState(code_catalogo);
  const [visivilityRemoveModal, setVisivilityRemoveModal] = useState(false);
  const [visivilityActiveModal, setVisivilityActiveModal] = useState(false);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [register, setRegister] = useState<GenericoModel>({
    id: 0,
    code: '',
    name: '',
    order: 0,
  });

  // GRID
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [filterName, setFilterName] = useState('');
  const [typeData, setTypeData] = useState(1);
  const [idRow, setIdRow] = useState(0);

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { catalog, count, loadingRequest } = useGetAll(
    codeCatalog,
    reloadGrid,
    pageSize,
    currentPage,
    filterName
  );
  const { catalogDisabled, countDisabled, loadingRequestDisabled } =
    useGetAllDisabled(
      codeCatalog,
      reloadGrid,
      pageSize,
      currentPage,
      filterName
    );

  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;
  const totalPagesDis = Math.ceil(countDisabled / pageSize)
    ? Math.ceil(countDisabled / pageSize)
    : 1;

  useEffect(() => {
    if (code_catalogo) {
      setLoadingGrid(true);
      setCodeCatalog(code_catalogo);
      setCurrentPage(1);
      setFilterName('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code_catalogo]);

  const addRegister = () => {
    setTitleModal('Agregar registro');
    setVisivilityModal(!visivilityModal);
  };
  const removeRegister = async (row: any) => {
    const { id, name } = row;

    switch (code_catalogo) {
      case 'ETAR':
        await eliminarEtapaTarea(id, name);
        break;
      case 'TTASK':
        await eliminarTipoTarea(id, name);
        break;
      case 'UN_EFFORT_TASK':
        await eliminarUnidadEsfuerzo(id, name);
        break;
      default:
        setRegister(row);
        setVisivilityRemoveModal(!visivilityRemoveModal);
        break;
    }
  };

  const eliminarUnidadEsfuerzo = async (id: number, nombre: string) => {
    await validarUnidadEsfuerzo(id)
      .then((res) => {
        const { existeEnTareas } = res.data.doc.data;
        if (existeEnTareas) {
          Swal.fire({
            title: '¡Registro en uso!',
            html: `
                <div className="text-gray-600">
                  "<b>${nombre}</b>", no puede ser eliminado porque está en uso.
                </div>
                `,
            showCancelButton: true,
            showConfirmButton: false,
            padding: '2em',
            cancelButtonText: 'Cerrar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light me-3',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        } else {
          Swal.fire({
            title: '¿Deseas eliminar la registro?',
            html: `
                <div className="text-gray-600">
                  Se va a eliminar el registro "<b>${nombre}</b>", una vez
                  eliminado ya no se podrá recuperar.
                </div>
                `,
            showCancelButton: true,
            padding: '2em',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light me-3',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              eliminarRegistro(Number(id), code_catalogo);
            }
          });
        }
      })
      .catch((err) => {
        const {
          data: { message },
        } = err.response;
        const errorMessage = message || 'Error al validar el registro.';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  const eliminarEtapaTarea = async (id: number, nombre: string) => {
    await validarEtapaTarea(id)
      .then((res) => {
        const { existeEnTareas } = res.data.doc.data;
        if (existeEnTareas) {
          Swal.fire({
            title: '¡Etapa en uso!',
            html: `
                <div className="text-gray-600">
                  "<b>${nombre}</b>", no puede ser eliminado porque está en uso en tareas.
                </div>
                `,
            showCancelButton: true,
            showConfirmButton: false,
            padding: '2em',
            cancelButtonText: 'Cerrar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light me-3',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        } else {
          Swal.fire({
            title: '¿Deseas eliminar la etapa?',
            html: `
                <div className="text-gray-600">
                  Se va a eliminar la etapa "<b>${nombre}</b>", una vez
                  eliminado ya no se podrá recuperar.
                </div>
                `,
            showCancelButton: true,
            padding: '2em',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light me-3',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              eliminarRegistro(Number(id), code_catalogo);
            }
          });
        }
      })
      .catch((err) => {
        const {
          data: { message },
        } = err.response;
        const errorMessage = message || 'Error al validar el registro.';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };
  const eliminarTipoTarea = async (id: number, nombre: string) => {
    await validarTipoTarea(id)
      .then((res) => {
        const { existeEnTareas } = res.data.doc.data;
        if (existeEnTareas) {
          Swal.fire({
            title: '¡Tipo de tarea en uso!',
            html: `
                <div className="text-gray-600">
                  "<b>${nombre}</b>", no puede ser eliminado porque está en uso en tareas.
                </div>
                `,
            showCancelButton: true,
            showConfirmButton: false,
            padding: '2em',
            cancelButtonText: 'Cerrar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light me-3',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        } else {
          Swal.fire({
            title: '¿Deseas eliminar el tipo de tarea?',
            html: `
                <div className="text-gray-600">
                  Se va a eliminar el tipo de tarea "<b>${nombre}</b>", una vez
                  eliminado ya no se podrá recuperar.
                </div>
                `,
            showCancelButton: true,
            padding: '2em',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light me-3',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              eliminarRegistro(Number(id), code_catalogo);
            }
          });
        }
      })
      .catch((err) => {
        const {
          data: { message },
        } = err.response;
        const errorMessage = message || 'Error al validar el registro.';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };
  const eliminarRegistro = async (id: number, code: string) => {
    await removeRegisterCatalog(id, code)
      .then((res) => {
        const {
          data: { message },
        } = res;
        toast.success('Registro eliminado.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        setReloadGrid(Math.random() * 40);
        const newPage = Math.ceil((count - 1 <= 0 ? 1 : count - 1) / pageSize);
        setCurrentPage(newPage);
      })
      .catch((err) => {
        let resMessageToast: string = '';
        const {
          data: { message, details = null },
        } = err.response;
        resMessageToast = `${message}`;
        toast.error(resMessageToast, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  const editRegister = (id: number) => {
    setIdRow(id);
    setTitleModal('Editar registro');
    setVisivilityModal(!visivilityModal);
  };

  const activeRegister = (row: any) => {
    setRegister(row);
    setVisivilityActiveModal(!visivilityActiveModal);
  };
  const handleFilter = (name: string) => {
    setLoadingGrid(true);
    if (name) {
      setFilterName(name);
    } else {
      setFilterName('');
    }
    setReloadGrid(Math.random() * 40);
  };
  const handleFilterClean = () => {
    setLoadingGrid(true);
    setFilterName('');
    setReloadGrid(Math.random() * 40);
  };
  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column">
                <div className="space-items-between">
                  {typeData == 1 ? (
                    <>
                      <a
                        href="#/"
                        // className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                        className={
                          filterName
                            ? 'btn btn-sm btn-primary'
                            : 'btn btn-sm btn-light-primary'
                        }
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-flip="top-end"
                      >
                        <KTSVG
                          path="/media/icons/duotune/general/gen031.svg"
                          className="svg-icon-5 svg-icon-gray-500 me-1"
                        />
                        Filtrar
                      </a>
                      <DropdownFilter
                        handleFilter={handleFilter}
                        valueFilter={filterName}
                        handleFilterClean={handleFilterClean}
                      />
                    </>
                  ) : null}

                  {userHasRole(['ADMIN']) &&
                    (code_catalogo === 'TOP' ? (
                      <button
                        style={{ marginLeft: '5px' }}
                        //   className="btn btn-sm btn-flex btn-light-primary fw-bolder"
                        className={
                          typeData == 2
                            ? 'btn btn-sm btn-primary'
                            : 'btn btn-sm btn-light-primary'
                        }
                        onClick={() => {
                          setTypeData(typeData == 1 ? 2 : 1);
                        }}
                      >
                        <KTSVG
                          path="/media/icons/duotune/general/gen031.svg"
                          className="svg-icon-5 svg-icon-gray-500 me-1"
                        />
                        {`Registros ${
                          typeData == 2 ? 'activos' : 'desactivados'
                        }`}
                      </button>
                    ) : null)}
                </div>
              </div>

              {code_catalogo !== 'UMON' && (
                <>
                  <div
                    className="card-toolbar"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-trigger="hover"
                    title="Nuevo registro"
                  >
                    <a
                      href="#/"
                      className="btn btn-sm btn-light-primary"
                      id="kt_toolbar_primary_button"
                      onClick={() => {
                        addRegister();
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr075.svg"
                        className="svg-icon-5 svg-icon-gray-500 me-1"
                      />
                      {/* <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' /> */}
                      Nuevo
                    </a>
                  </div>
                </>
              )}
            </div>

            {typeData === 1 ? (
              <ListGrid
                code={code_catalogo}
                data={catalog}
                loadingGrid={loadingGrid}
                count={count}
                currentPage={currentPage}
                totalPages={totalPages}
                valueFilter={filterName}
                onRemoveRegister={removeRegister}
                onPageChange={(pageNumber: number) => {
                  setLoadingGrid(true);
                  setCurrentPage(pageNumber);
                }}
                onEditRegister={editRegister}
              />
            ) : (
              <ListGridDisabled
                data={catalogDisabled}
                loadingGrid={loadingGrid}
                count={countDisabled}
                currentPage={currentPage}
                totalPages={totalPagesDis}
                valueFilter={filterName}
                onRemoveRegister={activeRegister}
                onPageChange={(pageNumber: number) => {
                  setLoadingGrid(true);
                  setCurrentPage(pageNumber);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <CreateModal
        id_row={idRow}
        title={titleModal}
        show={visivilityModal}
        code_catalogo={codeCatalog}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setTypeData(1);
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
          setIdRow(0);
        }}
      ></CreateModal>
      <RemoveModal
        code_catalogo={codeCatalog}
        show={visivilityRemoveModal}
        register={register}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
            const newPage = Math.ceil(
              (count - 1 <= 0 ? 1 : count - 1) / pageSize
            );
            setCurrentPage(newPage);
          }
          setVisivilityRemoveModal(!visivilityRemoveModal);
        }}
      ></RemoveModal>
      <ActiveModal
        show={visivilityActiveModal}
        register={register}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
            const newPage = Math.ceil(
              (countDisabled - 1 <= 0 ? 1 : countDisabled - 1) / pageSize
            );
            setCurrentPage(newPage);
            setTypeData(1);
          }
          setVisivilityActiveModal(!visivilityActiveModal);
        }}
      ></ActiveModal>
    </>
  );
};

export { View };
