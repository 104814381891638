import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { AddressCollaboratorModel } from '../../models/CollaboratorModel';

type Props = {
  data: AddressCollaboratorModel[];
  onOpenModal: (isOpen: boolean) => void;
  active: boolean;
  onEditAddress: (values: any) => void;
};

const AddressCollaboratorList: React.FC<Props> = ({
  data,
  onOpenModal,
  active,
  onEditAddress,
}) => {
  const [dataMemo, setDataMemo] = useState<AddressCollaboratorModel[]>(data);

  useEffect(() => {
    setDataMemo(data);
  }, [data]);

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-150px">Nombre</th>
              <th className="w-150px">Calle</th>
              <th className="w-100px">Núm. exterior </th>
              <th className="w-100px">C.P.</th>
              <th className="w-100px">Colonia</th>
            </tr>
          </thead>
          <tbody className="">
            {dataMemo.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      <div
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          onEditAddress(row);
                        }}
                      >
                        {row.nombre}
                      </div>
                      {/* <Link
                        className="text-primary"
                        to={`/clientes/cuentas/${
                          row.cuenta?.id
                            ? encryptId(row.cuenta.id.toString())
                            : ''
                        }/notas/${encryptId(row.id.toString())}`}
                      > */}

                      {/* </Link> */}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012">
                      {row.calle}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.numero_exterior}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.codigo_postal}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.colonia}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={5}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={() => {
                      onOpenModal(true);
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar dirección
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { AddressCollaboratorList };
