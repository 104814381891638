import React, { useState, useEffect, useRef } from 'react';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { dateFormatt } from '../../../../../../_metronic/helpers/FormatDate';
import { AudioTaskModel } from '../../models/NoteTaskModel';

type Props = {
  data: AudioTaskModel[];
  active: boolean;
};

const AudioTaskList: React.FC<Props> = ({ data, active }) => {
  const [dataMemo, setDataMemo] = useState<AudioTaskModel[]>(data);
  const [currentAudioIndex, setCurrentAudioIndex] = useState<number | null>(
    null
  );
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const playAudio = (index: number) => {
    setCurrentAudioIndex(index);
    setIsPlaying(true);
    if (audioRef.current) {
      if (currentAudioIndex !== index) {
        audioRef.current.src = dataMemo[index].nombre_final;
      }
      audioRef.current.play();
    }
  };

  const pauseAudio = (e: any) => {
    e.preventDefault();
    setIsPlaying(false);
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const handleAudioEnd = () => {
    setIsPlaying(false);
    setCurrentAudioIndex(null);
  };

  useEffect(() => {
    setDataMemo(data);
  }, [data]);

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-30px"></th>
              <th className="w-200px">Nombre</th>
              <th className="w-200px">Creado por</th>
              <th className="w-100px">Fecha</th>
              <th className="w-100px">Audio</th>
            </tr>
          </thead>
          <tbody className="">
            <audio
              ref={audioRef}
              onEnded={handleAudioEnd}
              crossOrigin="anonymous"
            ></audio>
            {dataMemo.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <div className="symbol symbol-30px me-5">
                    <span
                      className={`symbol-label fs-8 fw-bold  fw-bold ${
                        row.origen === 'W'
                          ? 'bg-light-primary text-primary'
                          : 'bg-light-info text-info'
                      }`}
                    >
                      {row.origen === 'W' ? 'WEB' : 'APP'}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      {row.nombre}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012">
                      <div className="symbol symbol-25px tooltip-soft">
                        {row.user && (
                          <>
                            {row.user.profile_photo ? (
                              <>
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC}users/${row.user.profile_photo}`}
                                  crossOrigin="anonymous"
                                  className="symbol-label fs-7 "
                                  alt="user"
                                />
                                <span className="tooltiptext">{`${row.user.first_name} ${row.user.father_last_name} ${row.user.mother_last_name}`}</span>
                              </>
                            ) : (
                              <>
                                <span className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold">
                                  {row.user.first_name &&
                                  row.user.father_last_name ? (
                                    <>{`${row.user.first_name[0]}${row.user.father_last_name[0]}`}</>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                                <span className="tooltiptext">{`${row.user.first_name} ${row.user.father_last_name} ${row.user.mother_last_name}`}</span>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.created_at
                        ? dateFormatt(row.created_at)
                        : row.created_at}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {currentAudioIndex === index && isPlaying ? (
                      <>
                        <button
                          onClick={pauseAudio}
                          className="badge badge-light-primary  text-hover-primary fw-bold cursor-pointer border-0"
                          title="Pausar audio"
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/pause.svg"
                            className="svg-icon-2"
                          />
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            playAudio(index);
                          }}
                          className="badge badge-light-primary  text-hover-primary fw-bold cursor-pointer border-0"
                          title="Reproducir audio"
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/play.svg"
                            className="svg-icon-2"
                          />
                        </button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export { AudioTaskList };
