import React from 'react';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { userHasRole } from '../../../helpers';

export function AsideMenuMain() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;

  return (
    <>
      <AsideMenuItem
        to="/index"
        icon="/media/icons/duotune/general/gen001.svg"
        title="Inicio"
      />
      {userHasRole(['ADMIN', 'CMA', 'DIR', 'TEC'], userRoles) && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className=" text-gray-600 fw-bolder text-uppercase fs-6 ls-1">
                CRM
              </span>
            </div>
          </div>

          {userHasRole(['ADMIN', 'CMA', 'DIR', 'TEC'], userRoles) && (
            <>
              <AsideMenuItemWithSub
                icon="/media/icons/duotune/graphs/gra008.svg"
                fontIcon="bi-layers"
                to="/dashboard"
                title="Dashboard"
                hasBullet={false}
              >
                <AsideMenuItem
                  to="/dashboard/proyecciones/"
                  title="Proyecciones de Ventas"
                />
                <AsideMenuItem
                  to="/dashboard/ventas/"
                  title="Ventas y Ventas Perdidas"
                />
              </AsideMenuItemWithSub>
            </>
          )}

          {userHasRole(['ADMIN', 'CMA', 'DIR', 'TEC'], userRoles) && (
            <AsideMenuItemWithSub
              icon="/media/icons/duotune/communication/com005.svg"
              fontIcon="bi-layers"
              to="/clientes"
              title="Clientes"
              hasBullet={false}
            >
              <AsideMenuItem to="/clientes/cuentas/" title="Cuentas" />
              <AsideMenuItem to="/clientes/contactos/" title="Contactos" />
            </AsideMenuItemWithSub>
          )}
          {userHasRole(['ADMIN', 'CMA', 'DIR', 'TEC'], userRoles) && (
            <AsideMenuItemWithSub
              icon="/media/icons/duotune/general/gen022.svg"
              fontIcon="bi-app-indicator"
              to="/oportunidades"
              title="Oportunidades"
              hasBullet={false}
            >
              <AsideMenuItem to="/oportunidades" title="Mis Oportunidades" />
            </AsideMenuItemWithSub>
          )}
          {userHasRole(['ADMIN', 'CMA', 'DIR', 'TEC'], userRoles) && (
            <AsideMenuItemWithSub
              icon="/media/icons/duotune/general/gen005.svg"
              fontIcon="bi-app-indicator"
              to="/cotizaciones"
              title="Cotizaciones"
              hasBullet={false}
              isBeta={true}
            >
              <AsideMenuItem
                to="/cotizaciones/cotizaciones"
                title="Mis Cotizaciones"
                isBeta
              />
              <AsideMenuItem
                to="/cotizaciones/productos"
                title="Productos"
                isBeta
              />
              <AsideMenuItem
                to="/cotizaciones/servicios"
                title="Servicios"
                isBeta
              />
              <AsideMenuItem
                to="/cotizaciones/paquetes"
                title="Paquetes"
                isBeta
              />
              <AsideMenuItem
                to="/cotizaciones/proveedores"
                title="Proveedores"
                isBeta
              />
            </AsideMenuItemWithSub>
          )}

          <div className="separator border-gray-300"></div>
          {/* {userHasRole(['ADMIN', 'DIR'], userRoles) && (
            <AsideMenuItem
              to="/envio_correo"
              title="Envío de correo"
              icon="/media/icons/duotune/communication/com002.svg"
              fontIcon="bi-app-indicator"
            />
          )} */}
          {userHasRole(['ADMIN', 'DIR'], userRoles) && (
            <AsideMenuItem
              to="/catalogos/"
              icon="/media/icons/duotune/coding/cod001.svg"
              title="Ajustes"
              fontIcon="bi-app-indicator"
            />
          )}
          {userHasRole(['ADMIN', 'DIR'], userRoles) && (
            <AsideMenuItem
              to="/vendedor"
              title=" Usuarios vendedores"
              icon="/media/icons/duotune/general/gen049.svg"
            />
          )}
        </>
      )}
      {userHasRole(
        ['ADMIN', 'DIR', 'SUP', 'RH', 'TEC', 'CMA', 'COLAB'],
        userRoles
      ) && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className=" text-gray-600 fw-bolder text-uppercase fs-6 ls-1">
                ERP
              </span>
            </div>
          </div>
          {userHasRole(['ADMIN', 'DIR', 'SUP', 'RH'], userRoles) && (
            <AsideMenuItemWithSub
              icon="/media/icons/duotune/abstract/abs025.svg"
              fontIcon="bi-app-indicator"
              to="/recursos_humanos"
              title="Recursos Humanos"
              hasBullet={false}
            >
              <AsideMenuItem
                to="/recursos_humanos/colaboradores"
                title="Mis Colaboradores"
              />
            </AsideMenuItemWithSub>
          )}
          {userHasRole(
            ['ADMIN', 'DIR', 'SUP', 'RH', 'TEC', 'CMA'],
            userRoles
          ) && (
            <AsideMenuItemWithSub
              icon="/media/icons/duotune/technology/teh005.svg"
              fontIcon="bi-app-indicator"
              to="/proyectos"
              title="Proyectos"
              hasBullet={false}
            >
              <AsideMenuItem to="/proyectos/proyectos" title="Mis Proyectos" />
            </AsideMenuItemWithSub>
          )}
          {userHasRole(
            ['ADMIN', 'DIR', 'SUP', 'RH', 'TEC', 'CMA', 'COLAB'],
            userRoles
          ) && (
            <AsideMenuItemWithSub
              icon="/media/icons/duotune/text/txt009.svg"
              fontIcon="bi-app-indicator"
              to="/bitacora"
              title="Bitácora"
              hasBullet={false}
              isBeta={true}
            >
              <AsideMenuItem
                to="/bitacora/bitacora"
                title="Mi Bitácora"
                isBeta
              />
            </AsideMenuItemWithSub>
          )}
          <div className="separator border-gray-300"></div>
          {userHasRole(['ADMIN', 'DIR'], userRoles) && (
            <AsideMenuItem
              to="/catalogos/"
              icon="/media/icons/duotune/coding/cod001.svg"
              title="Ajustes"
              fontIcon="bi-app-indicator"
            />
          )}
          {userHasRole(['ADMIN', 'DIR'], userRoles) && (
            <AsideMenuItem
              to="/usuarios"
              title="Usuarios generales"
              icon="/media/icons/duotune/general/gen049.svg"
            />
          )}
        </>
      )}
    </>
  );
}
