import React from 'react';
import { Link } from 'react-router-dom';
import { ProyectoModel } from '../models/ProyectoModel';
import { TableList } from '../../../../../_metronic/helpers/components/TableList';
import { EmptyResult } from '../../../../../_metronic/helpers/components/EmptyResult';
import { encryptId } from '../../../../../_metronic/helpers';
import { colorStatusProject } from '../../../../../_metronic/helpers/CustomElements';

type Props = {
  data: Array<ProyectoModel>;
  loadingGrid: boolean;
};

const ListGrid: React.FC<Props> = ({ data, loadingGrid }) => {
  const columns: any = [
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.nombre,
      cell: (row: any) => (
        <Link to={`/proyectos/proyectos/edit/${encryptId(row.id.toString())}`}>
          {row.nombre}
        </Link>
      ),
      sortable: true,
    },

    {
      id: 'tipo',
      name: <div className="fw-bolder text-muted me-5">Tipo de proyecto</div>,
      selector: (row: any) => row.tipo.name,
      cell: (row: any) => <div className="text-gray-700">{row.tipo.name}</div>,
      sortable: true,
    },
    {
      id: 'fecha_final',
      name: <div className="fw-bolder text-muted me-5">Fecha final</div>,
      selector: (row: any) =>
        new Date(row.fecha_final).toISOString().split('T')[0],
      cell: (row: any) => (
        <div className="text-gray-700">
          {new Date(row.fecha_final).toISOString().split('T')[0]}
        </div>
      ),
      sortable: true,
    },
    {
      id: 'status',
      name: <div className="fw-bolder text-muted me-5">Estatus</div>,
      selector: (row: any) => row.estatus.name,
      cell: (row: any) =>
        row.estatus ? (
          <div className={`mt-4 ${colorStatusProject(row.estatus.code)}`}>
            {row.estatus.name}
          </div>
        ) : (
          <div className="text-gray-700">{'No registrado'}</div>
        ),
      sortable: true,
    },
  ];

  return (
    <>
      <div className={`row gx-5 gx-xl-10`}>
        <div className="col-xl-12">
          <div className={`card mb-0 mb-xxl-8 h-100`}>
            {!loadingGrid ? (
              <TableList data={data} columns={columns}></TableList>
            ) : (
              <EmptyResult title="" loading={loadingGrid} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
