import React, { useState } from 'react';
import {
  KTSVG,
  cleanPhoneNumber,
  maskPhoneNumber,
} from '../../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { styleSelect } from '../../../../../../_metronic/helpers/SelectStyles';
import { useGetContactosFilter } from '../../../../contacto/hooks/ContactoHook';
import {
  updateModalContactoSupplier,
  addFirstContactoSupplier,
} from '../../../../../service/supplier/Supplier';
import { ContactoSupplierModel } from '../../../models/SupplierModel';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};

const AddContactSupplierModal: React.FC<Props> = ({
  id_row,
  show,
  handleClose,
  title,
}) => {
  const [contId, setContId] = useState<any | null>(null);
  const [cuentaId, setCuentaId] = useState<number>(0);
  const [contactoIdSelected, setContactoIdSelected] = useState<number>(0);
  const [subcuentaId, setSubcuentaId] = useState<number>(0);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [reloadContact, setReloadContact] = useState<number>(
    Math.random() * 40
  );
  const location_get = 1;
  const [loading, setLoading] = useState(false);
  const { contDataSelect } = useGetContactosFilter(
    reloadContact,
    cuentaId,
    subcuentaId,
    location_get
  );
  const initModel = {
    nombre: '',
    apellido_paterno: '',
    apellido_materno: '',
    email: '',
    telefono: '',
    proveedor_id: id_row,
  };
  const [initValues, setInitValues] =
    useState<ContactoSupplierModel>(initModel);

  const ContactoSchema = Yup.object().shape({
    nombre: Yup.string().required('Este campo es requerido.'),
    apellido_paterno: Yup.string().required('Este campo es requerido.'),
    apellido_materno: Yup.string(),
    email: Yup.string()
      .email('Formato de correo inválido.')
      .min(3, 'Mínimo 3 caracteres.'),
  });

  const formContacto = useFormik({
    initialValues: initValues,
    validationSchema: ContactoSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        addFirstContactoSupplier(values)
          .then((res) => {
            const {
              data: { message },
            } = res;
            toast.success(message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
            formContacto.resetForm();
            setLoading(false);
            handleClose(true);
            setContId(null);
            setShowForm(false);
          })
          .catch((err) => {
            let resMessageToast: string = '';
            const {
              data: { message, details = null },
            } = err.response;
            resMessageToast = `${message}`;
            setLoading(false);
            setSubmitting(false);
            toast.error(resMessageToast, {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          });
      }, 1000);
    },
  });

  const addContactIfExist = async (
    contactoId: number,
    proveedor_id: number
  ) => {
    if (!contactoId) {
      toast.error('Favor de seleccionar un contacto', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return false;
    }
    const values = {
      proveedor_id: proveedor_id,
      contacto_id: contactoId,
    };
    setLoading(true);
    setTimeout(() => {
      updateModalContactoSupplier(values)
        .then((res) => {
          toast.success(res.data.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          handleClose(true);
          formContacto.resetForm();
          setContId(null);
          setLoading(false);
        })
        .catch((err) => {
          let resMessageToast: string = '';
          const {
            data: { message, details = null },
          } = err.response;
          resMessageToast = `${message}`;
          setLoading(false);
          toast.error(resMessageToast, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        });
    }, 1000);
  };

  const showMaskPhoneNumber = (values: any) => {
    let value = values.target.value.toString();
    value = cleanPhoneNumber(value);
    const maskedPhoneNumber = maskPhoneNumber(value);
    formContacto.setFieldValue('telefono', maskedPhoneNumber);
  };
  const handleSelect = (selectedOption: any, selectId: string) => {
    if (selectedOption.value === 'new') {
      setShowForm(true);
    } else {
      setShowForm(false);
      setContactoIdSelected(selectedOption.value);
    }
    if (selectedOption) {
      const { value, label } = selectedOption;
    }
    if (selectId === 'CONT') {
      setContId(selectedOption);
    }
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-900px"
      show={show}
      onHide={() => {
        formContacto.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formContacto.resetForm();
              handleClose(false);
              setContId(null);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 d-flex justify-content-center">
          <div className="mb-3 col-5">
            <div className="w-100 d-flex">
              <label className="fw-bold fs-7 mb-1 w-auto">Contacto</label>
            </div>
            <Select
              options={contDataSelect}
              styles={styleSelect}
              placeholder={'Seleccione'}
              isSearchable={false}
              noOptionsMessage={() => <span>{'Agregar contacto'}</span>}
              onChange={(event) => handleSelect(event, 'CONT')}
              value={contId}
              backspaceRemovesValue
            />
          </div>
        </div>

        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formContacto.handleSubmit}
          >
            {showForm ? (
              <div>
                <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-5">
                  <div className="w-100 d-flex">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="required fw-bold fs-6 mb-2 w-auto">
                          Nombre
                        </label>
                      </div>
                      <input
                        className="form-control"
                        data-kt-element="input"
                        placeholder="Nombre"
                        {...formContacto.getFieldProps('nombre')}
                      ></input>
                      {formContacto.touched.nombre &&
                        formContacto.errors.nombre && (
                          <div className="fv-plugins-message-container text-danger w-100">
                            <span role="alert">
                              {formContacto.errors.nombre}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="w-100 d-flex">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="required fw-bold fs-6 mb-2 w-auto">
                          Apellido paterno
                        </label>
                      </div>
                      <input
                        className="form-control"
                        data-kt-element="input"
                        placeholder="Apellido paterno"
                        {...formContacto.getFieldProps('apellido_paterno')}
                      ></input>
                      {formContacto.touched.apellido_paterno &&
                        formContacto.errors.apellido_paterno && (
                          <div className="fv-plugins-message-container text-danger w-100">
                            <span role="alert">
                              {formContacto.errors.apellido_paterno}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="w-100 d-flex">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Apellido materno
                        </label>
                      </div>
                      <input
                        className="form-control"
                        data-kt-element="input"
                        placeholder="Apellido materno"
                        {...formContacto.getFieldProps('apellido_materno')}
                      ></input>
                      {formContacto.touched.apellido_materno &&
                        formContacto.errors.apellido_materno && (
                          <div className="fv-plugins-message-container text-danger w-100">
                            <span role="alert">
                              {formContacto.errors.apellido_materno}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-5">
                  <div className="w-100 d-flex">
                    <div className="w-50 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Correo electrónico
                        </label>
                      </div>
                      <input
                        {...formContacto.getFieldProps('email')}
                        className="form-control"
                        placeholder="Correo electrónico"
                        type="email"
                        name="email"
                      />
                      {formContacto.touched.email && formContacto.errors.email && (
                        <div className="fv-plugins-message-container text-danger w-100">
                          <span role="alert">{formContacto.errors.email}</span>
                        </div>
                      )}
                    </div>
                    <div className="w-50 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Teléfono
                        </label>
                      </div>
                      <input
                        className="form-control mb-3"
                        placeholder="Ejemplo: 81 1726 1744"
                        value={maskPhoneNumber(
                          formContacto.values.telefono || ''
                        )}
                        prefix=""
                        onChange={showMaskPhoneNumber}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formContacto.resetForm();
                  handleClose(false);
                  setContId(null);
                }}
              >
                Cancelar
              </button>
              {showForm ? (
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  disabled={loading}
                >
                  {!loading && <span className="indicator-label">Guardar</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              ) : (
                <button
                  onClick={() => addContactIfExist(contactoIdSelected, id_row)}
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  disabled={loading}
                >
                  {!loading && <span className="indicator-label">Agregar</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { AddContactSupplierModal };
