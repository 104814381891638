// import { TareaModel, TaskDocModel } from '../models/TaskModel';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getNoteProveedorByID,
  updateNoteProveedor,
} from '../../../service/supplier/Supplier';
import { NotaProveedorModel } from '../../supplier/models/SupplierModel';

export const useGetNoteProveedorByID = (id: string, reload: number) => {
  const note: NotaProveedorModel = {
    id: 0,
    nombre: '',
    descripcion: '',
  };
  const [noteInit, setNoteInit] = useState<NotaProveedorModel>(note);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getNoteProveedorByID(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setNoteInit(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setNoteInit(note);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload]);

  return { noteInit };
};

export const useEditNoteProveedor = () => {
  const [loadingEditNote, setLoadingEditNote] = useState<boolean>(false);
  const history = useHistory();
  const editNote = async (note: NotaProveedorModel) => {
    setLoadingEditNote(false);
    try {
      await updateNoteProveedor(note);
      setLoadingEditNote(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingEditNote(false);
    }
  };
  return { editNote, loadingEditNote };
};
