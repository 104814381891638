import React, { useState, useEffect } from 'react';
import { formatPriceNew, KTSVG } from '../../../../../_metronic/helpers';
import CountUp from 'react-countup';
import { Collapse } from 'react-bootstrap-v5';
import { useGetBOMExplosion } from '../../hooks/QuotationHook';

type Props = {
  cotizacion_id: number;
};

const Insumos: React.FC<Props> = ({ cotizacion_id }) => {
  const [openSection, setOpenSection] = useState<boolean>(false);
  const [openSectionService, setOpenSectionService] = useState<boolean>(false);
  const [openSectionTotals, setOpenSectionTotals] = useState<boolean>(false);
  const [reloadData, setReloadData] = useState<number>(Math.random() * 40);
  const { dataBOMExplosion, loading } = useGetBOMExplosion(
    cotizacion_id,
    reloadData
  );

  const {
    moneda,
    subtotalProductos,
    subtotalServicios,
    total,
    monto_utilidad,
    monto_costo_indirecto,
    monto_descuento,
    monto_recargo,
    importe_total_s_imp,
    monto_impuestos,
    productos,
    servicios,
  } = dataBOMExplosion;

  return (
    <>
      <div className="card mb-6 mb-xl-9">
        <div
          className="card-header cursor-pointer"
          onClick={() => {
            setOpenSection(!openSection);
          }}
          aria-controls="collapseProducts"
          aria-expanded={openSection}
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Productos</h3>
          </div>
          <div className="d-flex align-items-center">
            <div className="text-center me-5">
              <span className="fs-1 fw-bold" style={{ color: '#1B84FF' }}>
                <CountUp
                  end={subtotalProductos}
                  duration={1}
                  prefix="$"
                  decimals={2}
                />
              </span>

              <span className="fs-7 fw-semibold opacity-50">
                <>
                  /{' '}
                  <span data-kt-element="period">{moneda && moneda.code}</span>
                </>
              </span>
            </div>
            <div
              className={`align-self-center`}
              style={{
                transform: openSection ? 'translateX(10%) rotate(-180deg)' : '',
                transition: 'transform 0.3s ease',
              }}
            >
              <KTSVG
                path={'/media/icons/duotune/arrows/arr072.svg'}
                className={`svg-icon-1`}
              />
            </div>
          </div>
        </div>

        <Collapse in={openSection}>
          <div id="collapseProducts">
            <div className="card-body border-top p-9">
              <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
                <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
                  <thead>
                    <tr className="text-gray-800 fs-7">
                      <th className="w-150px">Concepto</th>
                      <th className="w-150px">Categoría</th>
                      <th className="w-150px">U/M</th>
                      <th className="w-100px">Vol.</th>
                      <th className="w-150px">Precio /u</th>
                      <th className="w-150px">Indirecto /u</th>
                      <th className="w-150px">Utilidad /u</th>
                      <th className="w-200px">Precio s/imp u</th>
                      <th className="w-150px">Subtotal s/imp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productos.length > 0 ? (
                      productos.map((producto, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {producto.concepto}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {producto.categoria}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {producto.unidad_medida}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {producto.volumen}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {`${formatPriceNew(
                                    producto.precio_u,
                                    'es-MX',
                                    'MXN',
                                    2,
                                    2
                                  )}`}
                                  / {moneda && moneda.code}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {`${formatPriceNew(
                                    producto.indirecto,
                                    'es-MX',
                                    'MXN',
                                    2,
                                    2
                                  )}`}
                                  / {moneda && moneda.code}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {`${formatPriceNew(
                                    producto.utilidad,
                                    'es-MX',
                                    'MXN',
                                    2,
                                    2
                                  )}`}
                                  / {moneda && moneda.code}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {`${formatPriceNew(
                                    producto.importe_s_impuesto,
                                    'es-MX',
                                    'MXN',
                                    2,
                                    2
                                  )}`}
                                  / {moneda && moneda.code}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {`${formatPriceNew(
                                    producto.precio_u_total,
                                    'es-MX',
                                    'MXN',
                                    2,
                                    2
                                  )}`}
                                  / {moneda && moneda.code}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9} className="text-center text-muted">
                            Sin conceptos
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="card mb-6 mb-xl-9">
        <div
          className="card-header cursor-pointer"
          onClick={() => {
            setOpenSectionService(!openSectionService);
          }}
          aria-controls="collapseServices"
          aria-expanded={openSectionService}
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Servicios</h3>
          </div>
          <div className="d-flex align-items-center">
            <div className="text-center me-5">
              <span className="fs-1 fw-bold" style={{ color: '#1B84FF' }}>
                <CountUp
                  end={subtotalServicios}
                  duration={1}
                  prefix="$"
                  decimals={2}
                />
              </span>

              <span className="fs-7 fw-semibold opacity-50">
                <>
                  /{' '}
                  <span data-kt-element="period">{moneda && moneda.code}</span>
                </>
              </span>
            </div>

            <div
              className={`align-self-center`}
              style={{
                transform: openSectionService
                  ? 'translateX(10%) rotate(-180deg)'
                  : '',
                transition: 'transform 0.3s ease',
              }}
            >
              <KTSVG
                path={'/media/icons/duotune/arrows/arr072.svg'}
                className={`svg-icon-1`}
              />
            </div>
          </div>
        </div>
        <Collapse in={openSectionService}>
          <div id="collapseServices">
            <div className="card-body border-top p-9">
              <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
                <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
                  <thead>
                    <tr className="text-gray-800 fs-7">
                      <th className="w-150px">Concepto</th>
                      <th className="w-150px">Categoría</th>
                      <th className="w-150px">U/M</th>
                      <th className="w-100px">Vol.</th>
                      <th className="w-150px">Precio /u</th>
                      <th className="w-150px">Indirecto /u</th>
                      <th className="w-150px">Utilidad /u</th>
                      <th className="w-200px">Precio s/imp u</th>
                      <th className="w-150px">Subtotal s/imp</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {servicios.length > 0 ? (
                      servicios.map((servicio, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {servicio.concepto}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {servicio.categoria}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {servicio.unidad_medida}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {servicio.volumen}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {`${formatPriceNew(
                                    servicio.precio_u,
                                    'es-MX',
                                    'MXN',
                                    2,
                                    2
                                  )}`}
                                  / {moneda && moneda.code}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {`${formatPriceNew(
                                    servicio.indirecto,
                                    'es-MX',
                                    'MXN',
                                    2,
                                    2
                                  )}`}
                                  / {moneda && moneda.code}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {`${formatPriceNew(
                                    servicio.utilidad,
                                    'es-MX',
                                    'MXN',
                                    2,
                                    2
                                  )}`}
                                  / {moneda && moneda.code}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {`${formatPriceNew(
                                    servicio.importe_s_impuesto,
                                    'es-MX',
                                    'MXN',
                                    2,
                                    2
                                  )}`}
                                  / {moneda && moneda.code}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                  {`${formatPriceNew(
                                    servicio.precio_u_total,
                                    'es-MX',
                                    'MXN',
                                    2,
                                    2
                                  )}`}
                                  / {moneda && moneda.code}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td colSpan={9} className="text-center text-muted">
                            Sin conceptos
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="card mb-6 mb-xl-9">
        <div
          className="card-header cursor-pointer"
          onClick={() => {
            setOpenSectionTotals(!openSectionTotals);
          }}
          aria-controls="collapseTotals"
          aria-expanded={openSectionTotals}
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Totales</h3>
          </div>
          <div className="d-flex align-items-center">
            <div className="text-center me-5">
              <span className="fs-1 fw-bold" style={{ color: '#1B84FF' }}>
                <CountUp end={total} duration={1} prefix="$" decimals={2} />
              </span>

              <span className="fs-7 fw-semibold opacity-50">
                <>
                  /{' '}
                  <span data-kt-element="period">{moneda && moneda.code}</span>
                </>
              </span>
            </div>
            <div
              className={`align-self-center`}
              style={{
                transform: openSectionTotals
                  ? 'translateX(10%) rotate(-180deg)'
                  : '',
                transition: 'transform 0.3s ease',
              }}
            >
              <KTSVG
                path={'/media/icons/duotune/arrows/arr072.svg'}
                className={`svg-icon-1`}
              />
            </div>
          </div>
        </div>
        <Collapse in={openSectionTotals}>
          <div id="collapseTotals">
            <div className="card-body border-top p-9">
              <div className="d-flex justify-content-end">
                <div className="w-300px">
                  <div className="d-flex flex-stack mb-3">
                    <div className="fw-semibold pe-10 text-gray-600 fs-7">
                      Indirectos:
                    </div>

                    <div className="text-end fw-bold fs-6 text-gray-800">
                      {`${formatPriceNew(
                        monto_costo_indirecto,
                        'es-MX',
                        'MXN',
                        2,
                        2
                      )}`}
                    </div>
                  </div>
                  <div className="d-flex flex-stack mb-3">
                    <div className="fw-semibold pe-10 text-gray-600 fs-7">
                      Utilidad:
                    </div>

                    <div className="text-end fw-bold fs-6 text-gray-800">
                      {`${formatPriceNew(
                        monto_utilidad,
                        'es-MX',
                        'MXN',
                        2,
                        2
                      )}`}
                    </div>
                  </div>
                  <div className="d-flex flex-stack  mb-3">
                    <div className="fw-semibold pe-10 text-gray-600 fs-7">
                      Descuento:
                    </div>
                    <div className="text-end fw-bold fs-6 text-gray-800">
                      {`${formatPriceNew(
                        monto_descuento,
                        'es-MX',
                        'MXN',
                        2,
                        2
                      )}`}
                    </div>
                  </div>
                  <div className="d-flex flex-stack  mb-3">
                    <div className="fw-semibold pe-10 text-gray-600 fs-7">
                      Recargo:
                    </div>
                    <div className="text-end fw-bold fs-6 text-gray-800">
                      {`${formatPriceNew(monto_recargo, 'es-MX', 'MXN', 2, 2)}`}
                    </div>
                  </div>
                  <div className="d-flex flex-stack  mb-3">
                    <div className="fw-semibold pe-10 text-gray-600 fs-7">
                      Importe tot s/ imp:
                    </div>
                    <div className="text-end fw-bold fs-6 text-gray-800">
                      {`${formatPriceNew(
                        importe_total_s_imp,
                        'es-MX',
                        'MXN',
                        2,
                        2
                      )}`}
                    </div>
                  </div>
                  <div className="d-flex flex-stack mb-3">
                    <div className="fw-semibold pe-10 text-gray-600 fs-7">
                      Impuesto:
                    </div>
                    <div className="text-end fw-bold fs-6 text-gray-800">
                      {`${formatPriceNew(
                        monto_impuestos,
                        'es-MX',
                        'MXN',
                        2,
                        2
                      )}`}
                    </div>
                  </div>
                  <div className="d-flex flex-stack">
                    <div className="fw-semibold pe-10 text-gray-600 fs-7">
                      Total:
                    </div>
                    <div className="text-end fw-bold fs-6 text-gray-800">
                      {`${formatPriceNew(total, 'es-MX', 'MXN', 2, 2)}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default Insumos;
