import axios from 'axios'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { AuthenticationModel } from '../models/AuthenticationModel'

const AUTH_URL = process.env.REACT_APP_API
const API_URL_IMAGE_PUBLIC = `${ process.env.REACT_APP_PUBLIC }users/`;

export function login(email: string, password: string) {
  try {
    const response = axios.post<AuthenticationModel>(`${AUTH_URL}login`,  { email, password });
    return response;
  } catch (error) {
    console.error("Error al iniciar sesión:", error);
    throw error; 
  }
}

export async function requestPassword(email: string) {
  return await axios.post(`${AUTH_URL}recover`,  { email });
}
export async function restorePassword( password: string, confirmPassword: string, token: string) {
  return await axios.post<AuthenticationModel>(`${AUTH_URL}reset/${ token }`,  { password, confirm_password: confirmPassword });
}
export async function getUserByToken() {

  const response = await axios.get(`${AUTH_URL}users/me`);
  const random = Math.random() * 40;

  const { 
    doc: {
        data: { id, first_name, father_last_name, mother_last_name, alias, email, phone, roles, profile_photo, active, company: { code, name }  }
    }
  } = response.data;

  return { 
      id: id,
      first_name: first_name,
      father_last_name: father_last_name,
      mother_last_name: mother_last_name,
      alias: alias,
      email: email,
      phone: phone,
      company: name,
      roles: roles,
      active: active,
      profile_photo: profile_photo ? `${API_URL_IMAGE_PUBLIC}${profile_photo}?${random}` : toAbsoluteUrl('/media/avatars/blank.png'),
    };

}
